import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from '../local-storage';

import { Tooltip, CustomTooltipProps } from './tooltip';

interface FeatureAnnouncementProps extends CustomTooltipProps {
  name: string;
  expiresAt?: Date;
}

export const FeatureAnnouncement = ({
  children,
  expiresAt,
  name,
  ...props
}: FeatureAnnouncementProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const expired = expiresAt && expiresAt < new Date();
  const [show, setShow] = React.useState(!expired);

  React.useEffect(() => {
    const status = getDataFromLocalStorage(name);
    if (status === 'true') {
      setShow(false);
    }
  }, [name]);

  React.useEffect(() => {
    if (!ref.current) return;

    if (show) {
      ReactTooltip.show(ref.current);
    } else {
      ReactTooltip.hide(ref.current);
    }
  }, [show]);

  React.useEffect(() => {
    if (getDataFromLocalStorage(name) === 'true') {
      setShow(false);
    }
  }, [name]);

  const hideItem = React.useCallback(() => {
    setDataInLocalStorage(name, 'true');
    setShow(false);
  }, [name]);

  if (!show) {
    return <>{children}</>;
  }

  return (
    <Tooltip {...props} afterHide={hideItem}>
      {React.Children.map<React.ReactNode, React.ReactNode>(
        children,
        (child) =>
          child &&
          React.isValidElement(child) &&
          React.cloneElement(child as React.ReactElement<CustomTooltipProps>, {
            ref,
          })
      )}
    </Tooltip>
  );
};
