import React, { useCallback } from 'react';
import styled, { CSSProperties, useTheme } from 'styled-components';
import { Theme } from '../theme/theme';
import { ButtonWrapper } from './button-base';
import { useRipple } from './useRipple';

const LinkButtonWrapper = styled(ButtonWrapper)`
  text-decoration: none;
`;

export interface LinkButtonProps extends React.ComponentPropsWithoutRef<'a'> {
  /**
   * Polymorphic prop in order to render the component as a different HTML element or React component with the same styling.
   */
  as?: string | React.ComponentType<any>;

  to?: string;

  /**
   * Show link button as primary
   * @default undefined
   */
  secondary?: boolean;

  /**
   * Show link button as tertiary
   * @default undefined
   */
  tertiary?: boolean;

  /**
   * Display button as active
   * @default undefined
   */
  active?: boolean;

  /**
   * Show small link button
   * @default undefined
   */
  small?: boolean;

  /**
   * Link button behaves like `display=block`
   * and takes all the available vertical space
   * @default undefined
   */
  expand?: boolean;

  /**
   * Add a custom theme to the link button e.g. for showing buttin in different colors
   * @default undefined
   */
  customTheme?: Partial<Theme>;

  /**
   * Override styles of the link button
   * @default undefined
   */
  customStyle?: CSSProperties;
}

export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    {
      as,
      children,
      secondary,
      active,
      expand = false,
      customTheme,
      customStyle = {},
      onClick,
      className,
      type = 'button',
      small,
      tertiary,
      ...rest
    },
    ref
  ) => {
    const classNames = [
      'form-element',
      ...(secondary ? ['secondary'] : []),
      ...(className ? [className] : []),
      ...(expand ? ['expanded'] : []),
      ...(active ? ['active'] : []),
      ...(small ? ['small'] : []),
      ...(tertiary ? ['tertiary'] : []),
    ].join(' ');

    const { rippleStyle, fireRipple } = useRipple();

    const theme = useTheme();

    const _onClick = useCallback(
      (evt) => {
        fireRipple(evt);
        return onClick?.(evt);
      },
      [fireRipple, onClick]
    );

    return (
      <LinkButtonWrapper
        as={as ?? 'a'}
        {...rest}
        className={classNames}
        type={type}
        onClick={_onClick}
        theme={
          customTheme
            ? {
                ...theme,
                ...customTheme,
              }
            : undefined
        }
        style={customStyle}
        ref={ref}
      >
        {children}
        <div style={rippleStyle} className="ripple" />
      </LinkButtonWrapper>
    );
  }
);

LinkButton.displayName = 'LinkButton';
