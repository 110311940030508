import * as React from 'react';
import styled from 'styled-components';
import { LabelStyle } from '../typography/typography';
import { Field, connect, FieldArray } from 'formik';
import { Button } from '../button/button';
import { FlexRow, Flex } from '../layout/layout';
import { DateTime } from 'luxon';
import get from 'lodash.get';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  labelComponent?: React.ReactNode;
  errorMsg?: React.ReactNode;
  touched?: boolean;
  hint?: React.ReactNode;
  warning?: React.ReactNode;
  appendix?: React.ReactNode;
  prependix?: React.ReactNode;
  unit?: string;
  'data-testid'?: string;
  key?: string;
  inputClassName?: string;
  isEditing?: boolean;
}

export const FormElementStyle = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 15px;

  .input-group {
    border: 1px solid ${(props) => props.theme.palette.borderMuted};
    background: ${(props) => props.theme.palette.backgroundMuted};
    border-radius: 4px;

    .prependix,
    .appendix {
      padding: 0px 8px 0px 8px;
    }
  }

  input,
  select,
  textarea {
    border: 1px solid ${(props) => props.theme.palette.borderMuted};
    background: ${(props) => props.theme.palette.backgroundMuted};
    border-radius: 4px;
    font-size: 15px;
    padding: 8px 6px;
    outline: 0;
    flex: 1;
    width: 100%;
    box-shadow: 0px 0px 0px 0px ${(props) => props.theme.palette.border};
    transition: box-shadow 70ms ease-out, border-color 70ms ease-out;

    &:disabled {
      color: ${(props) => props.theme.palette.textSubtle};
      user-select: none;
      cursor: not-allowed;

      ~ label {
        color: ${(props) => props.theme.palette.textSubtle};
      }
    }

    &:focus {
      border-color: ${(props) => props.theme.palette.outline};
      box-shadow: 0px 0px 0px 2px
        ${(props) => props.theme.palette.outlineShadow};
    }

    &.touched.error {
      color: ${(props) => props.theme.palette.error.color};
      border-color: ${(props) => props.theme.palette.error.color};
    }
  }

  textarea {
    min-height: 200px;
    width: 100%;
  }

  select {
    display: block;
    padding: 5px 6px;
    height: 39.5px;
  }

  .prependix,
  .appendix {
    display: flex;
    white-space: nowrap;
  }

  .prependix {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 8px;
  }

  .appendix {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: 8px;
  }

  .inline {
    display: inline-block;
    width: initial;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;

  &.with-appendix,
  &.with-prependix {
    input,
    select,
    textarea {
      width: 100%;
    }
  }
`;

export const ErrorBox = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.error.color};
  margin-top: 5px;
`;

export const Hint = styled.div<{ $color?: 'warning' | 'default' | string }>`
  color: ${({ $color = 'default', theme }) => {
    if ($color === 'warning') return theme.palette.warning.color;
    if ($color === 'default') return theme.palette.textMuted;
    return $color;
  }};
  font-size: 12px;
  margin-top: 5px;
  margin-left: 6px;
`;

const Label = styled.label`
  ${LabelStyle}
  margin-bottom: 5px;
`;

const CheckboxLabel = styled.label`
  ${LabelStyle}
  display: inline-block;
  margin-left: 6px;
`;

export const wrapFormElement = (Element: any) =>
  React.forwardRef(
    (
      {
        label,
        labelComponent,
        id,
        errorMsg,
        touched,
        hint,
        warning,
        appendix,
        prependix,
        unit,
        className,
        inputClassName,
        isEditing = true,
        ...props
      }: InputProps,
      ref
    ) => {
      let { value } = props;
      const inputClassnames = inputClassName ? [inputClassName] : [];
      const groupClassnames = [isEditing ? 'input-group' : ''];

      if (touched) {
        inputClassnames.push('touched');
      }

      if (errorMsg) {
        inputClassnames.push('error');
      }

      if (appendix || unit) {
        groupClassnames.push('with-appendix');
      }

      if (prependix) {
        groupClassnames.push('with-prependix');
      }

      /**
       * datetime-local dosent support timezones
       */
      if (props.type === 'datetime-local' || props.type === 'date') {
        if (value) {
          const date = DateTime.fromISO(String(value));

          if (props.type === 'datetime-local') {
            value = `${date.toFormat('yyyy-MM-dd')}T${date.toFormat('HH:mm')}`;
          } else {
            value = `${date.toFormat('yyyy-MM-dd')}`;
          }
        }
      }

      if (props.type === 'checkbox') {
        inputClassnames.push('inline');
        return (
          <FormElementStyle className={`form-element ${className}`} key={id}>
            <Element
              {...props}
              id={id}
              ref={ref as any}
              className={inputClassnames.join(' ')}
              value={value}
            />
            {label && <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>}
            {hint && !errorMsg && <Hint>{hint}</Hint>}
            {warning && !errorMsg && <Hint color="warning">{warning}</Hint>}
            {errorMsg && (
              <ErrorBox
                {...(props['data-testid'] && {
                  'data-testid': `${id}-error`,
                })}
              >
                {errorMsg}
              </ErrorBox>
            )}
          </FormElementStyle>
        );
      }

      return (
        <FormElementStyle className={`form-element ${className}`} key={id}>
          {label && (
            <Label htmlFor={id}>
              {label}
              {labelComponent}
            </Label>
          )}
          <InputGroup className={groupClassnames.join(' ')}>
            {prependix && <div className="prependix">{prependix}</div>}
            {isEditing ? (
              <Element
                {...props}
                id={id}
                ref={ref as any}
                className={inputClassnames.join(' ')}
                value={value}
              />
            ) : (
              <div
                {...props}
                id={id}
                ref={ref as any}
                className={[inputClassName].join(' ')}
              >
                {value || '--'}
              </div>
            )}
            {appendix && <div className="appendix">{appendix}</div>}
            {unit && <div className="appendix">{unit}</div>}
          </InputGroup>
          {hint && !errorMsg && isEditing && <Hint>{hint}</Hint>}
          {warning && !errorMsg && isEditing && (
            <Hint $color="warning">{warning}</Hint>
          )}
          {errorMsg && isEditing && (
            <ErrorBox
              {...(props['data-testid'] && {
                'data-testid': `${id}-error`,
              })}
            >
              {errorMsg}
            </ErrorBox>
          )}
        </FormElementStyle>
      );
    }
  );

export const Input = wrapFormElement('input');
export const Select = wrapFormElement('select');
export const Radio = wrapFormElement('div');
export const Textarea = wrapFormElement('textarea');
export const Checkbox = wrapFormElement('checkbox');

const wrapForFormik = (Element: any) => {
  return function FormikWrapper({
    field,
    form: {
      touched,
      errors,
      values,
      setFieldValue,
      isSubmitting,
      submitCount,
      validateOnMount,
      status,
    },
    ...props
  }: {
    field: any;
    form: any;
  }) {
    const backendErrorMessage = React.useMemo(() => {
      if (
        status &&
        status.hasOwnProperty(field.name) &&
        !get(touched, field.name, false)
      ) {
        return status[field.name];
      }
      return '';
    }, [status, touched, field.name]);
    const value = get(values, field.name, '');
    const hasError =
      ((get(touched, field.name, false) ||
        submitCount > 0 ||
        validateOnMount) &&
        get(errors, field.name, false)) ||
      (get(status, field.name, false) && !get(touched, field.name, false));
    const isDisabled = (props as React.HTMLProps<HTMLInputElement>).disabled;
    return (
      <Element
        {...props}
        {...field}
        {...((props as React.HTMLProps<HTMLInputElement>).type !==
          'checkbox' && {
          value,
        })}
        {...((props as React.HTMLProps<HTMLInputElement>).type ===
          'checkbox' && {
          checked: value === true,
        })}
        onChange={(e: any) => {
          setFieldValue(
            field.name,
            e.target.type === 'checkbox' ? e.target.checked : e.target.value
          );
        }}
        errorMsg={
          hasError && !isDisabled && !isSubmitting
            ? `${[get(errors, field.name, ''), backendErrorMessage]
                .filter((e) => e)
                .join(', ')}`
            : null
        }
        touched={hasError || get(touched, field.name)}
        disabled={isDisabled || isSubmitting}
      />
    );
  };
};

const wrapInField = (Element: any) => (props: any) =>
  <Field {...props} component={Element} />;

/**
 *  The following component only work in a Formik context.
 */
export const FormikInput = wrapInField(wrapForFormik(Input));
export const FormikSelect = wrapInField(wrapForFormik(Select));
export const FormikRadio = wrapInField(wrapForFormik(Radio));
export const FormikTextarea = wrapInField(wrapForFormik(Textarea));

export const FormikSubmit = connect<React.ComponentProps<typeof Button>>(
  ({ formik: { isSubmitting }, disabled, ...props }) => {
    return (
      <Button
        id="submit-btn"
        type="submit"
        isLoading={isSubmitting}
        disabled={isSubmitting || disabled}
        {...props}
      />
    );
  }
);

const MultipleItem = styled(FlexRow)`
  border-bottom: 1px solid lightgrey;
`;

interface FormikMultipleProps {
  name: string;
  subFields: string[];
  renderField: any;
  label: string;
}

export const FormikMultiple = connect<FormikMultipleProps>(
  ({ formik, name, subFields, renderField }) => {
    const values = get(formik.values, name);

    return (
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            <Label>{name}</Label>
            <Button
              secondary
              type="button"
              onClick={() => arrayHelpers.push({})}
            >
              +
            </Button>
            <div>
              {(values as any).map((value: any, index: number) => (
                <MultipleItem>
                  {subFields.map((name) => (
                    <Flex>
                      {' '}
                      {renderField(
                        name,
                        index,
                        value,
                        true
                        // index === values.length - 1
                      )}
                    </Flex>
                  ))}
                  <Button
                    secondary
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    -
                  </Button>
                </MultipleItem>
              ))}
            </div>
          </>
        )}
      />
    );
  }
);
