import React, { useCallback } from 'react';
import styled, { CSSProperties, useTheme } from 'styled-components';
import { Theme, defaultTheme } from '../theme/theme';
import { hexToRgbaString } from './hex-to-rgb';
import { useRipple } from './useRipple';

export const ButtonWrapper = styled.button`
  background: transparent;
  border: none;
  outline: none;
  border: 1px solid;
  border-radius: 4px;
  padding: 7px 22px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.15px;
  align-items: center;
  justify-content: center;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.24s, box-shadow 0.24s, border 0.24s;
  position: relative;

  &.expanded {
    width: 100%;
    flex: 1;
  }

  &.small {
    padding: 4px 10px;
  }

  // primary style
  & {
    background-color: ${(props) => props.theme.secondaryColor};
    border: none;
    color: ${(props) => props.theme.primaryColor};

    .ripple {
      background-color: ${(props) => props.theme.primaryColor};
    }
  }

  &:not(:disabled):not(.active):hover {
    background-color: ${(props) =>
      hexToRgbaString(props.theme.secondaryColor, 0.8)};
  }

  &.active {
    background-color: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.palette.background};
    transition: background, color, border-color 0s linear;
    transition-delay: 150ms;
  }

  &:disabled {
    background-color: ${(props) => props.theme.secondaryColor};
    color: ${(props) => hexToRgbaString(props.theme.primaryColor, 0.4)};

    cursor: default;
  }

  // secondary style
  &.secondary {
    background-color: ${(props) => props.theme.palette.background};
    color: ${(props) => props.theme.primaryColor};
    border: 1px solid ${(props) => props.theme.palette.border};

    .ripple {
      background-color: ${(props) => props.theme.primaryColor};
    }
  }

  &.secondary:not(:disabled):not(.active):hover {
    background-color: ${(props) =>
      props.theme.palette?.backgroundMuted ??
      defaultTheme.palette.backgroundMuted};
  }

  &.secondary.active {
    background-color: ${(props) => props.theme.primaryColor};
    border: 1px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.palette.background};
  }

  &.secondary:disabled {
    background-color: ${(props) => props.theme.palette.background};
    color: ${(props) => props.theme.palette.textSubtle};
    border: 1px solid ${(props) => props.theme.palette.border};
  }

  &.tertiary {
    border: none;
    background: none;
  }

  &.tertiary.active {
    background-color: ${(props) => props.theme.primaryColor};
  }

  &.tertiary:disabled {
    color: ${(props) => props.theme.palette.textSubtle};
  }

  &.tertiary:not(:disabled):not(.active):hover {
    background-color: ${(props) =>
      props.theme.palette?.backgroundMuted ??
      defaultTheme.palette.backgroundMuted} !important;
  }
`;

export interface BaseButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  /**
   * Show button as primary button
   * @default undefined
   */
  secondary?: boolean;

  /**
   * Show button as tertiary button
   * @default undefined
   */
  tertiary?: boolean;

  /**
   * Display button as active
   * @default undefined
   */
  active?: boolean;

  /**
   * Show small button
   * @default undefined
   */
  small?: boolean;

  /**
   * Button behaves like `display=block`
   * and takes all the available vertical space
   * @default undefined
   */
  expand?: boolean;

  /**
   * Add a custom theme to the button e.g. for showing buttin in different colors
   * @default undefined
   */
  customTheme?: Partial<Theme>;

  /**
   * Override styles of the button
   * @default undefined
   */
  customStyle?: CSSProperties;

  /**
   * This prop is deprecated
   * @deprecated
   */
  willBecomeActive?: boolean;

  /**
   * This prop is deprecated
   * @deprecated
   */
  bold?: boolean;
}

export const ButtonBase = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      children,
      secondary,
      disabled,
      active,
      expand = false,
      customTheme,
      customStyle = {},
      onClick,
      className,
      type = 'button',
      small,
      tertiary,
      ...rest
    },
    ref
  ) => {
    const classNames = [
      'form-element',
      ...(secondary ? ['secondary'] : []),
      ...(className ? [className] : []),
      ...(expand ? ['expanded'] : []),
      ...(active ? ['active'] : []),
      ...(small ? ['small'] : []),
      ...(tertiary ? ['tertiary'] : []),
    ].join(' ');

    const { rippleStyle, fireRipple } = useRipple();

    const theme = useTheme();

    const _onClick = useCallback(
      (evt) => {
        fireRipple(evt);
        if (!disabled) {
          return onClick?.(evt);
        }
      },
      [fireRipple, disabled, onClick]
    );

    return (
      <ButtonWrapper
        {...rest}
        className={classNames}
        type={type}
        onClick={_onClick}
        theme={
          customTheme
            ? {
                ...theme,
                ...customTheme,
              }
            : undefined
        }
        disabled={disabled}
        style={customStyle}
        ref={ref}
      >
        {children}
        <div style={rippleStyle} className="ripple" />
      </ButtonWrapper>
    );
  }
);

ButtonBase.displayName = 'ButtonBase';
