import React from 'react';
import styled, { useTheme } from 'styled-components';
import { BaseIcon } from '../icons/base-icon';
import { Headline as HeadlineComponent } from '../typography/typography';
import { FlexRow } from '../layout/layout';

const Container = styled.div`
  display: flex;
  padding: 0px 0 30px 0;

  .background-svg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: auto;
    z-index: 0;

    height: 295px;
    width: 100%;

    mask path {
      fill: #fff;
    }

    path {
      fill-rule: evenodd;
      clip-rule: evenodd;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
    flex: 1;
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.background};
  border-radius: 4px;

  margin-right: 10px;
  height: 44px;
  width: 44px;
`;

const Actions = styled(FlexRow)`
  justify-content: flex-end;
`;

const Headline = styled(HeadlineComponent)`
  flex: 1;
`;

export interface HeaderProps {
  Icon?: typeof BaseIcon;
  actions?: () => React.ReactNode;
  hideBackground?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  children,
  actions,
  Icon,
  hideBackground = false,
}) => {
  const theme = useTheme();

  return (
    <Container>
      {hideBackground ? null : (
        <svg
          className="background-svg"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMax slice"
          viewBox="0 0 1440 294"
          xmlSpace="preserve"
        >
          <defs>
            <linearGradient
              id="gradient-1"
              gradientUnits="userSpaceOnUse"
              x1="704.5"
              y1="161.9"
              x2="705.5"
              y2="161.9"
              gradientTransform="matrix(1440 0 0 -294 -1014495 47760)"
            >
              <stop offset="0" stopColor="#169ef8" />
              <stop offset="1" stopColor="#19f583" />
            </linearGradient>
            <linearGradient
              id="gradient-2"
              gradientUnits="userSpaceOnUse"
              x1="704.7"
              y1="161.7"
              x2="705.5"
              y2="161.7"
              gradientTransform="matrix(1502 0 0 -612 -1058205 99139)"
            >
              <stop offset="0" stopColor="#75ffe2" stopOpacity="0" />
              <stop offset="1" stopColor="#d2eefc" stopOpacity=".7" />
            </linearGradient>
            <linearGradient
              id="gradient-3"
              gradientUnits="userSpaceOnUse"
              x1="705.5"
              y1="161.3"
              x2="706.5"
              y2="161.3"
              gradientTransform="matrix(-1449 0 0 612 1023699 -98455)"
            >
              <stop offset="0" stopColor="#169ef8" />
              <stop offset="1" stopColor="#19f583" />
            </linearGradient>

            <filter id="filter-1" x="0" y="-278" width="1502" height="765">
              <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
            <filter id="filter-2" x="0" y="-137" width="1502" height="612">
              <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
            <filter id="filter-3" x="0" y="-55" width="1449" height="612">
              <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
            <filter id="filter-4" x="0" y="-255" width="525" height="730">
              <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>

            <mask
              filter="url(#filter-2)"
              x="0"
              y="-137"
              width="1502"
              height="612"
              id="mask-1"
            >
              <path d="M0 0h1440v294H0z" />
            </mask>
            <mask
              filter="url(#filter-3)"
              x="0"
              y="-55"
              width="1449"
              height="612"
              id="mask-2"
            >
              <path d="M0 0h1440v294H0z" />
            </mask>
            <mask
              filter="url(#filter-1)"
              x="0"
              y="-278"
              width="1502"
              height="765"
              id="mask-3"
            >
              <path d="M0 0h1440v294H0z" />
            </mask>
            <mask
              filter="url(#filter-4)"
              x="0"
              y="-255"
              width="525"
              height="730"
              id="mask-4"
            >
              <path d="M0 0h1440v294H0z" />
            </mask>
          </defs>

          <path d="M0 0h1440v294H0z" fill="url(#gradient-1)" />
          <path
            d="M0-9c162 207 301 298 417 273S693 160 901 26l276-182c48-30 89-56 124-76 77-40 144-54 201-42v761H0V-9z"
            opacity=".4"
            fill="#e6f5fe"
            mask="url(#mask-3)"
          />
          <path
            d="M0 153c169 131 351 170 546 117s361-123 496-208c239-133 392-199 460-199v612H1L0 153z"
            mask="url(#mask-1)"
            fill="url(#gradient-2)"
          />
          <path
            d="M1449 267c-163-131-338-170-527-117S574 273 443 358C214 491 66 557 0 557V-55h1448l1 322z"
            opacity=".7"
            mask="url(#mask-2)"
            fill="url(#gradient-3)"
          />
          <path
            d="M0-255C91 53 266 296 525 475c-110 0-285-3-523-9L0-255z"
            opacity=".4"
            mask="url(#mask-4)"
            fill="#53fe9a"
          />
        </svg>
      )}

      <div className="content">
        {Icon && (
          <IconWrapper>
            <Icon size={25} color={theme.primaryColor} />
          </IconWrapper>
        )}
        <Headline header>{children}</Headline>
        {actions && <Actions>{actions()}</Actions>}
      </div>
    </Container>
  );
};
