import * as React from 'react';
import { Textarea, InputProps } from './input';
import styled from 'styled-components';
import { defaultTheme } from '../theme/theme';

const CharCounter = styled.span<{ $hidden?: boolean }>`
  font-size: 14px;
  margin-top: 0.5rem;
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
`;

const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    align-self: flex-end;
  }
`;

export type TextareaProps = Omit<
  InputProps,
  'appendix' | 'prependix' | 'unit'
> & {
  characterThreshold?: number;
  maxLength: number;
  value?: string;
  className?: string;
};

export function TextareaCounter({
  characterThreshold,
  value,
  maxLength,
  onChange,
  className,
  isEditing,
  ...props
}: TextareaProps) {
  const [textValue, setTextValue] = React.useState(value);
  const [remaining, setRemaining] = React.useState(
    textValue ? maxLength - textValue.length : maxLength
  );

  React.useEffect(() => {
    if (value) {
      setTextValue(value);
      setRemaining(maxLength - value.length);
    }
  }, [maxLength, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemaining(maxLength - e.target.value.length);
    setTextValue(e.target.value);
    if (onChange) onChange(e);
  };

  const color = remaining < 15 ? defaultTheme.palette.warning.color : 'inherit';

  return (
    <TextareaWrapper className={className}>
      <Textarea
        {...props}
        isEditing={isEditing}
        value={textValue}
        maxLength={maxLength}
        onChange={handleChange}
      />
      {isEditing && (
        <CharCounter
          $hidden={!!characterThreshold && characterThreshold < remaining}
          style={{ color }}
        >
          {remaining} Zeichen verbleibend
        </CharCounter>
      )}
    </TextareaWrapper>
  );
}
