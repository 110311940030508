import React from 'react';
import styled from 'styled-components';

const FieldSetTitle = styled.div`
  font-weight: bold;
  font-size: 17px;
`;

const FieldSetStyled = styled.fieldset`
  border: 1px solid ${(props) => props.theme.palette.border};
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const FieldSet = ({
  legend,
  children,
}: {
  legend: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <FieldSetStyled>
        <FieldSetTitle>{legend}</FieldSetTitle>
        {children}
      </FieldSetStyled>
    </>
  );
};
