import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div<{ $dense?: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 0 6px;

  > label {
    margin-left: ${(props) => (props.$dense ? '0.4rem' : '0.6rem')};
  }
`;

type CheckboxSimpleProps = {
  checked: boolean;
  dense?: boolean;
  label: string;
  id: string;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

export const CheckboxSimple = ({
  checked,
  dense,
  label,
  id,
  onChange,
  disabled,
}: CheckboxSimpleProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked);
  };

  const inputId = id ?? `checkbox-${label}`;

  return (
    <CheckboxContainer $dense={dense}>
      <input
        type="checkbox"
        data-testid={id}
        id={inputId}
        checked={checked}
        readOnly
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor={inputId}>{label}</label>
    </CheckboxContainer>
  );
};
