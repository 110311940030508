import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Info } from '../icons/icons';
import { IconProps } from '../icons/base-icon';

const Message = styled.div`
  font-size: 15px;
  padding: 12px 15px 12px 15px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.info.color};
  background: ${(props) => props.theme.palette.info.background};
`;

const IconWrap = styled.div`
  margin-right: 0px;
  display: inline-block;
  position: relative;
  left: -5px;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 17px;
  color: ${(props) => props.theme.palette.info.color};
  letter-spacing: 0.27px;
`;

export interface AlertInfoProps {
  title: string;
  message?: string;
  icon?: React.ComponentType<IconProps>;
  children?: React.ReactNode;
  className?: string;
}

export function AlertInfo({
  icon,
  title,
  message,
  children,
  className,
}: AlertInfoProps) {
  const theme = useTheme();

  let Icon = icon ? icon : Info;
  return (
    <Message className={className}>
      <IconWrap>
        <Icon size={20} color={theme.palette.info.color} />
      </IconWrap>
      <Title>{title}</Title>
      <div>{children ? children : message}</div>
    </Message>
  );
}
