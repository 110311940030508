import React from 'react';
import styled, { useTheme } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import ReactTooltip from 'react-tooltip';

export interface LegendItemProps {
  color: string;
  small?: boolean;
}

export interface RatioBarProps {
  id: string;
  total: number;
  value: number;
  unit?: string;
  primaryColor?: string;
  secondaryColor?: string;
  withLegend?: boolean;
  legendItems?: RatioBarLegendProps['items'];
  smallLegend?: RatioBarLegendProps['small'];
}

export interface RatioBarLegendProps {
  items: {
    label: string;
    color: string;
  }[];
  small?: boolean;
}

const Container = styled.div`
  height: 21px;
  padding-top: 5px;

  .animate {
    transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform-origin: left;
  }

  .display-enter .animate,
  .display-appear .animate {
    transform: scaleX(0);
  }
  .display-enter-active .animate,
  .display-appear-active .animate {
    transform: scaleX(1);
  }
`;

const Bar = styled.div`
  height: 10px;
  background: ${(props) => props.color};
  border-radius: 4px;
  overflow: hidden;
  font-size: 11px;
  position: relative;
`;

const Percentage = styled.div`
  background: ${(props) => props.color};
  height: 10px;
  position: absolute;
`;

export const Legend = styled.div`
  display: inline-flex;
`;

const LegendItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-top: 4px;
  color: var(--color-grey);
  font-size: 12px;
  text-transform: uppercase;
`;

const Square = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props) => props.color};
  margin-right: 5px;
`;

export const RatioBar: React.FC<RatioBarProps> = ({
  id,
  total,
  value,
  unit,
  primaryColor,
  secondaryColor,
  withLegend = true,
  legendItems = [],
  smallLegend,
}) => {
  const theme = useTheme();

  return (
    <>
      <Container data-for={`ratio-tip-${id}`} data-tip>
        <CSSTransition
          in={true}
          timeout={1000}
          classNames="display"
          unmountOnExit
          appear
        >
          <Bar color={secondaryColor ?? theme.palette.ratioBarSecondary}>
            <Percentage
              color={primaryColor ?? theme.palette.ratioBarPrimary}
              style={{ width: `${(value / total) * 100}%` }}
              className="animate"
            />
          </Bar>
        </CSSTransition>
      </Container>
      <ReactTooltip
        id={`ratio-tip-${id}`}
        place="bottom"
        type="light"
        effect="solid"
      >
        {withLegend && legendItems.length ? (
          <RatioBarLegend small={smallLegend} items={legendItems} />
        ) : (
          `${value ?? ''} ${unit ?? ''}`
        )}
      </ReactTooltip>
    </>
  );
};

export const LegendItem: React.FC<LegendItemProps> = ({
  color,
  small,
  children,
}) => (
  <LegendItemWrapper
    style={{
      ...(small ? { fontSize: 10 } : {}),
    }}
  >
    <Square color={color} />
    {children}
  </LegendItemWrapper>
);

export const RatioBarLegend: React.FC<RatioBarLegendProps> = ({
  items,
  small,
}) => {
  return (
    <Legend>
      {items.map(({ label, color }) => (
        <LegendItem key={label} color={color} small={small}>
          {label}
        </LegendItem>
      ))}
    </Legend>
  );
};
