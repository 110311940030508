import styled from 'styled-components';

export const Link = styled.span`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;
