import styled from 'styled-components';

export const TabNavigation = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
  padding-left: 25px;
  background: ${(props) => props.theme.palette.background};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  a {
    text-decoration: none;
  }
`;

export const TabNavigationItem = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.palette.textMuted};
  padding: 15px 0px 9px 0px;
  cursor: pointer;
  margin-right: 50px;
  font-weight: 400;

  &:hover {
    color: ${(props) => props.theme.palette.text};
  }

  &.active {
    border-bottom: 2px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    font-weight: 600;
  }
`;

export const TabContainer = styled.div`
  display: none;

  &.active {
    display: inherit;
  }
`;

export const YellowDot = styled.div`
  width: 9px;
  height: 9px;
  background: #ff9800;
  border-radius: 50px;
  display: inline-block;
  margin-left: 6px;
`;
