import * as React from 'react';
import styled from 'styled-components';
import { InternalModal, MaxModalWidth } from '../modal/internal-modal';
import { FlexRow } from '../layout/layout';
import { Button } from '../button/button';
import { Theme } from '../theme/theme';

const Buttons = styled(FlexRow)`
  justify-content: flex-end;

  button:first-child {
    margin-right: 5px;
  }
`;

const ModalText = styled.div`
  padding: 20px 0;
  word-break: break-word;
`;

type ConfirmationDialogProps = {
  confirmLabel?: string;
  buttonTheme?: Theme;
  disableCancel?: boolean;
  id: string;
  isOpen: boolean;
  isLoading?: boolean;
  maxWidth?: MaxModalWidth;
  title?: string;
  onConfirm: () => void;
  onAbort?: () => void;
};

export function ConfirmationDialog({
  children,
  confirmLabel,
  buttonTheme,
  disableCancel,
  id,
  isLoading,
  isOpen,
  maxWidth,
  title,
  onAbort,
  onConfirm,
}: React.PropsWithChildren<ConfirmationDialogProps>) {
  return (
    <InternalModal
      title={title}
      onRequestClose={!disableCancel && onAbort ? onAbort : undefined}
      contentLabel={id}
      maxWidth={maxWidth}
      isOpen={isOpen}
    >
      <ModalText>{children}</ModalText>
      <Buttons>
        {!disableCancel && onAbort && (
          <Button secondary onClick={onAbort}>
            Abbrechen
          </Button>
        )}
        <Button
          onClick={onConfirm}
          customTheme={buttonTheme}
          isLoading={isLoading}
        >
          {confirmLabel ?? 'Ok'}
        </Button>
      </Buttons>
    </InternalModal>
  );
}
