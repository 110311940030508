import React from 'react';

import { PaginationProps } from './types';
import { PaginationWrapper, PaginationText } from './style';

import { ArrowLeft, ArrowRight } from '../icons/icons';
import { Button } from '../button/button';
import { useTheme } from 'styled-components';

export function Pagination({
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  rowCount,
  page,
  pageCount,
  pageIndex,
  pageSize,
}: PaginationProps) {
  const theme = useTheme();

  if (pageCount <= 1) {
    return null;
  }

  return (
    <PaginationWrapper>
      <Button
        secondary
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        <ArrowLeft
          size={20}
          color={!canPreviousPage ? theme.palette.border : theme.primaryColor}
        />
      </Button>
      <PaginationText>
        {`${pageIndex * pageSize + 1} – ${Math.min(
          Math.min(
            (pageIndex + 1) * pageSize,
            pageIndex * pageSize + page.length
          ),
          rowCount
        )} von ${Math.min(pageCount * pageSize, rowCount)}`}
      </PaginationText>
      <Button secondary onClick={() => nextPage()} disabled={!canNextPage}>
        <ArrowRight
          size={20}
          color={!canNextPage ? theme.palette.border : theme.primaryColor}
        />
      </Button>
    </PaginationWrapper>
  );
}
