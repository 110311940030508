import React from 'react';
import { useTranslation } from 'react-i18next';
import { Main, PageGrid, PageColumn, Section, Segment } from '../layout/layout';
import { Header } from '../header/header';
import { Settings } from '../icons/icons';
import { Headline } from '../typography/typography';
import { UserSettingsForm, UserSettingsProps } from './user-settings-form';

export const UserSettings: React.FC<UserSettingsProps> = ({
  user,
  useUpdatePasswordMutation,
}) => {
  const { t } = useTranslation('userSettings');

  return (
    <Main>
      <Header Icon={Settings}>{t('settings')}</Header>
      <PageGrid>
        <PageColumn>
          <Section>
            <Segment>
              <Headline title underlined>
                {t('account')}
              </Headline>
              <UserSettingsForm
                user={user}
                useUpdatePasswordMutation={useUpdatePasswordMutation}
              />
            </Segment>
          </Section>
        </PageColumn>
      </PageGrid>
    </Main>
  );
};

export { UserSettingsForm };
