import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import authenticationEN from './_locales/en/authentication.json';
import authenticationDE from './_locales/de/authentication.json';
import userSettingsEN from './_locales/en/userSettings.json';
import userSettingsDE from './_locales/de/userSettings.json';

const resources = {
  en: {
    authentication: authenticationEN,
    userSettings: userSettingsEN,
  },
  de: {
    authentication: authenticationDE,
    userSettings: userSettingsDE,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'de-DE',
    fallbackLng: 'de-DE',
    debug: process.env.I18NEXT_DEBUG === 'true',
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
