import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span<{ $color?: string; $textColor?: string }>`
  background: ${(props) => props.$color || props.theme.palette.gray[800]};
  padding: 2px 5px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  border-radius: 4px;
  font-weight: bold;
  color: ${(props) => props.$textColor || '#fff'};
`;

export interface TagProps {
  color?: string;
  textColor?: string;
  className?: string;
}

export const Tag: React.FC<TagProps> = ({
  color,
  textColor,
  className,
  children,
}) => {
  return (
    <Wrapper $color={color} $textColor={textColor} className={className}>
      {children}
    </Wrapper>
  );
};
