import React from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ArrowRight } from '../icons/icons';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  padding: 12px 8px;
`;

const ItemButton = styled.button`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  font-size: 15px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ItemLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }

  &:visited,
  &:active {
    color: inherit;
  }
`;

export interface BreadcrumbsProps {
  items: { label: string; href?: string }[];
  onItemClick?: <T>(item: T, index: number) => void;
  className?: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items,
  onItemClick,
  className,
}) => {
  const theme = useTheme();

  return (
    <Wrapper className={className}>
      {items.map((item, index) => {
        if (item.href) {
          return (
            <ItemLink key={item.href} to={item.href}>
              {item.label}
              {index < items.length - 1 && (
                <ArrowRight size={20} color={theme.primaryColor} />
              )}
            </ItemLink>
          );
        }
        return (
          <ItemButton
            key={item.href}
            onClick={() => onItemClick?.(item, index)}
          >
            {item.label}
            {index < items.length - 1 && (
              <ArrowRight size={20} color={theme.primaryColor} />
            )}
          </ItemButton>
        );
      })}
    </Wrapper>
  );
};
