import React, { useContext, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { SelectableCheckbox } from './selectable-checkbox';

interface SelectableProps {
  id?: string;
  value: any;
  onChange: (value: any) => void;
  className?: string;
}

const Context = React.createContext<{
  id?: string;
  value: any;
  onChange: (val: any) => void;
}>({
  id: '',
  value: '',
  onChange: (_: any) => {},
});

export function Selectable({
  children,
  className,
  id,
  value,
  onChange,
}: React.PropsWithChildren<SelectableProps>) {
  return (
    <Context.Provider
      value={{
        id,
        value,
        onChange,
      }}
    >
      <div className={className}>{children}</div>
    </Context.Provider>
  );
}

const OptionWrapper = styled.div<{
  disabled: boolean;
  selected: boolean;
  $allowOverflow?: boolean;
}>`
  background: ${(props) => props.theme.palette.background};
  display: flex;
  flex-direction: column;
  border: 1px solid
    ${(props) => {
      if (props.selected) return props.theme.primaryColor;
      if (props.disabled) return props.theme.palette.border;
      return props.theme.palette.borderEmphasis;
    }};
  padding: 12px;
  border-radius: 4px;
  margin: 5px 0;
  overflow: ${(props) => (props.$allowOverflow ? 'visible' : 'hidden')};
  color: ${(props) =>
    props.disabled ? props.theme.palette.textMuted : 'inherit'};
  cursor: ${(props) => {
    if (props.disabled) {
      return 'not-allowed';
    }
    if (props.selected) {
      return 'default';
    }
    return 'pointer';
  }};

  &:hover {
    .checkbox-wrapper {
      background: ${(props) => props.theme.palette.backgroundMuted};
      border-color: ${(props) => {
        if (props.selected) return props.theme.primaryColor;
        if (props.disabled) return props.theme.palette.borderEmphasis;
        return props.theme.palette.text;
      }};
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
`;

const OptionText = styled.div<{ $selected?: boolean }>`
  font-weight: ${(props) => (props.$selected ? '600' : 'normal')};
  flex: 1;
`;

const ContentWrapper = styled.div<{ $allowOverflow?: boolean }>`
  padding: 12px 0 0 0;
  overflow: ${(props) => (props.$allowOverflow ? 'visible' : 'hidden')};
  position: relative;
`;

type OptionProps = {
  content?: React.ReactNode;
  disabled?: boolean;
  value: any;
  allowOverflow?: boolean;
  className?: string;
};

export function SelectableOption({
  children,
  content,
  disabled,
  value,
  allowOverflow,
  className,
}: React.PropsWithChildren<OptionProps>) {
  const collapsable = !!content;

  const { value: selectValue, onChange, id } = useContext(Context);
  const isSelected = React.useMemo(
    () => value === selectValue,
    [value, selectValue]
  );
  const [collapsed, setCollapsed] = React.useState(isSelected);

  const onSelect = useCallback(() => {
    if (!disabled) {
      onChange(value);
    }
  }, [onChange, disabled, value]);

  useEffect(() => {
    setCollapsed(!isSelected);
  }, [isSelected]);

  const selectableId = id ? `${id}-${value}` : `selectable-${value}`;
  const contentId = `${selectableId}-content`;

  const additionalProps = {
    ...(collapsable && {
      'aria-controls': contentId,
      'aria-expanded': isSelected,
      'aria-checked': isSelected,
    }),
    ...(!collapsable && {
      'aria-checked': isSelected,
    }),
  };

  return (
    <OptionWrapper
      onClick={onSelect}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelect();
        }
      }}
      className={className}
      disabled={!!disabled}
      selected={isSelected}
      key={value}
      id={selectableId}
      data-testid={selectableId}
      role="button"
      tabIndex={0}
      $allowOverflow={allowOverflow}
      {...additionalProps}
    >
      <LabelWrapper>
        <OptionText $selected={isSelected}>{children}</OptionText>
        <SelectableCheckbox selected={isSelected} disabled={!!disabled} />
      </LabelWrapper>
      <AnimatePresence initial={!collapsed}>
        {collapsable && !collapsed && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ContentWrapper
              role="region"
              id={contentId}
              data-testid={contentId}
              aria-labelledby={selectableId}
              $allowOverflow={allowOverflow}
            >
              {content}
            </ContentWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </OptionWrapper>
  );
}
