import { Link as LinkComponent } from '../link/link';
import { FormWrapper, TextWrapper } from '../layout/layout';
import { ButtonGroup } from '../button/button-group';
import styled from 'styled-components';

export const Emoji = styled.div`
  font-size: 36px;
  margin-bottom: 4px;
  text-align: center;
`;

export const Link = styled(LinkComponent)`
  float: right;
  font-weight: 400;
`;

export const LoginContainer = styled(FormWrapper)`
  width: 300px;

  .form-element {
    margin-bottom: 0;
  }
`;

export const LoginActions = styled(ButtonGroup)`
  width: 100%;
  margin-top: 17px;
`;

export const SuccessTextWrapper = styled(TextWrapper)`
  max-width: 440px;
  white-space: pre-wrap;
`;
