import React, { useCallback, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { DateTime } from 'luxon';
import Flatpickr from 'react-flatpickr';
import { Calendar } from '../icons/icons';

const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  background: ${(props) => props.theme.palette.backgroundMuted};
  border-radius: 4px;

  .icon {
    color: ${(props) => props.theme.palette.textSubtle};
    pointer-events: none;
    margin-right: 4px;
  }

  .display {
    background: none;
    appearance: none;
    cursor: pointer;
    outline: 0;

    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    padding: 0 40px 0 10px;
    width: 100%;

    border: 0;
    border-radius: 4px;
    color: ${(props) => props.theme.palette.textMuted};

    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
    line-height: 2.25;
  }
`;

export interface DatePickerProps {
  date?: Date;
  minDate?: Date;
  maxDate?: Date;
  withTime?: boolean;
  onDateChange?: (date: Date) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  date,
  minDate,
  maxDate,
  withTime,
  onDateChange = () => {},
}) => {
  const theme = useTheme();
  const handleDateChanged = useCallback(
    ([_date]: Date[]) => {
      onDateChange(_date);
    },
    [onDateChange]
  );

  const options = useMemo(
    () => ({
      altFormat: withTime ? 'd.m.y H:i' : 'd.m.y',
      altInput: true,
      altInputClass: 'display',
      enableTime: withTime,
      maxDate,
      minDate,
      monthSelectorType: 'static',
      parseDate: (date: string, _: string) => DateTime.fromISO(date).toJSDate(),
      time_24hr: true,
    }),
    [withTime, minDate, maxDate]
  );

  return (
    <Flatpickr
      data-enable-time={withTime}
      value={date}
      onChange={handleDateChanged}
      options={options}
      render={(_: any, ref: React.RefObject<HTMLInputElement>) => {
        return (
          <>
            <Container>
              <input
                ref={ref}
                className="display"
                value={
                  date && DateTime.fromJSDate(date).toFormat('yyyy-MM-ddTHH:mm')
                }
              />
              <Calendar
                className="icon"
                color={theme.palette.borderEmphasis}
                size={16}
              />
            </Container>
          </>
        );
      }}
    />
  );
};
