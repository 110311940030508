import * as React from 'react';
import styled from 'styled-components';
import type { TabValue } from './vertical-tabs';

const TabItem = styled.button<{ $selected?: boolean; disabled?: boolean }>`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0;

  text-align: left;
  text-decoration: none;

  color: ${(props) => {
    if (props.disabled) {
      return props.theme.palette.textSubtle;
    }
    if (props.$selected) {
      return props.theme.primaryColor;
    }
    return props.theme.palette.text;
  }};

  border-left: ${(props) =>
    props.$selected
      ? `2px solid ${props.theme.primaryColor}`
      : '2px solid transparent'};

  &:hover {
    color: ${(props) =>
      !props.disabled && !props.$selected && props.theme.palette.textMuted};
  }
`;

export interface TabProps {
  label: React.ReactNode;
  as?: React.ElementType;
  value?: TabValue;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChange?: (value: any) => void;
  selected?: boolean;
  tabIndex?: number;
  disabled?: boolean;

  to?: string;
  href?: string;
  component?: React.ElementType;
  LinkComponent?: React.ElementType;
}

export function Tab({
  disabled = false,
  label: title,
  selected,
  value,
  onChange,
  onClick,
  component: componentProp = 'button',
  LinkComponent = 'a',
  ...rest
}: TabProps) {
  let component = componentProp;

  if (component === 'button' && (rest.href || rest.to)) {
    component = LinkComponent;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!selected && onChange) {
      onChange(value);
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <TabItem
      as={component}
      aria-selected={selected}
      disabled={disabled}
      role="tab"
      $selected={selected}
      tabIndex={selected ? 0 : -1}
      onClick={handleClick}
      {...rest}
    >
      {title}
    </TabItem>
  );
}
