import { setLocale } from 'yup';

/* eslint-disable no-template-curly-in-string */
setLocale({
  mixed: {
    default: 'Ungültig',
    oneOf: 'Muss einer der folgenden Werte sein: ${values}',
    notOneOf: 'Darf nicht einer der folgenden Werte sein: ${values}',
    required: 'Pflichtfeld',
    notType: ({
      type,
      value,
      originalValue,
    }: {
      type: string;
      value: any;
      originalValue: any;
    }) => {
      let isCast = originalValue != null && originalValue !== value;

      if (type === 'number') {
        return `Muss eine Zahl sein`;
      }
      if (type === 'date') {
        return `Muss ein Datum sein`;
      }

      let msg =
        `Must be a \`${type}\` type, ` +
        `but the final value was: \`${JSON.stringify(value)}\`` +
        (isCast
          ? ` (cast from the value \`${JSON.stringify(originalValue)}\`).`
          : '.');

      if (value === null) {
        msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
      }

      return msg;
    },
  },
  string: {
    length: 'Muss genau ${length} Zeichen lang sein',
    min: 'Muss mindestens ${min} Zeichen lang sein',
    max: 'Darf maximal ${max} Zeichen lang sein',
    matches: 'Ungültiges Format',
    email: 'Muss eine gültige E-Mail-Adresse sein',
    url: 'Muss eine gültige URL sein',
    trim: 'Muss eine gekürzte Zeichenkette sein',
    lowercase: 'Muss aus Kleinbuchstaben bestehen',
    uppercase: 'Muss aus Großbuchstaben bestehen',
  },
  number: {
    min: 'Muss größer oder gleich ${min} sein',
    max: 'Muss kleiner oder gleich ${max} sein',
    lessThan: 'Muss kleiner ${less} sein',
    moreThan: 'Muss größer ${more} sein',
    positive: 'Muss eine positive Zahl sein',
    negative: 'Muss eine negative Zahl sein',
    integer: 'Muss eine Zahl sein',
  },
  array: {
    min: 'Muss mindestens ${min} Elemente besitzen',
    max: 'Muss weniger oder genau ${max} Elemente besitzen',
  },
});
