import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Checkmark, Warning } from '../icons/icons';
import { FlexRow, Flex } from '../layout/layout';

export interface StepperProps {
  steps: { label: string }[];
  index: number;
  stepsWithErrors?: number[];
  onStepClick?(index: number): void;
}

const Wrapper = styled(FlexRow)``;

const Step = styled(Flex)`
  text-align: center;

  :first-child .line:first-child {
    opacity: 0;
  }

  :last-child .line:last-child {
    opacity: 0;
  }
`;

const StepIcon = styled.div<{ isCurrent: boolean }>`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.palette.background};
  background: ${(props) =>
    props.isCurrent ? props.theme.primaryColor : props.theme.palette.textMuted};
  text-align: center;
  font-size: 13px;
  border-radius: 50px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepLabel = styled.div<{ isDone: boolean }>`
  color: ${(props) =>
    props.isDone ? props.theme.palette.text : props.theme.palette.textMuted};
  font-size: 14px;
  padding: 0 30px;
  cursor: pointer;
`;

const Line = styled.div<{ isDone: boolean }>`
  flex: 1;
  border-top: 1px solid
    ${(props) =>
      props.isDone ? props.theme.primaryColor : props.theme.palette.border};
  position: relative;
  top: 15px;
`;

export const Stepper = ({
  steps,
  index,
  stepsWithErrors = [],
  onStepClick,
}: StepperProps) => {
  const theme = useTheme();
  return (
    <Wrapper>
      {steps.map((s, stepIndex: number) => (
        <Step
          key={s.label}
          onClick={() => onStepClick && onStepClick(stepIndex)}
        >
          <FlexRow>
            <Line className="line" isDone={stepIndex <= index} />
            {stepsWithErrors.includes(stepIndex) ? (
              <Warning size={32} color={'red'} />
            ) : stepIndex < index ? (
              <Checkmark size={32} color={theme.primaryColor} />
            ) : (
              <StepIcon isCurrent={stepIndex === index}>
                {stepIndex + 1}
              </StepIcon>
            )}
            <Line className="line" isDone={stepIndex < index} />
          </FlexRow>
          <StepLabel isDone={stepIndex <= index}>{s.label}</StepLabel>
        </Step>
      ))}
    </Wrapper>
  );
};
