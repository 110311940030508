import * as React from 'react';
import styled from 'styled-components';

import { Tab, TabProps } from './tab';

export type { TabProps };

const TabGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;

  min-width: 200px;
  overflow: hidden;
`;

const GroupHeader = styled.strong`
  font-weight: bold;
  padding: 8px 16px;
  color: ${(props) => props.theme.palette.text};
`;

export type TabValue = React.ReactText | boolean | undefined | null;

export interface VerticalTabsProps {
  children: React.ReactNode;
  /**
   * Define the start of the index. Useful if not using the `value` prop of `<Tab />` component and using multiple `<VerticalTabs />`
   */
  indexStart?: number;
  /**
   * Set a title for a vertical tab group
   */
  title?: React.ReactNode;
  value?: TabValue;
  onChange?: (index: any) => void;
  className?: string;
}

function VerticalTabsComponent({
  children: childrenProp,
  title,
  value,
  onChange,
  indexStart = 0,
  className,
}: VerticalTabsProps) {
  let tabIndex = indexStart;

  const children = React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const childValue =
      child.props.value === undefined ? tabIndex : child.props.value;
    const selected = childValue === value;

    tabIndex += 1;
    return React.cloneElement(child as React.ReactElement<TabProps>, {
      selected,
      onChange,
      value: childValue,
      ...(tabIndex === 1 &&
        value === false &&
        !child.props.tabIndex && { tabIndex: 0 }),
    });
  });

  return (
    <TabGroup className={className} role="tablist">
      {title ? <GroupHeader>{title}</GroupHeader> : null}
      {children}
    </TabGroup>
  );
}

export const VerticalTabs = Object.assign(VerticalTabsComponent, {
  Tab: Tab,
});

export const VerticalTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TabNavigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
