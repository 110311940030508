import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { AlertRetryable } from '../alert/alert-retryable';
import { Button } from '../button/button';
import { SpinnerDark } from '../spinner/spinner';
import { TextWrapper } from '../layout/layout';
import { FormikInput, FormikSubmit } from '../form/input';
import { Checkmark } from '../icons/icons';
import { SegmentTitle } from '../typography/typography';
import { LoginActions, LoginContainer } from '../login/login.style';

const inputValidationSchema = yup.object().shape({
  password: yup.string().min(5).required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwörter stimmen nicht überein.'),
});

const SpinnerWithPadding = styled(SpinnerDark)`
  padding: 14px 0;
`;

export interface ResetPasswordProps {
  token: string | null;
  email: string | null;
  resetPassword: ({
    email,
    password,
    token,
  }: {
    email: string;
    password: string;
    token: string;
  }) => Promise<{ data?: any }>;
  resetPasswordLoading: boolean;
  checkTokenLoading: boolean;
  errorMessage?: string;
  onSuccess: () => void;
  onCancel: () => void;
  isSuccess?: boolean;
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  token,
  email,
  resetPassword,
  checkTokenLoading,
  resetPasswordLoading,
  errorMessage,
  onSuccess,
  isSuccess,
  onCancel,
}) => {
  const { t } = useTranslation('authentication');
  const [, setPasswordReset] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const theme = useTheme();

  if (typeof token !== 'string' || typeof email !== 'string') {
    return (
      <AlertRetryable title="Ungültige Session" message=" " retryable={false} />
    );
  }

  if (error || errorMessage) {
    return <AlertRetryable message={error ?? errorMessage} />;
  }

  if (checkTokenLoading) {
    return <SpinnerWithPadding size={25} />;
  }

  if (isSuccess) {
    return (
      <>
        <Checkmark size={64} color={theme.palette.success.color} />
        <TextWrapper style={{ maxWidth: 440 }}>
          {t('passwordUpdated')}
        </TextWrapper>
        <Button expand onClick={onSuccess}>
          {t('backToLogin')}
        </Button>
      </>
    );
  }

  return (
    <Formik
      initialValues={{ password: '', passwordConfirmation: '' }}
      validationSchema={inputValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        resetPassword({
          email,
          password: values.password,
          token,
        })
          .then((data) => {
            setSubmitting(false);
            if (data) {
              switch (data.data?.resetPassword?.__typename) {
                case 'ResetPasswordSuccess':
                  setPasswordReset(true);
                  break;
                case 'UnknownError':
                  setError(data.data.resetPassword.message);
                  break;
                default:
                  setError('Unknown error');
                  break;
              }
            }
          })
          .catch((err) => {
            setSubmitting(false);
            setError(err.message);
          });

        return;
      }}
    >
      {() => (
        <LoginContainer>
          <SegmentTitle>{t('passwordResetTitle')}</SegmentTitle>
          {t('passwordResetMessage')}
          <FormikInput
            data-testid="reset-password"
            type="password"
            name="password"
            id="password"
            autoComplete="current-password"
            label={t('newPassword')}
            placeholder={t('newPassword')}
            required
          />
          <FormikInput
            data-testid="reset-password-confirm"
            type="password"
            name="passwordConfirmation"
            id="passwordConfirmation"
            label={t('newPasswordConfirm')}
            placeholder={t('newPasswordConfirm')}
            required
          />
          <LoginActions>
            <Button
              expand
              secondary
              disabled={resetPasswordLoading}
              onClick={onCancel}
            >
              {t('cancel')}
            </Button>
            <FormikSubmit expand disabled={resetPasswordLoading}>
              {t('safe')}
            </FormikSubmit>
          </LoginActions>
        </LoginContainer>
      )}
    </Formik>
  );
};
