import * as React from 'react';
import styled from 'styled-components';
import {
  ToastContainer,
  ToastContainerProps,
  toast as displayToast,
  ToastContent,
  ToastOptions,
  Slide,
  UpdateOptions,
  TypeOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Icons from '../icons/icons';
import { defaultTheme } from '../theme/theme';

export type ToastOptionsType = Omit<ToastOptions, 'type' | 'icon'>;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    --toastify-font-family: ${({ theme }) => theme.fontFamily};
  }

  &&&.Toastify__toast-container--bottom-left {
    left: 7em;
  }

  .Toastify__toast-icon {
    width: 30px;
    margin-inline-end: 8px;
    align-self: flex-start;
  }
`;

interface PromiseToast {
  promise: Promise<unknown>;
  pending: React.ReactNode;
  success: React.ReactNode;
  error: React.ReactNode;
}

export const ToastType = displayToast.TYPE;

export const ToastContext = React.createContext<{
  toast: (message: ToastContent, options?: ToastOptions) => React.ReactText;
  success: (
    message: ToastContent,
    options?: ToastOptionsType
  ) => React.ReactText;
  info: (message: ToastContent, options?: ToastOptionsType) => React.ReactText;
  warning: (
    message: ToastContent,
    options?: ToastOptionsType
  ) => React.ReactText;
  error: (message: ToastContent, options?: ToastOptionsType) => React.ReactText;
  loading: (
    message: ToastContent,
    options?: ToastOptionsType
  ) => React.ReactText;
  promiseToast: (
    data: PromiseToast,
    options?: ToastOptionsType
  ) => Promise<unknown>;
  update: (id: React.ReactText, options: UpdateOptions) => void;
  createOrUpdate: (
    id: React.ReactText | undefined | null,
    message: ToastContent,
    options?: ToastOptions
  ) => React.ReactText;
  dismiss: (id: React.ReactText) => void;
}>({
  toast: () => '',
  success: () => '',
  info: () => '',
  warning: () => '',
  error: () => '',
  loading: () => '',
  promiseToast: () => Promise.resolve(),
  update: () => {},
  createOrUpdate: () => '',
  dismiss: () => {},
});

export const useToasts = () => React.useContext(ToastContext);

const InfoIcon = () => (
  <Icons.Info size={30} color={defaultTheme.palette.info.color} />
);

const SuccessIcon = () => (
  <Icons.Checkmark size={30} color={defaultTheme.palette.success.color} />
);

const WarningIcon = () => (
  <Icons.Warning size={30} color={defaultTheme.palette.warning.color} />
);

const ErrorIcon = () => (
  <Icons.Warning size={30} color={defaultTheme.palette.error.color} />
);

export interface ToastProviderProps
  extends Omit<ToastContainerProps, 'theme'> {}

export function ToastProvider({
  children,
  ...overrideOptions
}: React.PropsWithChildren<ToastProviderProps>) {
  const defaultOptions: ToastOptionsType = React.useMemo(
    () => ({
      theme: 'dark',
    }),
    []
  );

  const getIconByType = (type?: TypeOptions | null) => {
    switch (type) {
      case ToastType.SUCCESS:
        return SuccessIcon;
      case ToastType.INFO:
        return InfoIcon;
      case ToastType.WARNING:
        return WarningIcon;
      case ToastType.ERROR:
        return ErrorIcon;
      default:
        return undefined;
    }
  };

  const toast = React.useCallback(
    (message: ToastContent, options?: ToastOptions) =>
      displayToast(message, {
        ...defaultOptions,
        icon: getIconByType(options?.type),
        ...options,
      }),
    [defaultOptions]
  );

  const success = React.useCallback(
    (message: ToastContent, options?: ToastOptionsType) =>
      displayToast.success(message, {
        ...defaultOptions,
        ...options,
        icon: SuccessIcon,
      }),
    [defaultOptions]
  );

  const info = React.useCallback(
    (message: ToastContent, options?: ToastOptionsType) =>
      displayToast.info(message, {
        ...defaultOptions,
        ...options,
        icon: InfoIcon,
      }),
    [defaultOptions]
  );

  const warning = React.useCallback(
    (message: ToastContent, options?: ToastOptionsType) =>
      displayToast.warning(message, {
        ...defaultOptions,
        ...options,
        icon: WarningIcon,
      }),
    [defaultOptions]
  );

  const error = React.useCallback(
    (message: ToastContent, options?: ToastOptionsType) =>
      displayToast.error(message, {
        ...defaultOptions,
        ...options,
        icon: ErrorIcon,
      }),
    [defaultOptions]
  );

  const loading = React.useCallback(
    (message: ToastContent, options?: ToastOptionsType) =>
      displayToast.loading(message, {
        closeOnClick: false,
        ...defaultOptions,
        ...options,
      }),
    [defaultOptions]
  );

  const promiseToast = React.useCallback(
    (
      { promise, pending, success, error }: PromiseToast,
      options?: ToastOptionsType
    ) => {
      return displayToast.promise(promise, {
        pending: {
          render() {
            return pending;
          },
          theme: 'dark',
        },
        success: {
          render() {
            return success;
          },
          theme: 'dark',
          icon: SuccessIcon,
        },
        error: {
          render({ data }) {
            console.error(data);
            return error;
          },
          theme: 'dark',
          icon: ErrorIcon,
        },
        ...options,
      });
    },
    []
  );

  const update = React.useCallback(
    (id: React.ReactText, options: UpdateOptions) => {
      displayToast.update(id, {
        icon: getIconByType(options.type),
        ...defaultOptions,
        ...options,
      });
    },
    [defaultOptions]
  );

  const createOrUpdate = React.useCallback(
    (
      id: React.ReactText | undefined | null,
      message: ToastContent,
      options?: ToastOptions
    ) => {
      if (id) {
        displayToast.update(id, {
          icon: getIconByType(options?.type),
          render: message,
          ...defaultOptions,
          ...options,
        });
        return id;
      }
      return displayToast(message, {
        icon: getIconByType(options?.type),
        ...defaultOptions,
        ...options,
      });
    },
    [defaultOptions]
  );

  const dismiss = React.useCallback(
    (id: React.ReactText) => displayToast.dismiss(id),
    []
  );

  const value = React.useMemo(
    () => ({
      toast,
      success,
      info,
      warning,
      error,
      loading,
      promiseToast,
      update,
      createOrUpdate,
      dismiss,
    }),
    [
      toast,
      success,
      info,
      warning,
      error,
      loading,
      promiseToast,
      update,
      createOrUpdate,
      dismiss,
    ]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <StyledToastContainer
        position="bottom-left"
        hideProgressBar
        newestOnTop={false}
        autoClose={false}
        limit={5}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        transition={Slide}
        closeButton={false}
        {...overrideOptions}
      />
    </ToastContext.Provider>
  );
}

export const withToastProvider =
  <T extends object>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: any) => {
    return (
      <ToastProvider>
        <Component {...props} />
      </ToastProvider>
    );
  };
