import React from 'react';
import styled, { CSSProperties, useTheme } from 'styled-components';
import { matchPath, Link, useLocation } from 'react-router-dom';
import { TooltipLight as Tooltip } from '../tooltip/tooltip';
import { useRipple } from '../button/useRipple';
import { defaultTheme } from '../theme/theme';

const baseStyle: CSSProperties = {
  backgroundColor: defaultTheme.palette.background,
  border: 'none',
  fontWeight: 'bold',
  fontSize: '13px',
  padding: '14px',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
  borderRadius: '4px',
  color: defaultTheme.palette.textMuted,
  transition: `background-color 700ms`,
};

const smallStyle: CSSProperties = {
  padding: 8,
};

const Wrapper = styled.div`
  --icon-size: 20px;
  margin: 5px 10px;
  cursor: pointer;
`;

const DisabledWrapper = styled.div`
  --icon-size: 20px;
  margin: 5px 10px;
  opacity: 0.5;
`;

const Ripples = styled.div`
  position: relative;
  display: inline-flex;
  overflow: hidden;
`;

interface NavigationItemProps {
  icon?: any;
  label?: any;
  href?: string;
  onClick?: () => any;
  isActive?: boolean;
  onlyIconActive?: boolean;
  exact?: boolean;
  tooltipId?: string;
  tooltip?: string;
  disabled?: boolean;
  small?: boolean;
}

const NavigationItemComponent: React.FC<NavigationItemProps> = (props) => {
  const {
    icon,
    href,
    onClick,
    label,
    isActive,
    onlyIconActive,
    exact,
    tooltipId,
    disabled,
    small,
  } = props;
  const location = useLocation();
  const theme = useTheme();
  const { rippleStyle, fireRipple } = useRipple();
  const IconComp = icon;
  const macthingRoute = isActive
    ? isActive
    : matchPath(location.pathname, {
        path: href,
        exact: exact ? exact : false,
      });

  const nextStyle = {
    color: theme.primaryColor,
    backgroundColor: theme.secondaryColor,
  };

  const style = {
    ...baseStyle,
    ...(macthingRoute !== null && !onlyIconActive ? nextStyle : {}),
    ...(small ? smallStyle : {}),
  };

  const NavItemWrapper = disabled ? DisabledWrapper : Wrapper;

  const Icon = (
    <>
      <IconComp
        size="25"
        color={
          macthingRoute !== null
            ? theme.primaryColor
            : defaultTheme.palette.textMuted
        }
      />
      {/* <Label>{label}</Label> */}
    </>
  );

  const inner = disabled ? (
    <div style={style}>{Icon}</div>
  ) : (
    <Ripples
      style={style}
      onClick={(e) => {
        fireRipple(e);
      }}
    >
      {Icon}
      <s style={{ ...rippleStyle, ...nextStyle }} />
    </Ripples>
  );

  return (
    <NavItemWrapper data-tip data-for={tooltipId!}>
      {disabled ? (
        <>{inner}</>
      ) : onClick ? (
        <div
          onClick={onClick}
          title={label}
          role="button"
          tabIndex={0}
          onKeyDown={onClick}
        >
          {inner}
        </div>
      ) : (
        <Link
          to={href!}
          title={label}
          style={disabled ? { cursor: 'unset' } : {}}
        >
          {inner}
        </Link>
      )}
    </NavItemWrapper>
  );
};

function withTooltip<T extends { tooltip?: string; tooltipId?: string }>(
  Component: React.ComponentType<T>
) {
  return (props: T) => {
    if (props.tooltip && props.tooltipId) {
      return (
        <Tooltip text={props.tooltip!} id={props.tooltipId}>
          <Component {...props} />
        </Tooltip>
      );
    }
    return <Component {...props} />;
  };
}

export const NavigationItem = withTooltip(NavigationItemComponent);
