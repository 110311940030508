import React, { ChangeEvent, MouseEvent } from 'react';
import styled from 'styled-components';

//codepen.io/avstorm/pen/pXgKNB

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckboxLabel = styled.label<{ $disabled?: boolean }>`
  -webkit-user-select: none;
  user-select: none;
  cursor: ${(props) => (props.$disabled ? 'unset' : 'pointer')};
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;

  &:not(:last-child) {
    margin-right: 6px;
  }
  &:hover {
    ${(props) =>
      !props.$disabled && `background-color: ${props.theme.primaryColor}19;`}
  }
  & span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);

    &:first-child {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      transform: scale(1);
      border: 1px solid ${(props) => props.theme.primaryColor};
      transition: all 0.2s ease;
      box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
    }

    &:first-child svg {
      position: absolute;
      top: 3px;
      left: 2px;
      fill: none;
      stroke: ${(props) => props.theme.palette.background};
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
      transition: all 0.3s ease;
      transition-delay: 0.1s;
      transform: translate3d(0, 0, 0);
    }
    &:last-child {
      padding-left: 8px;
      line-height: 18px;
    }
  }

  &:hover span:first-child {
    ${(props) =>
      !props.$disabled && `border-color: ${props.theme.primaryColor};`}
  }
`;

const CheckboxInput = styled.input`
  position: absolute;
  visibility: hidden;

  &:checked + label span:first-child {
    background: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};
    animation: wave 0.4s ease;
  }

  &:checked + label span:first-child svg {
    stroke-dashoffset: 0;
  }

  @-moz-keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
  @-webkit-keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
  @-o-keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
`;

export interface CheckboxProps {
  checked: boolean;
  dense?: boolean;
  label?: string;
  id: string;
  onChange?: (value: boolean, e: ChangeEvent) => void;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  disabled?: boolean;
}

export const CheckboxNew: React.FC<CheckboxProps> = ({
  checked,
  label,
  id,
  onChange,
  onClick,
  className,
  disabled,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked, e);
  };

  const inputId = id ?? `checkbox-${label}`;

  return (
    <CheckboxWrapper onClick={(e) => onClick?.(e)} className={className}>
      <CheckboxInput
        type="checkbox"
        data-testid={id}
        id={inputId}
        checked={checked}
        readOnly
        onChange={handleChange}
        disabled={disabled}
      />
      <CheckboxLabel htmlFor={inputId} $disabled={disabled}>
        <span>
          <svg width="12px" height="10px">
            <symbol id="check" viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </symbol>
          </svg>
        </span>
        <span>{label}</span>
      </CheckboxLabel>
    </CheckboxWrapper>
  );
};
