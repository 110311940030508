import React from 'react';
import { Formik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';
import { FormikInput, FormikSubmit } from '../form/input';
import { TextWrapper } from '../layout/layout';
import { Emoji, Link, LoginActions, LoginContainer } from './login.style';
import { SpinnerDark } from '../spinner/spinner';
import { AlertRetryable } from '../alert/alert-retryable';

const inputValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required(),
});

export interface LoginProps {
  setLoginState: (loginState: 'login' | 'passwordReset') => void;
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<{ data?: any }>;
  onLoginSuccess: (payload: any) => void;
  initialValues: { email: string; password: string };
  automaticLoginCredentials?: { email: string; password: string };
}

export const Login: React.FC<LoginProps> = ({
  setLoginState,
  login,
  onLoginSuccess,
  initialValues,
  automaticLoginCredentials,
}) => {
  const { t } = useTranslation('authentication');
  const [isLoading, setLoadingState] = React.useState(false);
  const [error, setError] = React.useState('');

  const [twoFactorRequiredEmail, setTwoFactorRequiredEmail] = React.useState<
    null | string
  >(null);

  const onSubmit = React.useCallback(
    (values, { setSubmitting, setFieldError }) => {
      const { email, password } = values;

      setLoadingState(true);
      setSubmitting(true);
      login({ email, password })
        .then((data) => {
          setLoadingState(false);
          setSubmitting(false);
          switch (data?.data?.loginUser.__typename) {
            case 'ServiceNotReachableError':
              setFieldError('password', t('authenticationServiceNotAvailable'));
              break;
            case 'TwoFactorEmailFailed':
              setFieldError('password', t('twoFactorEmailFailed'));
              break;
            case 'WrongCredentialsError':
              setFieldError('password', t('emailOrPasswordFailed'));
              break;
            case 'TwoFactorRequired':
              setTwoFactorRequiredEmail(data?.data?.loginUser.email);
              break;
            case 'User':
              return onLoginSuccess(data);
            case 'UnknownError':
              setFieldError('password', data?.data?.loginUser.message);
              break;
          }
        })
        .catch((err: any) => {
          if (err.graphQLErrors && err.graphQLErrors[0]?.message) {
            setFieldError('password', err.graphQLErrors[0]?.message);
          } else {
            setFieldError('password', err.toString());
          }
          setLoadingState(false);
          setSubmitting(false);
        });
    },
    [login, onLoginSuccess, t]
  );

  React.useEffect(() => {
    if (automaticLoginCredentials) {
      onSubmit(automaticLoginCredentials, {
        setSubmitting: () => {},
        setFieldError: (_: string, err: string) => setError(err),
      });
    }
  }, [automaticLoginCredentials, onSubmit]);

  if (automaticLoginCredentials) {
    if (error) {
      return <AlertRetryable onRetry={login} message={error} />;
    }

    return (
      <>
        <SpinnerDark size={25} />
        <TextWrapper>Lädt...</TextWrapper>
      </>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={inputValidationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          {twoFactorRequiredEmail && (
            <TextWrapper style={{ maxWidth: 440 }}>
              <Emoji role="img" arial-label="mail">
                📨
              </Emoji>
              <Trans
                t={t}
                i18nKey="authentication.twoFactorRequiredMessage"
                values={{ twoFactorRequiredEmail }}
              >
                Bitte klicke zum Einloggen auf den Link, den wir dir an{' '}
                <strong>{twoFactorRequiredEmail}</strong> gesendet haben.
              </Trans>
            </TextWrapper>
          )}
          {!twoFactorRequiredEmail && (
            <LoginContainer>
              <FormikInput
                data-testid="login-user-email"
                type="email"
                name="email"
                id="email"
                autoComplete="username"
                label={t('email')}
                placeholder={t('email')}
                required
              />
              <FormikInput
                data-testid="login-user-password"
                type="password"
                name="password"
                id="password"
                autoComplete="current-password"
                label={t('password')}
                labelComponent={
                  <Link onClick={() => setLoginState('passwordReset')}>
                    {t('forgottenPassword')}
                  </Link>
                }
                placeholder={t('password')}
                required
              />
              <LoginActions>
                <FormikSubmit
                  data-testid="login-btn-submit"
                  isLoading={isLoading}
                >
                  {t('login')}
                </FormikSubmit>
              </LoginActions>
            </LoginContainer>
          )}
        </>
      )}
    </Formik>
  );
};
