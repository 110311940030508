import React, { useState } from 'react';
import styled from 'styled-components';
import * as Icons from '../icons/icons';
import { IconProps } from '../icons/base-icon';
import { Button } from '../button/button';
import { defaultTheme } from '../theme/theme';

const Wrapper = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.error.color};
  padding: 12px 15px 12px 15px;
  background: ${(props) => props.theme.palette.error.background};
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
`;

const IconWrap = styled.div`
  margin-right: 0px;
  display: inline-block;
  position: relative;
  left: -5px;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => props.theme.palette.error.color};
  letter-spacing: 0.27px;
  vertical-align: bottom;
`;

const Code = styled.pre`
  font-size: 12px;
`;

const DetailButton = styled.button`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  border: 0;
  color: inherit;
  background-color: transparent;
  padding: 0;
`;

const Details = styled.div`
  margin-top: 5px;
`;

const Message = styled.div`
  white-space: break-spaces;
`;

export const ErrorMessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReportBug = styled(Button)`
  margin-top: 15px;
`;

export interface AlertRetryableProps {
  title?: string;
  message?: string;
  icon?: React.ComponentType<IconProps>;
  error?: any;
  retryable?: boolean;
  automaticRetry?: boolean;
  onRetry?: (...args: any) => any;
}

export function AlertRetryable({
  icon,
  title,
  message,
  error,
  retryable,
  automaticRetry = false,
  onRetry = () => window.location.reload(),
  ...rest
}: AlertRetryableProps) {
  const [isExpanded, setExpanded] = useState(false);

  let Icon = icon ? icon : Icons.Warning;
  let _title = title || 'Fehler';
  let _message = message || 'Etwas ist schiefgelaufen.';
  let details: any;
  let isDetailed = false;

  if (error) {
    if (error.networkError) {
      if (!navigator.onLine) {
        Icon = Icons.CloudWarning;
        _title = 'Keine Verbindung zum Internet';
        _message = 'Stelle eine Verbindung zum Internet her um fortzufahren.';
      } else {
        _message = 'Bei der Anfrage des Servers ist ein Fehler aufgetreten.';
      }
    } else {
      _message = 'Bei der Anfrage des Servers ist ein Fehler aufgetreten.';
      details = error.message;
    }

    if (error.graphQLErrors) {
      error.graphQLErrors.map((e: any) => `${e.message}`);
    }

    if (error.exception) {
      details = error.exception;
    } else if (error.stack) {
      details = error.stack;
    }
    const errorKeys = Object.keys(error);
    if (
      errorKeys.length > 0 &&
      errorKeys.every((key) =>
        ['message', 'traceId', 'payload', 'context'].includes(key)
      )
    ) {
      const detailedError = {
        Fehler: error.message,
        TraceId: error.traceId,
        Kontext: error.context,
        Payload: error.payload,
      };
      isDetailed = true;
      details = JSON.stringify(detailedError, null, 2);
    }
  }

  const [count, setCount] = React.useState(10);

  React.useEffect(() => {
    if (automaticRetry) {
      const interval = setInterval(() => {
        return setCount((c: number) => c - 1);
      }, 1000);

      if (count <= 0) {
        clearInterval(interval);
      }

      setTimeout(() => window.location.reload(), 10 * 1000);
    }
    // only execute at component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper {...rest}>
      <IconWrap>
        <Icon size={25} color={defaultTheme.palette.error.color} />
      </IconWrap>
      <Title>{_title}</Title>
      <Message>{_message}</Message>
      {automaticRetry && <div>Erneut versuchen in {count}</div>}
      {retryable !== false && (
        <DetailButton onClick={onRetry}>Erneut versuchen</DetailButton>
      )}

      {details ? (
        <Details>
          <>
            {error.stack && process.env.NODE_ENV === 'development' ? (
              <>
                <DetailButton onClick={() => setExpanded(!isExpanded)}>
                  {isExpanded ? 'Weniger' : 'Mehr'}
                </DetailButton>
                {isExpanded && <Code>{error ? error.stack : ''}</Code>}
              </>
            ) : null}
            {isDetailed && (
              <ReportBug
                onClick={() => {
                  window.open(
                    'mailto:' +
                      'servicedesk@ampeersenergy.de' +
                      '?subject=' +
                      'LS bug report' +
                      '&body=' +
                      encodeURIComponent(details)
                  );
                }}
              >
                Report
              </ReportBug>
            )}
          </>
        </Details>
      ) : null}
    </Wrapper>
  );
}

interface ErrorHandlerState {
  errorMessage?: string;
}

export class ErrorHandler extends React.Component<{}, ErrorHandlerState> {
  constructor(props: any) {
    super(props);
    this.state = { errorMessage: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { errorMessage: error.message };
  }

  componentDidCatch(_error: Error, _errorInfo: any) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.errorMessage) {
      return <AlertRetryable message={this.state.errorMessage} />;
    }

    return this.props.children;
  }
}

export const withErrorHandler =
  <T extends object>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: any) =>
    (
      <ErrorHandler>
        <Component {...props} />
      </ErrorHandler>
    );
