import React from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../theme/theme';

export interface SwitchProps {
  checked?: boolean;
  tinted?: boolean;
  activeColor?: string;
  passiveColor?: string;
  labelColor?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (value: any) => void;
}

const Container = styled.label<SwitchProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 1px 0 0 28px;
  font-size: 12px;
  letter-spacing: 0.6px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.8' : '1')};

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  input:checked + .label:before {
    background-color: ${(props) =>
      props.activeColor || props.theme.primaryColor};
  }

  input:checked + .label:after {
    background-color: ${(props) => props.theme.palette.background};
    left: 11px;
  }

  input:not(:checked) + .label {
    color: ${(props) => props.passiveColor || props.labelColor};
    opacity: 0.3;
  }

  input:checked + .label.tinted {
    color: ${(props) => props.activeColor || props.theme.primaryColor};
  }

  .label {
    display: flex;

    text-transform: uppercase;
    user-select: none;
    white-space: nowrap;
    font-weight: 600;

    color: ${(props) => props.labelColor};

    opacity: 1;
    transition: 0.2s ease-out;
    transition-property: opacity;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      background-color: ${(props) => props.theme.palette.background};
    }

    &:before {
      position: absolute;
      top: 2px;
      left: 0;
      bottom: auto;
      right: auto;
      border-radius: 15px;
      background-color: ${(props) => props.passiveColor};

      height: 15px;
      width: 24px;
    }

    &:after {
      position: absolute;
      top: 4px;
      left: 2px;
      bottom: auto;
      right: auto;
      transition: 0.2s ease-out;
      transition-property: background-color, left;
      border-radius: 50%;
      height: 11px;
      width: 11px;
    }
  }
`;

export const Switch: React.FC<SwitchProps> = ({
  checked,
  tinted,
  onChange = () => {},
  children,
  activeColor = '#3cc9bd',
  passiveColor = defaultTheme.palette.textMuted,
  labelColor = defaultTheme.palette.textMuted,
  disabled = false,
  className,
}) => {
  return (
    <Container
      activeColor={activeColor}
      passiveColor={passiveColor}
      labelColor={labelColor}
      disabled={disabled}
      className={className}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={(event) => onChange(event.target.value)}
      />
      <span className={`label ${tinted && 'tinted'}`}>{children}</span>
    </Container>
  );
};
