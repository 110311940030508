export const getDataFromLocalStorage = (name: string) => {
  try {
    const data = localStorage.getItem(name);
    return data;
  } catch (error) {
    return null;
  }
};

export const setDataInLocalStorage = (name: string, value: any) => {
  try {
    localStorage.setItem(name, value);
    return true;
  } catch (error) {
    return false;
  }
};

export const resetItemInLocalStorage = (name: string) => {
  try {
    localStorage.removeItem(name);
    return true;
  } catch (error) {
    return false;
  }
};
