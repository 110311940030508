import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Close } from '../icons/icons';

const CloseIcon = styled(Close)`
  vertical-align: bottom;
`;

export const CloseIconWrap = styled.button`
  cursor: pointer;
  display: inline-block;
  margin-top: inherit;
  align-self: center;
  background: ${({ theme }) => theme.palette.background};
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.border};
  border-image: initial;
  flex: 0 0 auto;
  padding: 0;

  &:hover {
    border-color: ${(props) => props.theme.primaryColor};

    svg {
      fill: ${(props) => props.theme.primaryColor};
    }
  }
`;

const ScreenReaderText = styled.span`
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
`;

export const PageClose = (props: React.ComponentPropsWithoutRef<'button'>) => {
  const theme = useTheme();

  return (
    <CloseIconWrap {...props}>
      <ScreenReaderText>Close</ScreenReaderText>
      <CloseIcon size={30} color={theme.palette.textMuted} />
    </CloseIconWrap>
  );
};
