import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { BaseIcon } from '../icons/base-icon';
import { Flex, FlexRow } from '../layout/layout';
import * as Icons from '../icons/icons';
import { NavigationItem } from '../navigation/navigation-item';

const Main = styled.div`
  flex: 1;
`;

const Sidebar = styled.div<{
  position?: 'left' | 'right';
  width?: string;
}>`
  order: ${(props) => (props.position === 'left' ? -1 : 1)};
  width: ${(props) => props.width ?? 'unset'};

  display: flex;
  flex-direction: row;
  border-right: ${(props) =>
    props.position === 'left' ? '1px solid #e1e1e1' : 'none'};
  border-left: ${(props) =>
    props.position === 'left' ? '' : '1px solid #e1e1e1'};
  background: #fff;
`;

const IconsWrapper = styled.div<{
  position?: 'left' | 'right';
  open?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-right: ${(props) =>
    props.position === 'right' && props.open ? '1px solid #e1e1e1' : 'none'};
  border-left: ${(props) =>
    props.position === 'left' && props.open ? '1px solid #e1e1e1' : 'none'};
  order: ${(props) => (props.position === 'left' ? 1 : -1)};

  .icon {
    cursor: pointer;
    margin: 10px;
  }
`;

const Content = styled(Flex)`
  overflow: hidden;
`;
export interface CollapsiblePaneProps {
  defaultOpen?: boolean;
  defaultOpenIndex?: number;
  sidebarItems: { Icon: typeof BaseIcon; content: React.ReactElement }[];
  sidebarWidth?: string;
  sidebarPosition?: 'left' | 'right';
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  className?: string;
}

export const CollapsiblePanel: React.FC<CollapsiblePaneProps> = ({
  children,
  sidebarItems,
  defaultOpen = false,
  defaultOpenIndex = 0,
  sidebarWidth,
  sidebarPosition = 'left',
  isOpen,
  setIsOpen,
  className,
}) => {
  const theme = useTheme();
  const [sidebarIndex, setSidebarIndex] = useState(defaultOpenIndex);
  const [sidebarOpen, setSidebarOpen] = useState(defaultOpen);

  const OpenIcon =
    sidebarPosition === 'left' ? Icons.ArrowLeft : Icons.ArrowRight;
  const ClosedIcon =
    sidebarPosition === 'left' ? Icons.ArrowRight : Icons.ArrowLeft;

  return (
    <FlexRow className={className}>
      <Main className="main">{children}</Main>
      <Sidebar
        width={isOpen ?? sidebarOpen ? sidebarWidth : undefined}
        position={sidebarPosition}
        className="sidebar"
      >
        <IconsWrapper open={isOpen ?? sidebarOpen} position={sidebarPosition}>
          {isOpen ?? sidebarOpen ? (
            <OpenIcon
              className="icon"
              onClick={() => {
                if (setIsOpen) setIsOpen(false);
                else setSidebarOpen(false);
              }}
              size={35}
              color={theme.palette.textMuted}
            />
          ) : (
            <ClosedIcon
              className="icon"
              onClick={() => {
                if (setIsOpen) setIsOpen(true);
                else setSidebarOpen(true);
              }}
              size={35}
              color={theme.palette.textMuted}
            />
          )}
          {sidebarItems.length > 1 &&
            sidebarItems.map(({ Icon }, index) => (
              <NavigationItem
                small
                isActive={index === sidebarIndex}
                icon={Icon}
                onClick={() => {
                  setSidebarIndex(index);
                  if (setIsOpen) setIsOpen(true);
                  else setSidebarOpen(true);
                }}
              />
            ))}
        </IconsWrapper>
        <Content>
          {(isOpen ?? sidebarOpen) && sidebarItems[sidebarIndex].content}
        </Content>
      </Sidebar>
    </FlexRow>
  );
};
