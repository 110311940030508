import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin-bottom: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  height: 40px;
`;

const StyledImg = styled.img`
  border-radius: 4px;
`;

const Logo = (props: any) => {
  return (
    <Wrapper>
      <Link to={'/'}>
        {props.theme.logo && (
          <StyledImg width="40" height="40" src={props.theme.logo} />
        )}
      </Link>
    </Wrapper>
  );
};

export default withTheme(Logo);
