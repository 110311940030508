import React, { useState, FormEvent } from 'react';
import Autosuggest from 'react-autosuggest';

import {
  FilterBarWrapper,
  Item,
  ItemLabel,
  ItemList,
  ItemValue,
  RemoveButton,
} from './style';
import {
  ColumnDefinition,
  Filter,
  FilterBarItemProps,
  Suggestion,
  Column,
  FilterBarProps,
} from './types';
import { Close, Search } from '../icons/icons';
import { defaultTheme } from '../theme/theme';

/**
 * renders one filter item in the "filter" bar
 */
const FilterBarItem = ({ label, value, removeFilter }: FilterBarItemProps) => {
  return (
    <Item>
      <ItemLabel>{label}</ItemLabel>
      <ItemValue>{value}</ItemValue>
      <RemoveButton secondary onClick={removeFilter}>
        <Close size={20} />
      </RemoveButton>
    </Item>
  );
};

/**
 * generate the suggestions, filter the already suggested items out
 */
function filterSuggestions(
  inputValue: string,
  filters: Filter[],
  columns: Array<Column>
): Array<Suggestion> {
  if (inputValue.trim() === '') {
    return [];
  }

  console.log('columns', columns);
  console.log('filters', filters);
  return columns
    .filter(({ id }) => !filters.find((f) => f.id === id))
    .map(({ id, label }) => ({
      key: label,
      value: inputValue,
      id,
    }));
}

export const FilterBar = ({
  filters,
  setAllFilters,
  columns: unMappedColumns,
  kind,
}: FilterBarProps) => {
  const columns: Column[] = React.useMemo(
    () =>
      unMappedColumns
        .map(({ Header, accessor, filterable }: ColumnDefinition<any>) => ({
          id: accessor as string,
          label: Header,
          filterable:
            filterable === true || filterable === false ? filterable : true,
        }))
        .filter((c) => c.filterable),

    [unMappedColumns]
  );

  let lastSuggestion: Suggestion | null = null;

  const [suggestions, setSuggestions] = useState(
    // filterSuggestions('', filters, columns)
    filterSuggestions('', [], columns)
  );

  console.log('suggestions', suggestions);

  const [inputValue, setInputValue] = useState('');

  /**
   * when the input changes simply set the value
   */
  function onChange(_: FormEvent<any>, { newValue }: { newValue: string }) {
    setInputValue(newValue);
  }

  /**
   * generate new suggestions
   */
  function onSuggestionsFetchRequested({ value }: { value: string }) {
    // setSuggestions(filterSuggestions(value, filters, columns));

    setSuggestions(filterSuggestions(value, [], columns));
  }

  /**
   * gets called when user selects one suggestion via keyboard or mouse
   */
  function onSuggestionsClearRequested() {
    if (lastSuggestion) {
      setAllFilters([
        { id: lastSuggestion.id, value: lastSuggestion.value },
        ...filters,
      ]);
    }
    setInputValue('');
    setSuggestions([]);
  }

  /**
   * when the user hovers one suggestion, format it nicely
   * for the input
   */
  function getSuggestionValue(suggestion: Suggestion): string {
    return `${suggestion.key}: ${suggestion.value}`;
  }

  /**
   * store the last suggestion to apply it as filter
   * when the user enters or clicks the next time
   */
  function onSuggestionSelected(
    _: FormEvent<any>,
    { suggestion }: { suggestion: Suggestion }
  ) {
    lastSuggestion = suggestion;
  }

  /**
   * format the suggestion in the dropdown list
   */
  function renderSuggestion(
    suggestion: Suggestion
    /* { query, isHighlighted }: { query: string; isHighlighted: boolean }*/
  ): any {
    return (
      <>
        <ItemLabel>{suggestion.key}</ItemLabel>
        <ItemValue>{suggestion.value}</ItemValue>
      </>
    );
  }

  const inputProps = {
    placeholder: `${kind} Durchsuchen…`,
    value: inputValue,
    onChange,
  };

  const filterItems = filters.map((filter: Filter) => {
    const column = columns.find((col) => col.id === filter.id);
    console.log('column', column);
    return (
      <FilterBarItem
        key={`${filter.id}-${filter.value}`}
        label={column ? column.label : ''}
        value={filter.value}
        removeFilter={() => {
          const nextFilter = filters.filter((f: Filter) => f !== filter);
          setAllFilters(nextFilter);
        }}
      />
    );
  });

  return (
    <FilterBarWrapper>
      <Search size={25} color={defaultTheme.palette.textMuted} />
      <ItemList>{filterItems}</ItemList>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion={true}
      />
    </FilterBarWrapper>
  );
};
