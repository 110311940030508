import React from 'react';
import styled from 'styled-components';
import { BaseIcon, IconProps } from '../icons/base-icon';

export const OpenInvoice = (props: IconProps) => (
  <BaseIcon {...props}>
    <path d="M15,6 C10.0294373,6 6,10.0294373 6,15 C6,19.9705627 10.0294373,24 15,24 C19.9705627,24 24,19.9705627 24,15 C24,10.0294373 19.9705627,6 15,6 Z M15,8.34782609 C18.6738942,8.34782609 21.6521739,11.3261058 21.6521739,15 C21.6521739,18.6738942 18.6738942,21.6521739 15,21.6521739 C11.3261058,21.6521739 8.34782609,18.6738942 8.34782609,15 C8.34782609,11.3261058 11.3261058,8.34782609 15,8.34782609 Z"></path>
  </BaseIcon>
);

export const CancelledInvoice = (props: IconProps) => (
  <BaseIcon {...props}>
    <path d="M15,6 C19.9705627,6 24,10.0294373 24,15 C24,19.9705627 19.9705627,24 15,24 C10.0294373,24 6,19.9705627 6,15 C6,10.0294373 10.0294373,6 15,6 Z M19.6956522,14.2173913 L11.0869565,14.2173913 L11.0869565,16.5652174 L19.6956522,16.5652174 L19.6956522,14.2173913 Z"></path>
  </BaseIcon>
);

const SepaReturnTyp = styled.div`
  position: absolute;
  margin-left: 6px;
  margin-top: -16px;
`;

const SepaReturnWrapper = styled.div`
  width: 26px;
  padding-top: 4px;
  position: relative;
`;

export const SepaReturn: React.FC<{}> = ({ children, ...rest }) => (
  <SepaReturnWrapper {...rest}>
    <svg
      width="12px"
      viewBox="0 -2 20 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ marginLeft: 2 }}
    >
      <path
        d="M12.0127902,0 L6.19741705,0 L6.19741705,3.41880342 L12.0127902,3.41880342 C14.585773,3.41880342 16.6778788,5.57350427 16.6778788,8.22307692 C16.6778788,10.8709402 14.5849425,13.0222222 12.0127902,13.0222222 L6.35853993,13.0222222 L9.41406088,9.87863248 L7.06532121,7.46153846 L0,14.7299145 L7.06532121,22 L9.41406088,19.582906 L6.36186205,16.4410256 L12.0127902,16.4410256 C16.4170923,16.4410256 20,12.7538462 20,8.22307692 C20,3.68974359 16.4170923,0 12.0127902,0 Z"
        fill="#757575"
      ></path>
    </svg>
    <SepaReturnTyp>{children}</SepaReturnTyp>
  </SepaReturnWrapper>
);

export const PartiallyPaid = (props: IconProps) => (
  <BaseIcon {...props}>
    <path d="M15,6 L15,8.34782609 C11.3261058,8.34782609 8.34782609,11.3261058 8.34782609,15 C8.34782609,18.6738942 11.3261058,21.6521739 15,21.6521739 L15,21.6521739 L15,24 C10.0294373,24 6,19.9705627 6,15 C6,10.0294373 10.0294373,6 15,6 L15,6 Z"></path>
    <path
      d="M25,6 L25,8.34782609 C21.3261058,8.34782609 18.3478261,11.3261058 18.3478261,15 C18.3478261,18.6738942 21.3261058,21.6521739 25,21.6521739 L25,21.6521739 L25,24 C20.0294373,24 16,19.9705627 16,15 C16,10.0294373 20.0294373,6 25,6 L25,6 Z"
      fill-opacity="0.3"
      transform="translate(20.500000, 15.000000) rotate(-180.000000) translate(-20.500000, -15.000000) "
    ></path>
  </BaseIcon>
);
