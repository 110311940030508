import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { Button } from '../button';
import { Sparks } from '../icons/icons';
import { defaultTheme } from '../theme/theme';

import { PageClose } from '../close/close';
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from '../local-storage';

const sparksBlue = '#0052FF';

const FlexColumn = styled.div`
  flex-direction: column;
`;

const GradientBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 527px;
  height: 265px;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  background: linear-gradient(
    90deg,
    rgba(199, 180, 225, 1) 0%,
    rgba(226, 239, 239, 1) 100%
  );
`;

const TextBlock = styled.div`
  padding: 41px;
  width: 527px;
  min-height: 284px;
  background-color: ${defaultTheme.palette.background};
  border-radius: 0px 0px 10px 10px;
`;

const SparklyNew = styled.div`
  color: ${sparksBlue};
  font-size: 15px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MoreLink = styled.a`
  color: ${defaultTheme.palette.text};
  cursor: pointer;
  text-decoration: underline;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Paragraphs = styled.div`
  margin-bottom: 30px;
`;

const Paragraph = styled.p`
  margin: 5px 0;
`;

const Heading = styled.h2`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const PageCloseIcon = styled(PageClose)`
  margin: 15px;
`;

export interface FeatureAnnouncementProps {
  name: string;
  image: string;
  heading: string;
  paragraphs: string[];
  moreLink: string;
  expiresAt?: Date;
}

export function FeatureAnnouncementModal({
  name,
  image,
  heading,
  paragraphs,
  moreLink,
  expiresAt,
}: FeatureAnnouncementProps) {
  const expired = expiresAt && expiresAt < new Date();

  const [isOpen, setIsOpen] = React.useState(!expired);

  React.useEffect(() => {
    const status = getDataFromLocalStorage(name);
    if (status === 'true') {
      setIsOpen(false);
    }
  }, [name]);

  const onClose = React.useCallback(() => {
    setDataInLocalStorage(name, 'true');
    setIsOpen(false);
  }, [name]);

  return (
    <>
      <ReactModal
        className="modal"
        overlayClassName="modal-overlay"
        isOpen={isOpen}
        contentLabel="feature-announcement"
      >
        <FlexColumn>
          <GradientBackground>
            <CloseIconContainer>
              <PageCloseIcon onClick={onClose} className="page-close" />
            </CloseIconContainer>
            <ImageWrapper>
              <img alt="feature announcement" src={image}></img>
            </ImageWrapper>
          </GradientBackground>
          <TextBlock>
            <SparklyNew>
              <Sparks color={sparksBlue} size={30} />
              <div>Neu</div>
            </SparklyNew>
            <Heading>{heading}</Heading>
            <Paragraphs>
              {paragraphs.map((paragraph, i) => (
                <Paragraph key={i}>{paragraph}</Paragraph>
              ))}
            </Paragraphs>
            <BottomRow>
              <MoreLink target="_blank" href={moreLink}>
                Mehr erfahren
              </MoreLink>
              <Button onClick={() => onClose()} secondary>
                Los gehts
              </Button>
            </BottomRow>
          </TextBlock>
        </FlexColumn>
      </ReactModal>
    </>
  );
}
