import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ChevronDown } from '../icons/icons';
import { defaultTheme } from '../theme/theme';

export interface ExpandableProps {
  /**
   * (optional) Set the active index
   */
  expandedIndex?: number | null;
  className?: string;
  showChevron?: boolean;
}

export interface ExpandableChildrenProps {
  title: React.ReactNode;
  onSelect?: (index: number | null) => void;
}

const ExpandablesWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.border};
  background: ${(props) => props.theme.palette.background};
  border-radius: 4px;
`;

const ExpandableTitle = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.palette.text};
  font-weight: 400;
  border-top: 1px solid ${(props) => props.theme.palette.border};
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    border-top: none;
  }

  &:hover {
    color: ${(props) => props.theme.palette.text};
  }

  &.active {
    font-weight: 600;
  }
`;

const ExpandableContent = styled.div`
  padding: 0px 15px 15px 15px;
`;

export const Expandable: React.FunctionComponent<ExpandableChildrenProps> = ({
  children,
}) => {
  return <div>{children}</div>;
};

export const Expandables: React.FunctionComponent<ExpandableProps> = ({
  children,
  expandedIndex = null,
  showChevron,
  className,
}) => {
  const [activeIndex, setActiveIndex] = useState(expandedIndex);

  useEffect(() => {
    setActiveIndex(expandedIndex);
  }, [expandedIndex]);

  const childs: Array<React.ReactElement<ExpandableChildrenProps>> = [];

  React.Children.forEach(children, (child, index) => {
    const c = child as React.ReactElement<ExpandableChildrenProps>;

    if (!React.isValidElement(c)) {
      return;
    }

    childs.push(
      <ExpandableTitle
        key={c.key ?? index}
        onClick={() => {
          if (c.props.onSelect) {
            c.props.onSelect(index === activeIndex ? null : index);
          } else {
            setActiveIndex(index === activeIndex ? null : index);
          }
        }}
        className={`${index === activeIndex ? 'active' : ''}`}
      >
        {c.props.title}
        {showChevron && (
          <>
            <motion.div
              animate={{ rotate: index === activeIndex ? 180 : 0 }}
              style={{ originY: 0.5 }}
              initial={false}
              transition={{ duration: 0.2 }}
            >
              <ChevronDown
                size={30}
                color={defaultTheme.palette.borderEmphasis}
              />
            </motion.div>
          </>
        )}
      </ExpandableTitle>
    );

    if (index === activeIndex) {
      childs.push(
        <ExpandableContent key={`${c.key}-container`} className={'active'}>
          {c}
        </ExpandableContent>
      );
    }
  });

  return (
    <ExpandablesWrapper className={className}>{childs}</ExpandablesWrapper>
  );
};
