import styled from 'styled-components';

export const InlineTable = styled.table`
  --inline-table-border-color: ${(props) => props.theme.palette.border};

  width: 100%;

  border-collapse: collpse;
  border-spacing: 0;

  td,
  th {
    text-align: left;
    letter-spacing: 0.05em !important;
    border-bottom: 1px solid var(--inline-table-border-color);
    padding: 5px 7px;
  }

  th {
    background-color: var(--inline-table-border-color);
  }
`;
