import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button } from '../button/button';
import { Checkmark } from '../icons/icons';
import { FormikInput } from '../form/input';
import { FlexColumn, FormWrapper, TextWrapper } from '../layout/layout';
import { defaultTheme } from '../theme/theme';

const inputValidationSchema = yup.object().shape({
  currentPassword: yup.string().min(5).required(),
  password: yup.string().min(5).required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwörter stimmen nicht überein.'),
});

export interface UpdatePasswordProps {
  email?: string;
  setShowUpdatePassword: (value: boolean) => void;
  useUpdatePasswordMutation: () => [
    (args: any) => Promise<{ data?: any }>,
    { loading: boolean }
  ];
}

export const UpdatePassword: React.FC<UpdatePasswordProps> = ({
  email,
  setShowUpdatePassword,
  useUpdatePasswordMutation,
}) => {
  const { t } = useTranslation('authentication');
  const [updatePassword, { loading }] = useUpdatePasswordMutation();
  const [success, setSuccess] = React.useState(false);

  if (success) {
    return (
      <FlexColumn>
        <Checkmark size={64} color={defaultTheme.primaryColor} />
        <TextWrapper>{t('passwordUpdated')}</TextWrapper>
        <Button disabled={loading} onClick={() => setShowUpdatePassword(false)}>
          {t('ok')}
        </Button>
      </FlexColumn>
    );
  }

  return (
    <Formik
      initialValues={{ password: '', currentPassword: '' }}
      validationSchema={inputValidationSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        const { password, currentPassword } = values;

        setSubmitting(true);
        updatePassword({
          variables: {
            email,
            password,
            currentPassword,
            updatePasswordCallbackUrl: `${window.location.origin}/`,
          },
        })
          .then(({ data }) => {
            setSubmitting(false);
            switch (data?.updatePassword?.__typename) {
              case 'UpdatePasswordSuccess':
                setSuccess(true);
                break;
              case 'UpdatePasswordEmailFailed':
                setFieldError(
                  'currentPassword',
                  t('twoFactorEmailFailed', { email })
                );
                break;
              case 'WrongCredentialsError':
                setFieldError('currentPassword', t('wrongPassword'));
                break;
              case 'EmailNotFound':
                setFieldError('currentPassword', t('emailNotFound'));
                break;
              case 'UnknownError':
                setFieldError('currentPassword', data?.updatePassword.message);
                break;
              default:
                setFieldError('currentPassword', t('unknownError'));
                break;
            }
          })
          .catch((err) => {
            if (err.graphQLErrors && err.graphQLErrors[0]?.message)
              setFieldError('currentPassword', err.graphQLErrors[0]?.message);
            setSubmitting(false);
          });
      }}
    >
      {({ submitForm }) => (
        <FormWrapper>
          <FormikInput
            data-testid="current-password"
            type="password"
            name="currentPassword"
            id="currentPassword"
            label={t('currentPassword')}
            placeholder={t('currentPassword')}
            required
          />
          <FormikInput
            data-testid="update-password"
            type="password"
            name="password"
            id="password"
            label={t('newPassword')}
            placeholder={t('newPassword')}
            required
          />
          <FormikInput
            data-testid="update-password-confirm"
            type="password"
            name="passwordConfirmation"
            id="passwordConfirmation"
            label={t('newPasswordConfirm')}
            placeholder={t('newPasswordConfirm')}
            required
          />
          <Button
            expand
            bold={false}
            disabled={loading}
            onClick={() => {
              submitForm();
            }}
          >
            {t('save')}
          </Button>
        </FormWrapper>
      )}
    </Formik>
  );
};
