import React from 'react';
import styled from 'styled-components';

const KeyValueContainer = styled.div`
  display: block;
  padding-bottom: 30px;

  .key {
    padding-bottom: 5px;
    font-weight: bold;
  }
`;

export const KeyValue: React.FC = ({ children }) => {
  return <KeyValueContainer>{children}</KeyValueContainer>;
};
