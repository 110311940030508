import React from 'react';
import { Theme, defaultTheme } from '../theme/theme';
import { Bold } from '../typography/typography';
import { ConfirmationDialog } from './confirmation-dialog';

const ButtonTheme: Theme = {
  ...defaultTheme,
  primaryColor: defaultTheme.palette.error.color,
  secondaryColor: defaultTheme.palette.error.background,
};

export interface DestructiveActionProps {
  item: string;
  action: string;
  onAbort: () => void;
  onConfirm: () => void;
  isOpen: boolean;

  /**
   * set a custom title
   */
  title?: string;
}

export const DestructiveActionConfirmation: React.FC<
  DestructiveActionProps
> = ({ item, action, onAbort, onConfirm, isOpen, children, title }) => {
  return (
    <ConfirmationDialog
      confirmLabel={action}
      isOpen={isOpen}
      id={`${item}-confirm-${action}`}
      title={title || `${item} ${action}?`}
      onConfirm={onConfirm}
      onAbort={onAbort}
      buttonTheme={ButtonTheme}
    >
      {children ?? (
        <>
          Bist du sicher, dass du <Bold>{item}</Bold> {action} möchtest? <br />
          Dies kann nicht rückgängig gemacht werden.
        </>
      )}
    </ConfirmationDialog>
  );
};
