import React from 'react';
import styled from 'styled-components';

const RadioGroupContainer = styled.div<{
  $direction: 'row' | 'column';
  $dense?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => props.$direction};
  padding: 6px 0;
  > div:not(:last-child) {
    margin: ${(props) => {
      if (props.$direction === 'row') {
        const margin = props.$dense ? '1rem' : '2rem';
        return `0 ${margin} 0 0`;
      } else {
        const margin = props.$dense ? '0rem' : '0.5rem';
        return `0 0 ${margin} 0`;
      }
    }};
  }
`;

const RadioContext = React.createContext<{
  id?: string;
  value?: any;
  onChange?: (value: any) => void;
}>({
  id: '',
  value: undefined,
  onChange: (_: any) => {},
});

type RadioGroupProps = {
  direction?: 'row' | 'column';
  dense?: boolean;
  id?: string;
  name?: string;
  value?: any;
  onChange?: (value: any) => void;
};

export function RadioGroup({
  children,
  direction = 'column',
  dense,
  id,
  name,
  value,
  onChange,
}: React.PropsWithChildren<RadioGroupProps>) {
  return (
    <RadioContext.Provider
      value={{
        id,
        value,
        onChange,
      }}
    >
      <RadioGroupContainer
        $direction={
          ['row', 'column'].includes(direction) ? direction : 'column'
        }
        $dense={dense}
      >
        {React.Children.map(
          children,
          (child) =>
            child &&
            React.isValidElement(child) &&
            React.cloneElement(child as React.ReactElement<RadioButtonProps>, {
              dense,
              name,
            })
        )}
      </RadioGroupContainer>
    </RadioContext.Provider>
  );
}

const RadioButtonConainer = styled.div<{
  $dense?: boolean;
  $disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  > label {
    opacity: ${({ $disabled }) => ($disabled ? 0.6 : 1)};
    margin-left: ${({ $dense }) => ($dense ? '0.2rem' : '0.6rem')};
  }
`;

type RadioButtonProps = {
  checked?: boolean;
  disabled?: boolean;
  dense?: boolean;
  label: string;
  id?: string;
  name?: string;
  value?: any;
  onChange?: (value: any) => void;
};

export function RadioButton({
  checked,
  disabled = false,
  dense,
  label,
  id,
  name,
  value,
  onChange,
}: RadioButtonProps) {
  const {
    value: selectedValue,
    onChange: contextOnChange,
    id: contextId,
  } = React.useContext(RadioContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    if (contextOnChange) contextOnChange(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  let inputId = id;
  if (!inputId && contextId) {
    inputId = `${contextId}-${value}`;
  } else {
    inputId = `radio-${name}-${label}-${value}`;
  }

  return (
    <RadioButtonConainer $disabled={disabled} $dense={dense}>
      <input
        type="radio"
        name={name}
        disabled={disabled}
        id={inputId}
        data-testid={inputId}
        checked={checked ?? selectedValue === value}
        value={value}
        onChange={handleChange}
        readOnly
      />
      <label htmlFor={inputId}>{label}</label>
    </RadioButtonConainer>
  );
}
