import * as React from 'react';
import styled from 'styled-components';

const TabPanelContainer = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  padding: 4px 12px;
`;

interface TabPanelProps {
  children: React.ReactNode;
  value: any;
  index: any;
  lazy?: boolean;
}

export function TabPanel({ children, lazy, value, index }: TabPanelProps) {
  const selected = value === index;

  return (
    <TabPanelContainer
      role="tabpanel"
      aria-hidden={!selected}
      hidden={!selected}
    >
      {lazy && !selected ? null : children}
    </TabPanelContainer>
  );
}
