import React from 'react';
import { ButtonGroup } from '../button/button-group';
import { Button } from '../button/button';
import { BooleanFilter, Filter } from './types';
import styled, { useTheme } from 'styled-components';
import { Filter as FilterIcon } from '../icons/icons';

const Wrapper = styled(ButtonGroup)`
  margin-bottom: 10px;
  align-items: center;

  > svg {
    margin-right: 7px;
  }
`;

export const BooleanFilterBar = ({
  filters,
  state,
  setFilter,
  setAllFilters,
}: {
  filters: BooleanFilter[];
  state: Filter[];
  setFilter: (id: string, value: string) => void;
  setAllFilters: (filters: Filter[]) => void;
}) => {
  const theme = useTheme();

  const hasActiveFilter = state.some((s) =>
    filters.find((filter) => filter.id === s.id)
  );

  const handleResetBooleanFilters = () => {
    const otherFilters = state.filter(
      (s) => !filters.find((filter) => filter.id === s.id)
    );

    setAllFilters(otherFilters);
  };

  return (
    <Wrapper gap={'5px'}>
      <FilterIcon size={20} color={theme.palette.textMuted} />
      <Button
        key="all"
        small
        secondary
        active={!hasActiveFilter}
        onClick={handleResetBooleanFilters}
      >
        Alle
      </Button>
      {filters.map(({ label, id, value }) => {
        const isActive =
          state.find((f) => id === f.id && value === f.value) !== undefined;
        return (
          <Button
            key={`${id}-${value}`}
            small
            secondary
            onClick={() => setFilter(id, value)}
            active={isActive}
          >
            {typeof label === 'string' ? label : label(isActive)}
          </Button>
        );
      })}
    </Wrapper>
  );
};
