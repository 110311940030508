import React, { useState, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { FlexRow } from '../layout/layout';
import { LabelStyle } from '../typography/typography';
import { formatDate } from '../formatters';
import ContentLoader from 'react-content-loader';
import { Modal } from '../modal/modal';
import { HistoricTabs, HistoricItem } from '../historic-tabs/historic-tabs';

export interface HistoricEntryProps {
  items?: HistoricItem[];
  isLoading: boolean;
  renderValue: (item: HistoricItem) => React.ReactNode;
  renderActions?: () => React.ReactNode;
  title: string;
}

const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.background};
`;

const Title = styled.div`
  ${LabelStyle}
`;

const TitleRow = styled(FlexRow)`
  align-items: baseline;
`;

const Value = styled(FlexRow)`
  padding: 3px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;

  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const RenderLoadingState = () => {
  const theme = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={180}
      height={20}
      viewBox="0 0 180 20"
      backgroundColor={theme.palette.backgroundMuted}
      foregroundColor={theme.palette.border}
    >
      <rect x="0" y="8" rx="3" ry="3" width="150" height="6" />
    </ContentLoader>
  );
};

const renderItem = (
  item: HistoricItem,
  renderValue: (value: HistoricItem) => React.ReactNode
) => {
  return `${renderValue(item)} seit ${formatDate(item.date)}`;
};

export const HistoricEntry = ({
  items,
  isLoading,
  renderValue,
  title,
  renderActions,
}: HistoricEntryProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const sortedEntries = useMemo(
    () =>
      (items ?? []).sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      ),
    [items]
  );

  return (
    <Wrapper>
      <TitleRow>
        <Title>{title}</Title>
      </TitleRow>
      <Value onClick={() => setModalVisible(true)}>
        {isLoading
          ? RenderLoadingState()
          : renderItem(sortedEntries[0], renderValue)}
      </Value>
      <Modal
        isOpen={modalVisible}
        title={null}
        contentLabel={title}
        onRequestClose={() => setModalVisible(false)}
      >
        <HistoricTabs
          items={sortedEntries}
          isLoading={false}
          renderValue={(item) => renderValue(item)}
          renderActions={renderActions}
          title={title}
        />
      </Modal>
    </Wrapper>
  );
};
