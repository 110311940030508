import { Filter, Sort } from './types';

export function parseQuery(queryString: string) {
  const query: { [key: string]: any } = {};
  (queryString[0] === '?' ? queryString.substr(1) : queryString)
    .split('&')
    .filter((s) => s.length !== 0)
    .forEach((pair) => {
      const [key, value] = pair.split('=');
      query[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });

  return query;
}

export function stringifyPath(path: string, query: { [key: string]: string }) {
  const parts: string[] = [];

  for (const [key, value] of Object.entries(query)) {
    parts.push(`${key}=${value}`);
  }

  return `${path}${parts.length !== 0 ? `?${parts.join('&')}` : ''}`;
}

export function arrayToObject(
  array: Array<any>,
  accessor: string,
  value?: string
) {
  return array.reduce(
    (obj: { [x: string]: string }, item: any) => ({
      ...obj,
      [item[accessor]]: value ? item[value] : item,
    }),
    {}
  );
}

export function parseFilter(filter: string): Filter[] {
  return filter
    .split(',')
    .map((part: string) => part.split(':'))
    .map((parts: string[]) => ({ id: parts[0], value: parts[1] }));
}

export function stringifySort(sortBy: Sort[]): string {
  return sortBy
    .map(({ id, desc }: Sort) => `${id}:${desc ? 'desc' : 'asc'}`)
    .join(',');
}

export function parseSort(sort: string): Sort[] {
  return sort
    .split(',')
    .map((part: string) => part.split(':'))
    .map((parts: string[]) => ({ id: parts[0], desc: parts[1] === 'desc' }));
}
