import styled, { useTheme, css } from 'styled-components';
import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '../button/button';
import { File, ChevronDown, Download, Open, Delete } from '../icons/icons';
import { DateTime } from 'luxon';
import { AnimatePresence, motion } from 'framer-motion';
import { FillOnHover } from '../icons/base-icon';
import { Tooltip, TooltipDark } from '../tooltip/tooltip';
import { Bold } from '../typography/typography';
import { DialogProvider, useDialog } from '../dialog/useDialog';
import { defaultTheme } from '../theme/theme';

const Aside = styled.div`
  margin-left: 15px;
  flex: 1;
`;

const Small = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.textMuted};
  line-height: 19px;
`;

const ItemStyle = css`
  background: ${(props) => props.theme.palette.background};
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.borderMuted};
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
  user-select: none;
`;

const Item = styled.div`
  position: relative;
  ${ItemStyle}
`;

const Actions = styled.div<{ $hover?: boolean }>`
  box-shadow: 0px 1px 1px 1px RGBA(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.background};
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  display: ${(props) => (props.$hover ? 'block' : 'none')};

  > span {
    padding: 0 2px;
  }
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

const Wrapper = styled.div`
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ButtonWrap = styled.div`
  display: inline-block;
`;

const FileIcon = () => {
  const theme = useTheme();
  return (
    <Wrapper>
      <File size={22} color={theme.primaryColor} />
    </Wrapper>
  );
};

export interface Document {
  hash?: string | null;
  createdAt?: string;
  fileURL: string;
  contentType: string;
  fileName?: string;
  showFileName?: boolean | null;
  id?: string;
  type?: string;
  userUpload?: boolean | null;
  deletable?: boolean;
}

export interface DocumentType {
  type: string;
  documents?: Document[];
  creation?: {
    disabled: boolean;
    hint: string;
  };
  previewEnabled?: boolean;
  groupAlways?: boolean;
}

export interface DocumentsProps {
  types: DocumentType[];
  onCreate(type: string): void;
  onOpen(fileURL: string, type: string): void;
  onDelete?(document: Document): void;
}

export const Documents: React.FC<DocumentsProps> = ({
  types,
  onCreate,
  onOpen,
  onDelete,
}) => {
  return (
    <DialogProvider>
      {types.map((d) => {
        if (!d.documents || !Array.isArray(d.documents)) {
          return (
            <DocumentToBeCreated
              type={d.type}
              onCreate={onCreate}
              creation={d.creation}
              key={d.type}
            />
          );
        }

        const isSingleDocument = d.documents.length === 1 && !d.groupAlways;
        if (isSingleDocument) {
          return (
            <SingleDocument
              type={d.documents[0].type ?? d.type}
              document={d.documents[0]}
              showFileName={
                !!d.documents[0].userUpload || !!d.documents[0].showFileName
              }
              onOpen={onOpen}
              onDelete={onDelete}
              key={d.type}
              previewEnabled={d.previewEnabled}
            />
          );
        }

        return (
          <GroupedDocuments
            type={d.type}
            documents={d.documents}
            onOpen={onOpen}
            onDelete={onDelete}
            key={d.type}
            previewEnabled={d.previewEnabled}
          />
        );
      })}
    </DialogProvider>
  );
};

const shortenFileName = (
  fileName: string,
  maxLength: number,
  showLastPart = false
) => {
  const split = fileName.split('.');
  if (split.length === 1) return fileName;

  const extension = split.slice(-1)[0];
  const name = split.slice(0, -1).join('.');
  if (name.length > maxLength) {
    if (showLastPart && maxLength > 10) {
      return `${name.substring(0, maxLength - 5)}...${name.slice(
        -5
      )}.${extension}`;
    } else {
      return `${name.substring(0, maxLength)}...${extension}`;
    }
  }
  return `${name}${extension ? `.${extension}` : ''}`;
};

export const SingleDocument = ({
  document,
  type,
  withIcon = true,
  onOpen,
  showFileName = false,
  onDelete,
  previewEnabled = true,
}: {
  document: Document;
  type: string;
  withIcon?: boolean;
  onOpen: DocumentsProps['onOpen'];
  onDelete?: DocumentsProps['onDelete'];
  showFileName?: boolean;
  previewEnabled?: boolean;
}) => {
  const { openConfirmation } = useDialog();

  const [hover, setHover] = useState(false);

  const onRequestDelete = useCallback(
    (document: Document) => {
      openConfirmation({
        title: 'Dokument löschen?',
        content: (
          <>
            Bist du sicher, dass du das Dokument{' '}
            <Bold>{document?.fileName}</Bold> löschen möchtest?
          </>
        ),
        confirmButtonLabel: 'Löschen',
        dangerButton: true,
        handleRequest: () => onDelete?.(document),
      });
    },
    [onDelete, openConfirmation]
  );

  const showFileNameOnHover = hover && showFileName && document.fileName;
  const shortendName = document.fileName
    ? shortenFileName(document.fileName, withIcon ? 20 : 30, true)
    : '';

  return (
    <Item
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {withIcon && <FileIcon />}
      <Aside>
        <Label>{type}</Label>
        {showFileNameOnHover && (
          <>
            {shortendName !== document.fileName ? (
              <Tooltip
                variant="dark"
                id={`${document.fileName}-show-filename`}
                text={document.fileName ?? ''}
                place="bottom"
              >
                <Small data-tip data-for={`${document.fileName}-show-filename`}>
                  {shortendName}
                </Small>
              </Tooltip>
            ) : (
              <Small>{document.fileName}</Small>
            )}
          </>
        )}
        {!showFileNameOnHover && document.createdAt && (
          <Small>
            {document.userUpload ? 'Hochgeladen am ' : 'Erstellt am '}
            <FormattedDate
              date={document.createdAt}
              format={DateTime.DATE_MED}
            />
          </Small>
        )}
      </Aside>
      <Actions $hover={hover}>
        {previewEnabled && (
          <TooltipDark
            id={`${document.fileName}-show-tooltip`}
            text={'Im Browser anzeigen'}
            place="top"
          >
            <FillOnHover
              onClick={() => onOpen(document.fileURL, type)}
              data-tip
              data-for={`${document.fileName}-show-tooltip`}
            >
              <Open size={25} color={defaultTheme.palette.textMuted} />
            </FillOnHover>
          </TooltipDark>
        )}
        <TooltipDark
          id={`${document.fileName}-download-tooltip`}
          text={'Herunterladen'}
          place="top"
        >
          <a
            href={document.fileURL}
            download
            data-tip
            data-for={`${document.fileName}-download-tooltip`}
          >
            <FillOnHover>
              <Download size={25} color={defaultTheme.palette.textMuted} />
            </FillOnHover>
          </a>
        </TooltipDark>
        {onDelete && document.deletable && (
          <TooltipDark
            id={`${document.fileName}-delete-tooltip`}
            text={'Löschen'}
            place="top"
          >
            <FillOnHover
              onClick={() => onRequestDelete(document)}
              fillColor={defaultTheme.palette.error.color}
              data-tip
              data-for={`${document.fileName}-delete-tooltip`}
            >
              <Delete size={25} color={defaultTheme.palette.textMuted} />
            </FillOnHover>
          </TooltipDark>
        )}
      </Actions>
    </Item>
  );
};

const DocumentToBeCreated = ({
  onCreate,
  type,
  creation,
}: {
  onCreate: (type: string) => void;
  type: string;
  creation?: {
    disabled: boolean;
    hint: string;
  };
}) => {
  return (
    <Item>
      <FileIcon />
      <Aside>
        <Label>{type}</Label>
        <Small>Noch nicht erstellt</Small>
      </Aside>
      {creation && creation.disabled && (
        <TooltipDark id={`${type}-tooltip`} text={creation.hint} place="bottom">
          {/** Extra wrap here because tooltip doesn't appear when the targeted button
           * is `disabled`: https://github.com/wwayne/react-tooltip/issues/304*/}
          <ButtonWrap data-tip data-for={`${type}-tooltip`}>
            <Button secondary disabled>
              Erstellen
            </Button>
          </ButtonWrap>
        </TooltipDark>
      )}
      {!(creation && creation.disabled) && (
        <Button secondary onClick={() => onCreate(type)}>
          Erstellen
        </Button>
      )}
    </Item>
  );
};

const IconWrapper = styled(motion.div)`
  padding: 3px 10px;
`;

const Grey = styled.span`
  color: ${(props) => props.theme.palette.textMuted};
`;

const AnimatedItem = styled(motion.div)`
  ${ItemStyle}
  cursor: pointer;

  :hover .chevron {
    background: ${defaultTheme.palette.backgroundMuted};
    border-radius: 50px;
    fill: ${(props) => props.theme.primaryColor};
  }
`;

const GroupedDocuments = ({
  documents,
  type,
  onOpen,
  onDelete,
  previewEnabled,
}: {
  type: string;
  documents: Document[];
  onOpen: DocumentsProps['onOpen'];
  onDelete: DocumentsProps['onDelete'];
  previewEnabled?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  const sorted = useMemo(
    () =>
      documents.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        } else if (a.createdAt) {
          return -1;
        } else {
          return 1;
        }
      }),
    [documents]
  );

  return (
    <>
      <AnimatedItem onClick={() => setExpanded(!expanded)} initial={false}>
        <FileIcon />
        <Aside>
          <Label>
            {type} <Grey>({documents.length})</Grey>
          </Label>
          <Small>
            {sorted[0].createdAt && (
              <>
                letzte am{' '}
                <FormattedDate
                  date={sorted[0].createdAt!}
                  format={DateTime.DATE_MED}
                />
              </>
            )}
          </Small>
        </Aside>
        <IconWrapper
          animate={{ rotate: expanded ? 180 : 0 }}
          style={{ originY: 0.5 }}
          initial={false}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown
            size={25}
            className="chevron"
            color={defaultTheme.palette.textMuted}
          />
        </IconWrapper>
      </AnimatedItem>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {documents.map((d) => (
              <SingleDocument
                document={d}
                type={d.type ?? type}
                withIcon={false}
                onOpen={onOpen}
                showFileName={!!d.userUpload || !!d.showFileName}
                onDelete={onDelete}
                key={d.id ?? d.fileURL}
                previewEnabled={previewEnabled}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const FormattedDate = ({
  format,
  date,
}: {
  date: string;
  format: any /*LocaleOptions & DateTimeFormatOptions;*/;
}) => {
  return (
    <time dateTime={date}>{DateTime.fromISO(date).toLocaleString(format)}</time>
  );
};
