import React from 'react';
import { TooltipProps } from 'react-tooltip';
import { defaultTheme } from '../theme/theme';

import { ReactTooltipStyled } from './style';

export interface CustomTooltipProps extends TooltipProps {
  children: React.ReactNode;
  visible?: boolean;
  text: string;
  id: string;
  variant?: 'dark' | 'light';
  ref?: React.RefObject<any>;
  maxWidth?: string;
}

export const Tooltip = ({
  children,
  id,
  text,
  effect = 'solid',
  place = 'top',
  visible = true,
  border = true,
  variant = 'dark',
  maxWidth,
  ...rest
}: CustomTooltipProps) => {
  const backgroundColor =
    variant === 'light'
      ? defaultTheme.palette.background
      : defaultTheme.palette.text;
  const textColor =
    variant === 'light'
      ? defaultTheme.palette.textMuted
      : defaultTheme.palette.background;
  const borderColor =
    variant === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(0, 0, 0, 0.08)';

  return (
    <>
      {children}
      {visible && (
        <ReactTooltipStyled
          id={id}
          place={place}
          effect={effect}
          textColor={textColor}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          border={border}
          maxWidth={maxWidth}
          {...rest}
        >
          {text}
        </ReactTooltipStyled>
      )}
    </>
  );
};

export const TooltipLight = (props: CustomTooltipProps) => {
  return <Tooltip {...props} variant="light" />;
};

export const TooltipDark = (props: CustomTooltipProps) => {
  return <Tooltip {...props} variant="dark" />;
};
