import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormikInput, FormikSubmit } from '../form/input';
import { AlertRetryable } from '../alert/alert-retryable';
import { Button } from '../button/button';
import { SegmentTitle } from '../typography/typography';
import {
  Emoji,
  LoginActions,
  LoginContainer,
  SuccessTextWrapper,
} from '../login/login.style';

export interface ResetPasswordRequestProps {
  setLoginState: (loginState: 'login' | 'passwordReset') => void;
  sendPasswordResetEmail: ({
    email,
  }: {
    email: string;
  }) => Promise<{ data?: any }>;
  loading: boolean;
}

export const ResetPasswordRequest: React.FC<ResetPasswordRequestProps> = ({
  setLoginState,
  sendPasswordResetEmail,
  loading,
}) => {
  const [emailSent, setEmailSent] = useState<null | string>(null);
  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslation('authentication');

  if (error) {
    return <AlertRetryable message={error} />;
  }

  if (emailSent) {
    return (
      <>
        <SuccessTextWrapper>
          <Emoji role="img" aria-label="mail">
            📨
          </Emoji>
          {t('passwordResetRequestSuccess')}
        </SuccessTextWrapper>
        <Button expand onClick={() => setLoginState('login')}>
          {t('backToLogin')}
        </Button>
      </>
    );
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={yup.object().shape({
        email: yup.string().email().required(),
      })}
      onSubmit={({ email }, { setSubmitting }) => {
        setSubmitting(true);
        sendPasswordResetEmail({
          email,
        })
          .then(({ data }) => {
            setSubmitting(false);
            if (data) {
              switch (data?.sendPasswordResetEmail?.__typename) {
                case 'SendPasswordResetEmailSuccess':
                  setEmailSent(data?.sendPasswordResetEmail.email);
                  break;
                case 'UnknownError':
                case 'WrongPasswordResetFlow':
                  setError(data?.sendPasswordResetEmail.message);
                  break;
                default:
                  setError('Unknown error');
                  break;
              }
            }
          })
          .catch((err) => {
            setSubmitting(false);
            setError(err.message);
          });

        return;
      }}
    >
      {() => (
        <LoginContainer>
          <SegmentTitle>{t('passwordResetRequestTitle')}</SegmentTitle>
          {t('passwordResetRequestMessage')}
          <FormikInput
            data-testid="login-user-email"
            type="email"
            name="email"
            id="email"
            label={t('email')}
            placeholder={t('email')}
            autoComplete="username"
            required
          />
          <LoginActions>
            <Button
              expand
              secondary
              disabled={loading}
              onClick={() => setLoginState('login')}
            >
              {t('cancel')}
            </Button>
            <FormikSubmit expand disabled={loading}>
              {t('passwordResetRequestAction')}
            </FormikSubmit>
          </LoginActions>
        </LoginContainer>
      )}
    </Formik>
  );
};
