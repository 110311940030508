import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Checkmark, Progress, Warning, Wrong } from '../icons/icons';
import { FlexRow } from '../layout/layout';
import ContentLoader from 'react-content-loader';
import { defaultTheme } from '../theme/theme';

const Wrapper = styled(FlexRow)<{ isSelected: Boolean; isDisabled?: Boolean }>`
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3px 13px 3px 0px;
  border-radius: 4px;
  background: ${(props) =>
    props.isSelected ? props.theme.palette.backgroundMuted : 'inherit'};

  cursor: ${(props) => (props.isDisabled ? 'inherit' : 'pointer')};

  :hover {
    background: ${(props) =>
      props.isDisabled ? 'inherit' : props.theme.palette.backgroundMuted};
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
`;

const Icon = styled.div<{ isActive: boolean }>`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.palette.background};
  border: 2px solid
    ${(props) =>
      props.isActive
        ? props.theme.primaryColor
        : props.theme.palette.borderEmphasis};
  text-align: center;
  font-size: 13px;
  border-radius: 50px;
  margin: 6px;
`;

const Label = styled.div<{ isSelected: boolean }>`
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
`;
export interface WorkflowStepProps {
  state:
    | 'success'
    | 'active'
    | 'inactive'
    | 'failed'
    | 'needs-attention'
    | 'loading';
  label: string;
  onClick: () => void;
  isSelected: boolean;
}

export const WorkflowStep = ({
  state,
  label,
  onClick,
  isSelected,
}: WorkflowStepProps) => {
  const theme = useTheme();

  return (
    <Wrapper
      onClick={() => state !== 'inactive' && onClick()}
      isSelected={isSelected}
      isDisabled={state === 'inactive'}
    >
      {state === 'loading' && (
        <ContentLoader
          speed={2}
          width={170}
          height={20}
          viewBox="0 0 170 20"
          backgroundColor={theme.palette.backgroundMuted}
          foregroundColor={theme.palette.border}
        >
          <circle cx="16" cy="10" r="10" />
          <rect x="32" y="3" rx="4" ry="4" width="123" height="16" />
        </ContentLoader>
      )}
      {state === 'success' && (
        <Checkmark size={32} color={theme.primaryColor} />
      )}
      {state === 'inactive' && <Icon isActive={false} />}
      {state === 'active' && (
        <Progress size={32} color={defaultTheme.palette.textMuted} />
      )}
      {state === 'needs-attention' && (
        <Warning size={32} color={defaultTheme.palette.warning.color} />
      )}
      {state === 'failed' && (
        <Wrong size={32} color={defaultTheme.palette.error.color} />
      )}
      {state !== 'loading' && <Label isSelected={isSelected}>{label}</Label>}
    </Wrapper>
  );
};
