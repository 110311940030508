import { Checkmark } from '../icons/icons';
import React from 'react';
import styled from 'styled-components';
import { FlexRow } from '../layout/layout';
import {
  OpenInvoice,
  CancelledInvoice,
  SepaReturn,
  PartiallyPaid,
} from './icons';
import { TooltipDark } from '../tooltip/tooltip';
import { defaultTheme } from '../theme/theme';

const Wrapper = styled(FlexRow)`
  align-items: center;
  width: 26px;
`;

interface InvoiceStateProps {
  state: 'draft' | 'open' | 'paid' | 'partially-paid' | 'canceled';
  isSepaReturn?: boolean;
  id: string;
  color?: string;
}

const colors = {
  success: defaultTheme.palette.textMuted,
  open: defaultTheme.palette.success.color,
  cancelled: defaultTheme.palette.textMuted,
};

export const InvoiceState: React.FC<InvoiceStateProps> = ({
  state,
  isSepaReturn,
  id,
  color,
}) => {
  const inner = () => {
    const tooltipTargetProps = {
      'data-tip': true,
      'data-for': `${id}-invoice-state-tooltip`,
    };

    const tooltipProps = {
      id: `${id}-invoice-state-tooltip`,
      place: 'left' as React.ComponentProps<typeof TooltipDark>['place'],
    };

    switch (state) {
      case 'paid':
        return (
          <>
            {isSepaReturn && (
              <TooltipDark {...tooltipProps} text={'SEPA-Rückläufer Bezahlt'}>
                <SepaReturn {...tooltipTargetProps}>
                  <Checkmark size={22} color={color ?? colors.success} />
                </SepaReturn>
              </TooltipDark>
            )}
            {!isSepaReturn && (
              <TooltipDark {...tooltipProps} text={'Bezahlt'}>
                <Checkmark
                  size={26}
                  color={color ?? colors.success}
                  {...tooltipTargetProps}
                />
              </TooltipDark>
            )}
          </>
        );

      case 'open':
        return (
          <TooltipDark {...tooltipProps} text={'Offen'}>
            <OpenInvoice
              size={26}
              color={color ?? colors.open}
              {...tooltipTargetProps}
            />
          </TooltipDark>
        );

      case 'partially-paid':
        return (
          <TooltipDark {...tooltipProps} text={'Teilzahlung'}>
            <PartiallyPaid
              size={26}
              color={color ?? colors.open}
              {...tooltipTargetProps}
            />
          </TooltipDark>
        );

      case 'canceled':
        return (
          <>
            {isSepaReturn && (
              <TooltipDark {...tooltipProps} text={'SEPA-Rückläufer Storniert'}>
                <SepaReturn {...tooltipTargetProps}>
                  <CancelledInvoice
                    size={22}
                    color={color ?? colors.cancelled}
                  />
                </SepaReturn>
              </TooltipDark>
            )}
            {!isSepaReturn && (
              <TooltipDark {...tooltipProps} text={'Storniert'}>
                <CancelledInvoice
                  size={26}
                  color={color ?? colors.cancelled}
                  {...tooltipTargetProps}
                />
              </TooltipDark>
            )}
          </>
        );

      default:
        return null;
    }
  };

  return <Wrapper>{inner()}</Wrapper>;
};
