import React from 'react';
import styled from 'styled-components';

import Logo from './logo';

const Box = styled.div<{ flex?: number | string }>`
  flex: ${(props) => props.flex} 1 0%;
`;

const NavigationWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.background};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type NavigationProps = {
  centerComponent: React.ReactNode;
  endComponent?: React.ReactNode;
};

export const Navigation: React.FC<NavigationProps> = ({
  centerComponent,
  endComponent,
}) => {
  return (
    <NavigationWrapper>
      <Logo />
      <Box flex="0.5" />
      {centerComponent}
      <Box flex="2" />
      {endComponent}
    </NavigationWrapper>
  );
};
