import styled, { css } from 'styled-components';

export const BaseFont = css`
  font-size: 15px;
  font-weight: 600;
`;

export const LabelStyle = css`
  ${BaseFont}
  display: block;
  margin-top: 10px;
`;

export const Label = styled.div`
  ${LabelStyle}
`;

export const PageTitle = styled.h2`
  font-size: 20px;
  color: ${(props) => props.theme.palette.text};
`;

export const SubTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => props.theme.palette.text};
`;

export const SegmentTitle = styled.h4``;

export const Bold = styled.span`
  font-weight: bold;
`;

export interface HeadlineProps {
  title?: boolean;
  subtitle?: boolean;
  headline?: boolean;
  header?: boolean;
  subheader?: boolean;
  underlined?: boolean;
}

export const Headline: React.FC<HeadlineProps> = styled.h6.attrs<HeadlineProps>(
  (props) => ({
    className: `
    ${props.title ? 'title' : ''}
    ${props.subtitle ? 'subtitle' : ''}
    ${props.headline ? 'headline' : ''}
    ${props.header ? 'header' : ''}
    ${props.subheader ? 'subheader' : ''}
    ${props.underlined ? 'underlined' : ''}
  `,
  })
)<HeadlineProps>`
  color: ${(props) => props.theme.palette.text};
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  margin: 0;
  padding: 0;

  &.title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &.subtitle {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.827;
    text-transform: uppercase;
  }

  &.headline {
    color: ${(props) => props.theme.palette.text};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.29;
    text-transform: uppercase;
  }

  &.header {
    font-size: 26px;
  }

  &.subheader {
    font-size: 12px;
  }

  &.underlined {
    padding-left: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${(props) => props.theme.palette.border};
    margin-bottom: 20px;
  }
`;
