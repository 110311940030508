import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ButtonGroup } from '../button';

import { formatHour, formatDateShort } from '../formatters';

import { IconProps } from '../icons/base-icon';
import { Section } from '../layout/layout';
import { Pagination } from '../table/pagination';

const ListTitle = styled.h2`
  color: ${(props) => props.theme.palette.text};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;

  padding-left: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.borderMuted};
`;

const ListContent = styled.ul`
  margin: 0;
  padding: 0;
`;

const ListItemContainer = styled.li<{ role?: React.AriaRole & string }>`
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: min-content auto;
  cursor: ${({ role }) => (role === 'button' ? 'pointer' : 'default')};

  padding-bottom: 16px;
  padding-top: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.palette.border};
  }
`;

const ListIcon = styled.span`
  grid-row: 1 / span 2;
  align-items: center;
  margin-left: 15px;
  margin-right: 18px;
`;

const ListItemTitle = styled.h3`
  grid-row: 1 / 1;
  grid-column: 2 / 2;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: ${(props) => props.theme.palette.text};
  line-height: 1;
`;

const ListItemContent = styled.span`
  grid-row: 2 / 2;
  grid-column: 2 / 2;

  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.text};
`;

const ListItemDate = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.palette.textMuted};
  grid-row: 1 / 1;
  grid-column: 3 / 3;
`;

const ListItemTime = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.palette.textMuted};
  grid-row: 2 / 2;
  grid-column: 3 / 3;
`;

const Buttons = styled(ButtonGroup)`
  grid-row: 3 / 3;
  grid-column: 1 / 4;
  justify-content: flex-end;
  margin-top: 16px;
`;

export interface ListProps {
  header?: string;
  withPagination?: boolean;
  pageSize?: number;
  className?: string;
}

export interface ListItemProps {
  title?: string;
  Icon: React.ComponentType<any>;
  timestamp?: Date;
  iconProps?: IconProps;
  className?: string;
  buttons?: JSX.Element;
  onClick?: () => void;
}

export const List: React.FC<ListProps> = ({
  header,
  children,
  withPagination,
  pageSize = 1,
  className,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);

  const rowCount = React.Children.count(children);
  const pageCount = Math.ceil(rowCount / pageSize);
  const canPreviousPage = currentPage > 0;
  const canNextPage = currentPage < pageCount - 1;

  const filteredChildren: React.ReactElement[] = [];
  if (withPagination && children) {
    React.Children.forEach(
      children as ReactElement<any>,
      (child: React.ReactElement, index: number) => {
        if (
          React.isValidElement(child) &&
          index + 1 > currentPage * pageSize &&
          index + 1 <= (currentPage + 1) * pageSize
        ) {
          filteredChildren.push(child);
        }
      }
    );
  }

  return (
    <>
      <Section className={className}>
        {header ? <ListTitle>{header}</ListTitle> : null}
        <ListContent>
          {withPagination ? filteredChildren : children}
        </ListContent>
      </Section>
      {withPagination && (
        <Pagination
          nextPage={() => setCurrentPage(currentPage + 1)}
          previousPage={() => setCurrentPage(currentPage - 1)}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={currentPage}
          pageSize={pageSize}
          page={filteredChildren}
          rowCount={rowCount}
        />
      )}
    </>
  );
};

export const ListItem: React.FC<ListItemProps> = ({
  Icon,
  iconProps = {},
  title,
  children,
  timestamp,
  className,
  buttons,
  onClick,
}) => {
  return (
    <ListItemContainer
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? -1 : undefined}
      onKeyDown={onClick ? onClick : undefined}
      className={className}
    >
      <ListIcon>
        <Icon {...iconProps} />
      </ListIcon>
      {title ? <ListItemTitle>{title}</ListItemTitle> : null}
      <ListItemContent>{children}</ListItemContent>
      {timestamp && (
        <>
          <ListItemDate>{formatDateShort(timestamp)}</ListItemDate>
          <ListItemTime>{formatHour(timestamp)}</ListItemTime>
        </>
      )}
      <Buttons>{buttons}</Buttons>
    </ListItemContainer>
  );
};
