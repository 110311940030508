import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import styled, { useTheme, css } from 'styled-components';

import { Button } from '../button/button';
import { Pencil, Plus, Warning } from '../icons/icons';
import { BaseIcon } from '../icons/base-icon';
import { FlexRow } from '../layout/layout';
import { useHover } from './useHover';
import { defaultTheme } from '../theme/theme';
import { DynamicListContextType, DynamicListItemProps } from './types';

export const DynamicListContext = createContext<DynamicListContextType>({
  addItem: () => {},
  removeItem: () => {},
  validateItem: () => true,
  onNewItem: (item) => item,
  EditComponent: () => null,
  ListItemComponent: () => null,
  type: '',
  Icon: undefined,
  hoverColor: undefined,
});

const Wrapper = styled.li<{
  $hoverColor?: string;
  $iconColor?: string;
  $hideIcon?: boolean;
  $color?: string;
  $editMode?: boolean;
  $isHovered?: boolean;
}>`
  display: ${(props) => (props.$editMode ? 'none' : 'flex')};
  align-items: center;
  border-radius: 4px;

  svg {
    margin-right: 4px;
    fill: ${(props) => props.$iconColor || props.theme.primaryColor};
    opacity: ${(props) => (props.$hideIcon ? 0 : 1)};
  }

  ${(props) =>
    props.$isHovered &&
    css<{ $hoverColor?: string }>`
      background-color: ${(props) =>
        props.$hoverColor ?? props.theme.palette.backgroundMuted};
      cursor: pointer;

      svg {
        opacity: 1;
      }

      &:active {
        background-color: ${(props) => props.theme.secondaryColor};
      }
    `}
`;

const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 13px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

const RowWithMargin = styled(FlexRow)`
  margin-top: 10px;
  justify-content: flex-end;

  button {
    margin: 0 4px;
  }

  button:first-child {
    margin-left: 0;
  }

  button:last-child {
    margin-right: 0;
  }
`;

const ItemKey = styled.strong`
  margin-right: 16px;
`;

const PlusIcon = styled(Plus)`
  transform: rotate(45deg) scale(0.8);
`;

export const useDynamicListItem = ({
  item,
  index,
  isEditable,
}: Pick<DynamicListItemProps, 'item' | 'index' | 'isEditable'>): Omit<
  DynamicListContextType,
  'addItem' | 'removeItem'
> & {
  onAddItem: () => void;
  onCancel: () => void;
  onDelete: () => void;
  isValid: boolean;
  editMode: boolean;
  setEditMode: (v: boolean) => void;
} => {
  const { addItem, removeItem, validateItem, onNewItem, ...context } =
    useContext(DynamicListContext);
  const [editMode, setEditMode] = useState(false);

  const onAddItem = useCallback(() => {
    if (!isEditable) return;
    if (item.isNewValue)
      addItem({
        ...(onNewItem ? onNewItem(item) : item),
        isNewValue: false,
        isPlaceholder: true,
      });
    if (item.isPlaceholder) {
      addItem({ ...item, isNewValue: false, isPlaceholder: false });
      removeItem(index);
    }
    setEditMode(true);
  }, [setEditMode, addItem, item, index, isEditable, removeItem, onNewItem]);

  const onCancel = useCallback(() => {
    if (item.isPlaceholder) removeItem(index);
    setEditMode(false);
  }, [setEditMode, removeItem, item, index]);

  const onDelete = useCallback(() => {
    removeItem(index);
    setEditMode(false);
  }, [setEditMode, removeItem, index]);

  const isValid = useMemo(() => {
    return validateItem(item);
  }, [validateItem, item]);

  return {
    onAddItem,
    onCancel,
    onDelete,
    isValid,
    editMode,
    setEditMode,
    validateItem,
    onNewItem,
    ...context,
  };
};

export const DynamicListItem: React.FC<DynamicListItemProps> = (props) => {
  const { item, index, isEditable } = props;
  const theme = useTheme();

  const {
    onAddItem,
    onCancel,
    onDelete,
    isValid,
    editMode,
    setEditMode,
    EditComponent = () => null,
    hoverColor,
    type,
    Icon,
  } = useDynamicListItem({ item, index, isEditable });
  const { isHovered, hoverHandlers } = useHover();

  const skipIconColor = useMemo(
    () => !(!isValid && (!isHovered || !isEditable)) || item.isNewValue,
    [isValid, isEditable, isHovered, item]
  );

  const CurrentIcon: typeof BaseIcon = useMemo(() => {
    if (!isValid && (!isHovered || !isEditable)) return Warning;
    if (isValid && !isHovered && Icon) return Icon;
    if (!isEditable && Icon) return Icon;

    return Pencil;
  }, [isValid, isHovered, isEditable, Icon]);

  return (
    <>
      {editMode && (
        <EditWrapper>
          <EditComponent index={index} item={item} isValid={isValid} />
          <RowWithMargin>
            <Button onClick={onCancel} secondary>
              Abbrechen
            </Button>

            {!item.isPlaceholder && (
              <Button
                onClick={onDelete}
                secondary
                customTheme={{
                  ...defaultTheme,
                  primaryColor: defaultTheme.palette.error.color,
                  secondaryColor: defaultTheme.palette.error.background,
                }}
              >
                Löschen
              </Button>
            )}
            <Button disabled={!isValid} onClick={() => setEditMode(false)}>
              {item.isPlaceholder ? 'Hinzufügen' : 'Speichern'}
            </Button>
          </RowWithMargin>
        </EditWrapper>
      )}
      <Wrapper
        {...hoverHandlers}
        $hoverColor={hoverColor}
        $iconColor={skipIconColor ? '' : theme.palette.warning.color}
        $hideIcon={isValid && !Icon}
        $color={item.isNewValue ? theme.palette.textMuted : 'inherit'}
        $editMode={editMode}
        $isHovered={isEditable && isHovered}
        onClick={onAddItem}
      >
        {item.isNewValue ? (
          <>
            <PlusIcon size={30} color={theme.palette.textMuted} />
            {type} hinzufügen
          </>
        ) : (
          <>
            <CurrentIcon size={28} />
            <ItemKey className="item-key">{item.key}</ItemKey>
            <span className="item-value">{item?.value}</span>
          </>
        )}
      </Wrapper>
    </>
  );
};
