import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../icons/base-icon';
import { FlexRow } from '../layout/layout';

const Wrap = styled(FlexRow)`
  align-items: center;
`;

const Head = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  font-size: 15px;
`;

const Children = styled.div`
  margin-top: 12px;
`;

const IconWrap = styled.div`
  svg {
    fill: ${(props) => props.theme.primaryColor};
    margin-right: 5px;
  }
`;

export interface RelationProps {
  title: string;
  icon: React.ComponentType<IconProps>;
  className?: string;
  iconSize?: number;
}

export const Relation = ({
  icon,
  children,
  title,
  className,
  iconSize = 16,
}: React.PropsWithChildren<RelationProps>) => {
  const Icon = icon;

  return (
    <Wrap className={className}>
      <IconWrap>
        <Icon size={iconSize} />
      </IconWrap>
      <Head>{title}</Head>
      {children && <Children>{children}</Children>}
    </Wrap>
  );
};
