import React from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import type { TabChildrenProps, TabsProps } from '../tabs/tabs';
import {
  TabNavigationItem,
  YellowDot,
  TabNavigation,
  TabContainer,
} from '../tabs/style';
import normalize from 'path-normalize';

interface LinkedTabChildrenProps extends Omit<TabChildrenProps, 'lazy'> {
  path: string;
  replace?: boolean;
  exact?: boolean;
  strict?: boolean;
  disabled?: boolean;
}

interface LinkedTabsProps extends TabsProps {
  children: (React.ReactElement<LinkedTabChildrenProps> | null)[];
  basePath: string;
}

export const LinkedTab: React.FunctionComponent<LinkedTabChildrenProps> = ({
  children,
}) => {
  return <div>{children}</div>;
};

export const LinkedTabs: React.FunctionComponent<LinkedTabsProps> = ({
  children,
  basePath,
}) => {
  const childs: Array<React.ReactElement<TabChildrenProps>> = [];

  const navigationItems = React.Children.map(children, (child, index) => {
    const c = child as React.ReactElement<LinkedTabChildrenProps>;

    if (!React.isValidElement(c)) {
      return child;
    }

    const path = normalize(`${basePath}/${c.props.path}`);

    /**
     * redirect to the first tab
     */
    if (index === 0) {
      childs.push(
        <Route
          exact
          path={basePath}
          render={() => <Redirect push={false} to={path} />}
          key={`i`}
        />
      );
    }

    childs.push(
      <Route
        path={path}
        render={() => <TabContainer className="active">{c}</TabContainer>}
        key={path}
      />
    );

    return (
      <Route
        exact={c.props.exact}
        path={path}
        strict={c.props.strict}
        key={`${path}-n`}
        children={({ match }) => {
          const isActive = !!match;

          /**
           * prevent the transition if tab is disabled
           * or already active
           */
          const onClick = (e: any) => {
            if (c.props.disabled || isActive) {
              e.preventDefault();
            }
          };

          return (
            <Link to={path} replace={c.props.replace ?? true} onClick={onClick}>
              <TabNavigationItem className={`${isActive ? 'active' : ''}`}>
                {c.props.title}
                {c.props.hasNotification && <YellowDot />}
              </TabNavigationItem>
            </Link>
          );
        }}
      />
    );
  });

  return (
    <div>
      <TabNavigation>{navigationItems}</TabNavigation>
      <Switch>{childs}</Switch>
    </div>
  );
};
