import React, { useCallback, useState, useRef, useEffect } from 'react';
import { CSSProperties } from 'styled-components';

const baseStyle = {
  position: 'absolute',
  borderRadius: '50%',
  width: '35px',
  height: '35px',
  pointerEvents: 'none',
  transform: 'translate(-50%, -50%)',
  opacity: 0,
};

export const useRipple = () => {
  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const [rippleStyle, setStyle] = useState(baseStyle);
  const fireRipple = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      if (process.env.NODE_ENV === 'test') return;

      const {
        pageX,
        pageY,
        currentTarget: { offsetLeft, offsetTop, offsetWidth, offsetHeight },
      } = ev;
      const left = pageX - offsetLeft;
      const top = pageY - offsetTop;
      const size = Math.max(offsetWidth, offsetHeight);

      setStyle((state) => ({
        ...state,
        left: Number.isNaN(left) ? 0 : left,
        top: Number.isNaN(top) ? 0 : top,
        opacity: 1,
        transform: 'translate(-50%, -50%)',
        transition: 'initial',
      }));

      setTimeout(() => {
        if (isMounted.current) {
          setStyle((state) => ({
            ...state,
            opacity: 0,
            transform: `scale(${size / 9})`,
            transition: `all 600ms`,
          }));
        }
      }, 50);
    },
    [setStyle]
  );

  return { rippleStyle: rippleStyle as CSSProperties, fireRipple };
};
