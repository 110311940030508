import React from 'react';
import styled from 'styled-components';
import { SpinnerDark } from '../spinner/spinner';

import { ButtonBase, BaseButtonProps } from './button-base';

const SpinnerCentered = styled(SpinnerDark)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  right: -10px;
  overflow: visible;
`;

export interface ButtonProps extends BaseButtonProps {
  /**
   * Show loading spinner
   * @default false
   */
  isLoading?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ isLoading, children, disabled, ...rest }, ref) => {
    return (
      <ButtonBase {...rest} ref={ref} disabled={isLoading || disabled}>
        {children}
        {isLoading && <SpinnerCentered size={14} />}
      </ButtonBase>
    );
  }
);

Button.displayName = 'Button';
