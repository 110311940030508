import { withTheme } from 'styled-components';

import logo from '../assets/images/ae-logo.png';

export interface ColorProps {
  color: string;
  background: string;
}

export interface ColorPalette {
  info: ColorProps;
  success: ColorProps;
  warning: ColorProps;
  error: ColorProps;

  /**
   * @deprecated use semantic colors instead of gray
   */
  gray: Record<string, string>;

  text: string;
  textMuted: string;
  textSubtle: string;

  background: string;
  backgroundMuted: string;
  backgroundEmphasis: string;

  border: string;
  borderMuted: string;
  borderEmphasis: string;

  outline: string;
  outlineShadow: string;

  ratioBarPrimary: string;
  ratioBarSecondary: string;
}

export interface Theme {
  primaryColor: string;
  secondaryColor: string;
  palette: ColorPalette;
  logo: string;
  fontFamily: string;
}

export interface ThemeProps {
  theme: Theme;
}

export const defaultTheme: Theme = {
  fontFamily: '"Proxima Nova", sans-serif',
  primaryColor: '#119142',
  secondaryColor: '#DBFFD4',
  palette: {
    info: {
      color: '#4d7488',
      background: '#eef8fd',
    },
    error: {
      color: '#ff0e0e',
      background: '#ffe5e5',
    },
    success: {
      color: '#0d7312',
      background: '#ddf1c5',
    },
    warning: {
      color: '#ffa40e',
      background: '#ffefd5',
    },

    text: '#000',
    textMuted: '#5d5d5d',
    textSubtle: '#bfbfbf',

    background: '#fff',
    backgroundMuted: '#F5F5F5',
    backgroundEmphasis: '#ededf1',

    border: '#e1e1e1',
    borderMuted: '#F5F5F5',
    borderEmphasis: '#9e9e9e',

    outline: '#119142',
    outlineShadow: '#067ffb52',

    ratioBarPrimary: '#88cbff',
    ratioBarSecondary: '#cdf2f2',

    gray: {
      100: '#F5F5F5',
      200: '#F1F1F1',
      300: '#e1e1e1',
      400: '#d3d3d3',
      500: '#bfbfbf',
      600: '#9e9e9e',
      700: '#888888',
      800: '#5d5d5d',
      900: '#323231',
    },
  },
  logo,
};

export const RenderWithTheme = withTheme((props: ThemeProps & any) => {
  return props.children(props.theme);
});
