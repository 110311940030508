import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { PageClose } from '../close/close';
import { DialogProvider, useDialog } from '../dialog/useDialog';
import { Flex, FlexRow } from '../layout/layout';
import { PageTitle } from '../typography/typography';
import { InternalModal, MaxModalWidth, ModalBodyProps } from './internal-modal';
import { useBeforeunload } from './useBeforeunload';

export type { MaxModalWidth };

export interface ModalProps extends ModalBodyProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  contentLabel: string;
  children: any;
  title: React.ReactNode;
}

const PageCloseButton = styled(PageClose)`
  display: inline-block;
  margin-top: inherit;
  align-self: center;
`;

type WarnWhenMovingAway = {
  warnWhenMovingAway?: {
    enabled: boolean;
    title: string;
    body: string;
  };
};

type ModalWarnWhenMovingAwayProps = React.ComponentProps<typeof InternalModal> &
  WarnWhenMovingAway;

export function Modal(props: ModalWarnWhenMovingAwayProps) {
  return (
    <DialogProvider>
      <ModalWarnWhenMovingAway {...props} />
    </DialogProvider>
  );
}

function ModalWarnWhenMovingAway({
  warnWhenMovingAway,
  onRequestClose,
  ...rest
}: ModalWarnWhenMovingAwayProps) {
  const { openConfirmation } = useDialog();

  useBeforeunload((event) => {
    if (warnWhenMovingAway && warnWhenMovingAway.enabled && rest.isOpen) {
      event.preventDefault();
    }
  });

  const internalOnRequestClose = useCallback(async () => {
    if (warnWhenMovingAway && warnWhenMovingAway.enabled && rest.isOpen) {
      await openConfirmation({
        title: warnWhenMovingAway.title,
        content: warnWhenMovingAway.body,
        confirmButtonLabel: 'Verlassen',
      });
      onRequestClose?.();
    } else {
      onRequestClose?.();
    }
  }, [onRequestClose, openConfirmation, rest.isOpen, warnWhenMovingAway]);

  return <InternalModal onRequestClose={internalOnRequestClose} {...rest} />;
}

export function modalSetAppElement(element: string | HTMLElement) {
  ReactModal.setAppElement(element);
}

const OverlayWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.palette.background};
  top: 0;
  bottom: 0;
  z-index: 2;
  overflow: scroll;
`;

const Center = styled.div<{ $width?: number }>`
  width: ${(props) => (props.$width ? props.$width : '1024')}px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export interface OverlayProps {
  children: React.ReactNode;
  title: string;
  onRequestClose: () => void;
  isOpen: boolean;
  maxWidth?: 'medium' | 'large';
  className?: string;
}

export const Overlay = ({
  children,
  title,
  onRequestClose,
  isOpen,
  maxWidth = 'medium',
  className,
}: OverlayProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <OverlayWrapper className={className}>
      <Center $width={maxWidth === 'large' ? 1200 : 1024}>
        <FlexRow>
          <Flex>
            <PageTitle>{title}</PageTitle>
          </Flex>
          <PageCloseButton
            onClick={onRequestClose}
            role="button"
            tabIndex={0}
            onKeyDown={onRequestClose}
            data-testid="overlay-close"
            aria-label="Schließen"
          />
        </FlexRow>
        {children}
      </Center>
    </OverlayWrapper>
  );
};
