import React, { createContext, useCallback, useMemo, useState } from 'react';
import { Formik, Form, FormikConfig, FormikValues } from 'formik';
import styled from 'styled-components';

import { AlertRetryable } from '../alert/alert-retryable';
import { Button } from '../button/button';
import { ButtonGroup } from '../button/button-group';
import { ErrorAlert } from '../alert/alert-base';
import { FormikSubmit } from '../form/input';
import { Link } from '../link/link';
import { WithErrorsStepper } from '../stepper/with-errors-stepper';

const ActionButtons = styled(ButtonGroup)`
  margin-top: 10px;
`;

const AlertLink = styled(Link)`
  color: inherit;
`;

type ValidationError = {
  message: string;
  pointer: string;
  retryable?: boolean;
};

export interface FormFlowStep {
  title: string;
  content: React.ReactNode;
}

export interface MultistepFormProps {
  initialValues: FormikConfig<FormikValues>['initialValues'];
  onSubmit: ({
    newValues,
    initialValues,
  }: {
    newValues: FormikValues;
    initialValues: FormikValues;
  }) => Promise<{
    data?: Record<string, any> | null | undefined;
    errors?: readonly { message?: string; name?: string }[];
  }>;
  onSuccess?: (result: unknown, newValues: { values: FormikValues }) => void;
  onFail?: (error: unknown) => void;
  onAbort?: () => void;
  validationSchema: FormikConfig<FormikValues>['validationSchema'];
  steps: FormFlowStep[];
  initialStepIndex?: number;
  labels?: {
    back?: string;
    cancel?: string;
    next?: string;
    submit?: string;
  };
  className?: string;
  wrapperClassName?: string;
  actionButtonsClassName?: string;
  extraActionButtons?: React.ReactNode;
  showSubmitInEveryStep?: boolean;
}

interface FormValidationErrorProps {
  stepLabel: string;
  fieldLabel: string;
  onClick: () => void;
}

const FormValidationError: React.FC<FormValidationErrorProps> = ({
  stepLabel,
  fieldLabel,
  onClick,
}) => {
  return (
    <ErrorAlert>
      Im Schritt{' '}
      <AlertLink role="button" tabIndex={0} onClick={onClick}>
        {stepLabel}
      </AlertLink>{' '}
      ist das Feld <strong>{fieldLabel}</strong> fehlerhaft.
    </ErrorAlert>
  );
};

export interface MultistepFormContextType {
  registerField: (name: string) => void;
  unregisterField: (name: string) => void;
  setStepIndex: (index: number) => void;
  stepIndex: number;
  isValid: boolean;
  isSubmitting: boolean;
}

export const MultistepFormContext = createContext<
  MultistepFormContextType | undefined
>(undefined);

export const MultistepForm: React.FC<MultistepFormProps> = ({
  initialValues,
  onSubmit,
  onSuccess,
  onFail,
  onAbort,
  validationSchema,
  steps,
  initialStepIndex = 0,
  labels,
  className,
  wrapperClassName,
  actionButtonsClassName,
  extraActionButtons,
  showSubmitInEveryStep = false,
  children,
}) => {
  const invalidInitialStepIndex =
    initialStepIndex > steps.length - 1 || initialStepIndex < 0;
  const [mutationError, setMutationError] = useState<
    (string | { message?: string; name?: string })[] | null
  >(null);
  const [stepIndex, setStepIndex] = useState(
    invalidInitialStepIndex ? 0 : initialStepIndex
  );
  const [validationErrors, setValidationErrors] = useState<Record<
    string,
    string
  > | null>(null);

  if (invalidInitialStepIndex) {
    console.error(
      `Invalid initialStepIndex ${initialStepIndex}. Must be [0, ${
        steps.length - 1
      }]`
    );
  }

  /**
   * formik provides `isSubmitting` on his own but is not fast enough
   * in updating it which can result in double submits
   */
  const [isSubmitting, setSubmitting] = useState(false);
  const fieldsStepMap: { [key: string]: number } = useMemo(() => ({}), []);

  const renderedFields: { [key: string]: Set<string> } = useMemo(() => {
    return steps.reduce(
      (acc, _, index) => ({
        ...acc,
        [index]: new Set(),
      }),
      {}
    );
  }, [steps]);

  const registerField = useCallback(
    (fieldName: string) => {
      renderedFields[stepIndex].add(fieldName);
      fieldsStepMap[fieldName] = stepIndex;
    },
    [fieldsStepMap, renderedFields, stepIndex]
  );

  const unregisterField = useCallback(
    (fieldName: string) => {
      renderedFields[stepIndex].delete(fieldName);
    },
    [renderedFields, stepIndex]
  );

  const onNextStep = useCallback(() => {
    setSubmitting(false);
    setStepIndex((stepIndex) => stepIndex + 1);
  }, []);

  const onPrevStep = useCallback(() => {
    if (stepIndex === 0) {
      if (onAbort) onAbort();
      return;
    }

    setStepIndex((stepIndex) => stepIndex - 1);
    // warn about losing data
  }, [onAbort, stepIndex]);

  const onValidate = async (newValues: any) => {
    const fields = Array.from(renderedFields[stepIndex]);
    const errors: { [key: string]: string } = {};

    for (const fieldName of fields) {
      try {
        await validationSchema.validateAt(fieldName, newValues, {
          context: newValues,
        });
      } catch (err) {
        errors[fieldName] = (err as Error).message;
      }
    }

    return errors;
  };

  const onFormSubmit = useCallback<FormikConfig<FormikValues>['onSubmit']>(
    async (
      newValues,
      { setTouched, setSubmitting: setFormikSubmitting, setStatus }
    ) => {
      setTouched({});

      if (isSubmitting) {
        return;
      }

      setSubmitting(true);
      setFormikSubmitting(true);

      try {
        const { data, errors } = await onSubmit({
          newValues,
          initialValues,
        });

        if (onSuccess && data) {
          const keys = Object.keys(data as object);

          if (keys.length === 0) {
            throw new Error('No key found');
          }

          onSuccess(data[keys[0] as keyof typeof data], {
            values: newValues,
          });
        }

        if (errors) {
          setMutationError(errors as any);
        }
      } catch (error) {
        if (
          Array.isArray(error) &&
          error[0]?.__typename === 'ValidationError'
        ) {
          const formattedErrors = error.reduce(
            (
              acc: { [field: string]: string },
              { pointer, message }: ValidationError
            ) => {
              return { ...acc, [pointer]: message };
            },
            {}
          );

          setValidationErrors(formattedErrors);
          setTouched({});
          setStatus(formattedErrors);
        } else {
          setMutationError(error as string[]);
        }

        if (onFail) onFail(error);
      } finally {
        setFormikSubmitting(false);
        setSubmitting(false);
      }
    },
    [initialValues, isSubmitting, onSuccess, onFail, onSubmit]
  );

  const stepperSteps: any = useMemo(
    () =>
      steps.map((s: FormFlowStep) => ({
        label: s.title,
      })),
    [steps]
  );

  const isLastStep = stepIndex === steps.length - 1;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onFormSubmit}
      validate={onValidate}
      className={className}
    >
      {({ isSubmitting, isValid }) => (
        <MultistepFormContext.Provider
          value={{
            registerField,
            unregisterField,
            setStepIndex,
            stepIndex,
            isValid,
            isSubmitting,
          }}
        >
          <Form autoComplete="off" className={wrapperClassName}>
            <WithErrorsStepper
              steps={stepperSteps}
              index={stepIndex}
              fieldsStepMap={fieldsStepMap}
              onStepClick={setStepIndex}
            />
            <div>
              {mutationError && (
                <>
                  {Array.isArray(mutationError) ? (
                    mutationError.map((error) => (
                      <AlertRetryable
                        title={'Fehler'}
                        error={error}
                        retryable={false}
                      />
                    ))
                  ) : (
                    <AlertRetryable
                      title={'Fehler'}
                      message={String(mutationError)}
                      retryable={false}
                    />
                  )}
                </>
              )}
              {validationErrors &&
                isLastStep &&
                Object.keys(validationErrors).map((error) => {
                  const index =
                    fieldsStepMap[error as keyof typeof fieldsStepMap];
                  if (!index) return null;

                  const stepLabel = stepperSteps[index]?.label;

                  if (!stepLabel) return null;

                  return (
                    <FormValidationError
                      key={error}
                      stepLabel={stepLabel}
                      fieldLabel={'???'}
                      onClick={() => setStepIndex(index)}
                    />
                  );
                })}
              {/* <SubTitle> {steps[stepIndex].title}</SubTitle> */}

              {steps[stepIndex].content}
            </div>
            <ActionButtons className={actionButtonsClassName}>
              {extraActionButtons}
              <Button
                onClick={onPrevStep}
                disabled={isSubmitting}
                secondary
                data-testid="graphql-form-abort"
              >
                {stepIndex === 0
                  ? labels?.cancel ?? 'Abbrechen'
                  : labels?.back ?? 'Zurück'}
              </Button>
              {!isLastStep && (
                <Button onClick={onNextStep}>{labels?.next ?? 'Weiter'}</Button>
              )}
              {(isLastStep || showSubmitInEveryStep) && (
                <FormikSubmit>{labels?.submit ?? 'Speichern'}</FormikSubmit>
              )}
            </ActionButtons>
            {children}
          </Form>
        </MultistepFormContext.Provider>
      )}
    </Formik>
  );
};
