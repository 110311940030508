import * as React from 'react';
import { ConfirmationDialog } from '../confirmation/confirmation-dialog';
import { MaxModalWidth } from '../modal/modal';
import { defaultTheme } from '../theme/theme';

export interface ConfirmationStateProps {
  content: React.ReactNode;
  confirmButtonLabel?: string;
  catchOnCancel?: boolean;
  dangerButton?: boolean;
  disableCancel?: boolean;
  maxWidth?: MaxModalWidth;
  title: string;
  handleRequest?: () => Promise<void> | void;
}

export interface DialogStateProp {
  confirmButtonLabel?: string;
  content: React.ReactNode;
  maxWidth?: MaxModalWidth;
  title: string;
}

export const DialogContext = React.createContext<{
  openConfirmation: (options: ConfirmationStateProps) => Promise<unknown>;
  openDialog: (options: DialogStateProp) => void;
}>({
  openConfirmation: () => Promise.resolve(),
  openDialog: () => {},
});

export const useDialog = () => React.useContext(DialogContext);

export function DialogProvider({ children }: { children: React.ReactNode }) {
  const [confirmationState, setConfirmationState] =
    React.useState<ConfirmationStateProps | null>(null);
  const [dialogState, setDialogState] = React.useState<DialogStateProp | null>(
    null
  );
  const [loading, setLoading] = React.useState(false);

  const componentMounted = React.useRef<boolean | undefined>();

  const awaitingPromiseRef = React.useRef<{
    resolve: (value?: unknown) => void;
    reject: (error?: unknown) => void;
  }>();

  React.useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const openConfirmation = React.useCallback(
    (options: ConfirmationStateProps) => {
      setConfirmationState({
        catchOnCancel: false,
        disableCancel: false,
        ...options,
      });

      return new Promise((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject };
      });
    },
    []
  );

  const closeConfirmation = React.useCallback(() => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    if (componentMounted.current) {
      setConfirmationState(null);
    }
  }, [confirmationState]);

  const submitConfirmation = React.useCallback(async () => {
    setLoading(true);
    try {
      if (confirmationState?.handleRequest) {
        await confirmationState.handleRequest();
      }
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve();
      }
    } catch (error) {
      awaitingPromiseRef.current?.reject(error);
    }
    if (componentMounted.current) {
      setLoading(false);
      setConfirmationState(null);
    }
  }, [confirmationState]);

  const openDialog = React.useCallback((options: DialogStateProp) => {
    setDialogState({
      ...options,
    });
  }, []);

  const closeDialog = React.useCallback(() => {
    if (componentMounted.current) {
      setDialogState(null);
    }
  }, []);

  const buttonTheme = React.useMemo(
    () =>
      confirmationState?.dangerButton
        ? {
            ...defaultTheme,
            primaryColor: defaultTheme.palette.error.color,
            secondaryColor: defaultTheme.palette.error.background,
          }
        : undefined,
    [confirmationState]
  );

  const value = React.useMemo(
    () => ({
      openConfirmation,
      openDialog,
    }),
    [openConfirmation, openDialog]
  );

  return (
    <DialogContext.Provider value={value}>
      {children}
      {confirmationState && (
        <ConfirmationDialog
          buttonTheme={buttonTheme}
          confirmLabel={confirmationState.confirmButtonLabel}
          disableCancel={confirmationState.disableCancel || false}
          id="confirmation-modal"
          isLoading={loading}
          isOpen={true}
          maxWidth={confirmationState.maxWidth ?? 'sm'}
          title={confirmationState.title}
          onConfirm={submitConfirmation}
          onAbort={closeConfirmation}
        >
          {confirmationState.content}
        </ConfirmationDialog>
      )}
      {dialogState && (
        <ConfirmationDialog
          confirmLabel={dialogState.confirmButtonLabel ?? 'Ok'}
          disableCancel={true}
          id="dialog-modal"
          isOpen={true}
          maxWidth={dialogState.maxWidth ?? 'sm'}
          title={dialogState.title}
          onConfirm={closeDialog}
        >
          {dialogState.content}
        </ConfirmationDialog>
      )}
    </DialogContext.Provider>
  );
}

export const withDialogProvider =
  <T extends object>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: any) => {
    return (
      <DialogProvider>
        <Component {...props} />
      </DialogProvider>
    );
  };
