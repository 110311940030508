import React, { useState, useEffect, useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import styled, { useTheme } from 'styled-components';

import { FlexRow, Flex } from '../layout/layout';
import { BaseFont } from '../typography/typography';
import { Button } from '../button/button';
import { Time } from '../icons/icons';

import { formatDate } from '../formatters';

const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.background};
  min-width: 500px;
`;

const InnerWrapper = styled(FlexRow)`
  border: 1px solid ${(props) => props.theme.palette.borderMuted};
  border-radius: 4px;
`;

const Sidebar = styled.ul<{ isOnlySidebar: boolean; height?: number }>`
  min-width: 200px;
  padding-left: 5px;
  margin: 0;
  padding: 0;
  height: ${(props) => props.height ?? 280}px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: ${(props) => (props.isOnlySidebar ? 1 : 'none')};
`;

const SidebarItem = styled.li<{ isActive: boolean }>`
  list-style: none;
  padding: 5px 5px 5px 5px;

  border-left: 2px solid
    ${(props) => (props.isActive ? props.theme.primaryColor : 'transparent')};
`;

const HoverContainer = styled.div<{
  isActive: boolean;
  disableSelection: boolean;
}>`
  padding: 9px 9px;
  cursor: ${(props) => (props.disableSelection ? 'inherit' : 'pointer')};
  border-radius: 4px;

  &:hover {
    background: ${(props) =>
      props.disableSelection ? 'inherit' : props.theme.palette.backgroundMuted};
  }
`;

const Date = styled.div<{ hasValue: boolean }>`
  font-size: 14px;
  padding-top: 4px;
  color: ${(props) =>
    props.hasValue ? props.theme.palette.textMuted : 'inherit'};
`;

const Header = styled(FlexRow)`
  padding: 5px 0px 10px 0px;
  align-items: center;
`;

const Title = styled.div`
  ${BaseFont}
  font-size: 17px;
  margin-top: 0;
`;

const Main = styled.div`
  padding: 16px;
  flex: 1;
  border-left: 1px solid ${(props) => props.theme.palette.border};
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export interface HistoricItem {
  date: string;
  value?: any;
  id: string | number;
}

export interface HistoricTabsProps<T extends HistoricItem> {
  items?: T[];
  isLoading: boolean;
  renderValue?: (item: T) => React.ReactNode;
  children?: (item: T) => React.ReactNode;
  renderActions?: () => React.ReactNode;
  loadMore?: () => void;
  index?: number;
  onItemSelect?: (item: T) => void;
  title: React.ReactNode;
  height?: number;
  withTitle?: boolean;
  withDate?: boolean;
  formatDate?: (date: string) => string;
}

const RenderSidebarItems = <T extends HistoricItem>(
  items: T[],
  activeItem: T,
  onClick: (item: T) => void,
  isOnlySidebar: boolean,
  renderValue?: (item: T) => React.ReactNode,
  withDate: boolean = true,
  formatDateFn: (date: string) => string = formatDate
) => {
  return items.map((item) => (
    <SidebarItem
      onClick={() => !isOnlySidebar && onClick(item)}
      isActive={!isOnlySidebar && item === activeItem}
      key={item.id}
    >
      <HoverContainer
        isActive={!isOnlySidebar && item === activeItem}
        disableSelection={isOnlySidebar}
      >
        {renderValue?.(item) ?? null}
        {withDate && (
          <Date hasValue={renderValue !== undefined}>
            {formatDateFn(item.date)}
          </Date>
        )}
      </HoverContainer>
    </SidebarItem>
  ));
};

const RenderSiderbarLoadingItems = () => {
  const theme = useTheme();

  return new Array(3).fill(null).map(() => (
    <SidebarItem isActive={false}>
      <ContentLoader
        speed={2}
        width={180}
        height={40}
        viewBox="0 0 180 40"
        backgroundColor={theme.palette.backgroundMuted}
        foregroundColor={theme.palette.border}
      >
        <rect x="0" y="8" rx="3" ry="3" width="150" height="6" />
        <rect x="0" y="26" rx="3" ry="3" width="80" height="6" />
      </ContentLoader>
    </SidebarItem>
  ));
};

const renderLoadMore = (loadMore: () => void) => {
  return (
    <Button secondary onClick={loadMore}>
      Load More
    </Button>
  );
};

export const HistoricTabs = <T extends HistoricItem>({
  items,
  isLoading,
  renderValue,
  children,
  renderActions,
  loadMore,
  index,
  onItemSelect,
  title,
  height,
  withTitle = true,
  withDate = true,
  formatDate,
}: HistoricTabsProps<T>) => {
  const initialIndex =
    index !== undefined
      ? items?.[index] ?? null
      : isLoading
      ? null
      : items?.[0] ?? null;

  const [activeItem, _setActiveItem] = useState<T | null>(initialIndex);

  useEffect(() => {
    if (index !== undefined) {
      _setActiveItem(items?.[index] ?? null);
    } else if (!isLoading) {
      _setActiveItem(items?.[0] ?? null);
    }
  }, [items, index, isLoading, _setActiveItem]);

  const setActiveItem = useCallback(
    (item: T) => {
      onItemSelect?.(item);
      _setActiveItem(item);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [index, onItemSelect, _setActiveItem]
  );

  return (
    <Wrapper>
      {withTitle && (
        <Header>
          <Flex>
            <Title>
              <IconWrapper>
                <Time size={18} />
              </IconWrapper>
              Verlauf {title}
            </Title>
          </Flex>
          {renderActions?.() ?? null}
        </Header>
      )}
      <InnerWrapper>
        <Sidebar isOnlySidebar={children === undefined} height={height}>
          {isLoading
            ? RenderSiderbarLoadingItems()
            : RenderSidebarItems(
                items!,
                activeItem!,
                setActiveItem,
                children === undefined,
                renderValue,
                withDate,
                formatDate
              )}
          {!isLoading && loadMore && renderLoadMore(loadMore)}
        </Sidebar>
        {activeItem && children ? <Main>{children(activeItem)}</Main> : null}
      </InnerWrapper>
    </Wrapper>
  );
};
