import styled, { useTheme } from 'styled-components';
import React, { useState, useEffect, useRef } from 'react';
import { Checkmark } from '../icons/icons';
import ReactCanvasConfetti from 'react-canvas-confetti';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

const CenterCanvas = styled(Center)`
  position: relative;
  width: 300px;
  height: 200px;

  svg {
    margin-top: 90px;
    z-index: 5;
  }
`;

const canvasStyle = {
  position: 'absolute' as any,
  height: 200,
  pointerEvents: 'none',
};

export interface SuccessConfettiProps {
  children?: React.ReactNode;
}

export const SuccessConfetti: React.FC<SuccessConfettiProps> = ({
  children,
}) => {
  const theme = useTheme();
  const ref = useRef<any>(null);
  const [fire, setFire] = useState(false);

  useEffect(() => {
    const makeShot = (particleRatio: number, opts: any) => {
      if (ref)
        ref.current({
          ...opts,
          origin: { y: 0.6 },
          particleCount: Math.floor(50 * particleRatio),
        });
    };

    makeShot(0.25, {
      spread: 25,
      startVelocity: 12,
      scalar: 0.5,
    });

    makeShot(0.2, {
      spread: 60,
      scalar: 0.5,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 1,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 12,
      decay: 0.92,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 23,
      scalar: 0.5,
    });
  }, [ref, fire]);

  return (
    <Center>
      <CenterCanvas>
        <Checkmark
          size={60}
          color={theme.palette.success.color}
          onClick={() => setFire(!fire)}
        />
        <ReactCanvasConfetti
          style={canvasStyle as any}
          refConfetti={(r) => (ref.current = r)}
        />
      </CenterCanvas>
      {children}
    </Center>
  );
};
