import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InlineTable as InlineTableComponent } from '../inline-table/inline-table';
import { KeyValue } from '../key-value/key-value';
import { Circles } from '../circles/circles';
import { Button } from '../button/button';
import { UpdatePassword, UpdatePasswordProps } from './update-password';

type User = {
  firstName: string;
  email: string;
  lastName: string;
};

export interface UserSettingsProps {
  user?: User;
  useUpdatePasswordMutation: UpdatePasswordProps['useUpdatePasswordMutation'];
}

const InlineTable = styled(InlineTableComponent)`
  --inline-table-border-color: none;

  tr td {
    min-width: 50%;
  }
`;

export function UserSettingsForm({
  user,
  useUpdatePasswordMutation,
}: UserSettingsProps) {
  const { t } = useTranslation('userSettings');
  const [showUpdatePassword, setShowUpdatePassword] = React.useState(false);

  return (
    <InlineTable>
      <tbody>
        <tr>
          <td>
            <KeyValue>
              <div className="key">{t('firstName')}</div>
              {user?.firstName}
            </KeyValue>
          </td>
          <td>
            <KeyValue>
              <div className="key">{t('email')}</div>
              {user?.email}
            </KeyValue>
          </td>
        </tr>
        <tr>
          <td>
            <KeyValue>
              <div className="key">{t('lastName')}</div>
              {user?.lastName}
            </KeyValue>
          </td>
        </tr>
        <tr>
          <td>
            {showUpdatePassword ? (
              <UpdatePassword
                email={user?.email}
                setShowUpdatePassword={setShowUpdatePassword}
                useUpdatePasswordMutation={useUpdatePasswordMutation}
              />
            ) : (
              <KeyValue>
                <div className="key">{t('password')}</div>
                <Circles />
                <Button
                  secondary
                  bold
                  onClick={() => setShowUpdatePassword(true)}
                >
                  {t('changePassword')}
                </Button>
              </KeyValue>
            )}
          </td>
        </tr>
      </tbody>
    </InlineTable>
  );
}
