import React from 'react';
import styled from 'styled-components';

import { BaseIcon } from '../icons/base-icon';
import { defaultTheme } from '../theme/theme';

import { Button } from './button';
import { BaseButtonProps } from './button-base';

const Spacer = styled.span`
  margin-left: 5px;
`;

const IconButtonWrapper = styled(Button)<{
  secondary?: boolean;
  muted?: boolean;
  color?: string;
  disabled?: boolean;
}>`
  svg {
    fill: ${(props) =>
      props.disabled
        ? props.theme.palette.textSubtle
        : props.muted
        ? props.theme.palette.textMuted
        : props.color
        ? props.color
        : props.theme.primaryColor};
  }

  button:hover:not(:disabled):not(.active) {
    svg {
      fill: ${(props) => props.color || props.theme.primaryColor};
    }
  }

  button.active:not(:disabled) {
    svg {
      fill: ${(props) => props.theme.palette.background};
    }
  }
`;

export interface IconButtonProps extends BaseButtonProps {
  icon: typeof BaseIcon;
  /**
   * option to mute the icon color down. Useful
   * in situations where lots of colorful buttons
   * would distract the user for example in lists.
   * Note: Use only on secondary buttons.
   */
  muted?: boolean;

  /**
   * overwrite the color for example for a
   * delete button. Note: Use only on
   * secondary buttons.
   */
  color?: string;
}

export const ButtonIcon = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & IconButtonProps
>((props, ref) => {
  const {
    icon: Icon,
    muted,
    color,
    children,
    secondary,
    disabled,
    ...rest
  } = props;
  return (
    <IconButtonWrapper
      {...rest}
      secondary={secondary}
      muted={muted}
      color={color}
      disabled={disabled}
      {...{
        ...(color && {
          customTheme: {
            ...defaultTheme,
            primaryColor: color,
            secondaryColor: color,
          },
        }),
      }}
      ref={ref}
    >
      <Icon size={20} />
      {children && <Spacer>{children}</Spacer>}
    </IconButtonWrapper>
  );
});
