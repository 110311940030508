import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LoginBackground } from '../assets/images/moduleBg2.svg';

export interface LoginLayoutProps {
  ProductLogo?: string;
  className?: string;
}

const LoginWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.background};
  padding: 20px 25px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  z-index: 1;

  img {
    max-width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Box = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoginLayout: React.FC<LoginLayoutProps> = ({
  ProductLogo,
  children,
  className,
}) => {
  return (
    <Box>
      <LoginWrapper className={className}>
        {ProductLogo && <img src={ProductLogo} alt="Logo" />}
        {children}
      </LoginWrapper>
      <LoginBackground
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          zIndex: 0,
          opacity: 0.5,
        }}
      />
    </Box>
  );
};
