import * as React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { defaultTheme, Theme } from './theme';

export const ThemeProvider = ({
  children,
  extendedTheme,
}: {
  children: React.ReactNode;
  extendedTheme?: Partial<Theme>;
}) => {
  const theme = {
    ...defaultTheme,
    ...extendedTheme,
  };
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};
