import { DateTime } from 'luxon';

export const EMPTY_STR = '--';

export function maybeStr(t: string | number | null | undefined): string {
  if (t === undefined || t === null || t === '') return EMPTY_STR;
  return String(t);
}

export function formatString(str?: string | null | number): string {
  return maybeStr(str);
}

export function formatCurrency(str?: number): string {
  if (!str) return EMPTY_STR;

  const currencyFormat = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return currencyFormat.format(str);
}

export function formatDecimal(str?: number): string {
  if (!str) return EMPTY_STR;

  const decimalFormat = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
  });
  return decimalFormat.format(str);
}

export function formatDate(str?: string) {
  // WARNING: space chars here are THE EMPTY CHAR (U+200C)
  // COPY HERE: "‌‌ "
  if (!str) return `‌‌ ‌‌ ${EMPTY_STR}‌‌ ‌‌ `;

  return DateTime.fromISO(str).toLocaleString({
    ...DateTime.DATE_SHORT,
    month: '2-digit',
    day: '2-digit',
    numberingSystem: 'latn',
  });
}

export function formatDateTime(str?: string) {
  if (!str) return EMPTY_STR;

  return DateTime.fromISO(str).toLocaleString({
    ...DateTime.DATE_SHORT,
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    numberingSystem: 'latn',
  });
}

export function formatBoolean(val?: boolean): string {
  if (val === null) return EMPTY_STR;

  return val === true ? 'Ja' : 'Nein';
}

export function formatTwoDigitString(str?: string): string {
  if (!str) return EMPTY_STR;

  return ('0' + str).slice(-2);
}

export function formatHour(d: Date) {
  return DateTime.fromJSDate(d).toFormat('HH:mm');
}

export function formatDateShort(date: Date) {
  return DateTime.fromJSDate(date)
    .setLocale('de')
    .toLocaleString({
      ...DateTime.DATE_SHORT,
      day: '2-digit',
      month: '2-digit',
      numberingSystem: 'latn',
    });
}
