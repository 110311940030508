import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import * as Icons from '../icons/icons';
import { IconProps } from '../icons/base-icon';

const AlertWrapper = styled.div<{
  foregroundColor: string;
  backgroundColor: string;
  inline: boolean;
}>`
  font-size: 14px;
  padding: 12px 15px 12px 15px;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: ${(props) => props.backgroundColor};
  align-items: center;
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};

  .alert-content {
    white-space: break-spaces;
    color: ${(props) => props.theme.palette.text};
    flex: 1;
  }
`;

const IconWrap = styled.div`
  margin-right: 0px;
  display: inline-block;
  position: relative;
  left: -5px;
`;

interface AlertProps {
  children: React.ReactNode;
  icon?: React.ComponentType<IconProps>;
  className?: string;
  inline?: boolean;
  expandableChildren?: React.ReactNode;
}

export interface AlertBaseProps extends AlertProps {
  icon: React.ComponentType<IconProps>;
  foregroundColor: string;
  backgroundColor: string;
}

export function AlertBase({
  icon,
  children,
  className,
  foregroundColor,
  backgroundColor,
  inline,
}: AlertBaseProps) {
  const Icon = icon;

  return (
    <AlertWrapper
      role="alert"
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
      className={className}
      inline={inline ?? false}
    >
      <IconWrap>
        <Icon size={25} className="alert-icon" color={foregroundColor} />
      </IconWrap>
      <div className="alert-content">{children}</div>
    </AlertWrapper>
  );
}

export const ErrorAlert = (props: AlertProps) => {
  const theme = useTheme();

  return (
    <AlertBase
      icon={Icons.Warning}
      foregroundColor={theme.palette.error.color}
      backgroundColor={theme.palette.error.background}
      {...props}
    />
  );
};

const DetailButton = styled.div`
  color: rgb(251, 49, 62);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const Details = styled.div`
  margin-top: 5px;
`;

export const ExpandableErrorAlert = ({
  details,
  children,
  ...rest
}: AlertProps & {
  details: React.ReactNode;
}) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <ErrorAlert {...rest}>
      {children}
      {details ? (
        <Details>
          {isExpanded && details}
          <DetailButton
            role="button"
            onKeyDown={() => setExpanded(!isExpanded)}
            onClick={() => setExpanded(!isExpanded)}
            tabIndex={0}
          >
            {isExpanded ? 'Weniger' : 'Mehr'}
          </DetailButton>
        </Details>
      ) : null}
    </ErrorAlert>
  );
};

export const InfoAlert = (props: AlertProps) => {
  const theme = useTheme();

  return (
    <AlertBase
      icon={Icons.Info}
      foregroundColor={theme.palette.info.color}
      backgroundColor={theme.palette.info.background}
      {...props}
    />
  );
};

export const WarningAlert = (props: AlertProps) => {
  const theme = useTheme();

  return (
    <AlertBase
      icon={Icons.Warning}
      foregroundColor={theme.palette.warning.color}
      backgroundColor={theme.palette.warning.background}
      {...props}
    />
  );
};

export const SuccessAlert = (props: AlertProps) => {
  const theme = useTheme();

  return (
    <AlertBase
      icon={Icons.Checkmark}
      foregroundColor={theme.palette.success.color}
      backgroundColor={theme.palette.success.background}
      {...props}
    />
  );
};
