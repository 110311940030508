import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button, ButtonGroup } from '../button';

import { Cookie } from '../icons/icons';
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from '../local-storage';
import { Switch } from '../switch/switch';

const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1072;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  &.fade-in {
    opacity: 1;
  }
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 28px 64px;
  background-color: ${(props) => props.theme.palette.background};
  border-radius: 4px 4px 0 0;
  box-shadow: 0px -3px 4px 0px #00000013;
  z-index: 2;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.5s, transform 0.5s;

  &.slide-in {
    opacity: 1;
    transform: translateY(0);
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 44px;
  margin-right: 60px;
  flex: 1;

  & > p {
    text-align: justify;
    margin: 8px 0;
  }

  & > h3 {
    margin: 0;
    letter-spacing: 1.1px;
    font-weight: bold;
  }

  & > a {
    color: ${(props) => props.theme.palette.textMuted};
    text-transform: underline;
  }
`;

const CookieIcon = styled(Cookie)`
  fill: linear-gradient(
    90deg,
    rgba(199, 180, 225, 1) 0%,
    rgba(226, 239, 239, 1) 100%
  );
`;

const Switches = styled.div`
  display: inline-flex;
  justify-content: space-around;
  flex: 1;
  margin-top: 4px;
`;

const BottomRow = styled.div`
  display: flex;
`;

type CookieBannerScreen = 'main' | 'settings';
type CookieBannerButtonAction = 'acceptAll' | 'cancelAll';

type Screen = {
  id: CookieBannerScreen;
  title: string;
  content: string;
  mainButtonText: string;
  secondaryButtonText: string;
  mainButtonAction: CookieBannerScreen | CookieBannerButtonAction;
  secondaryButtonAction: CookieBannerScreen | CookieBannerButtonAction;
};

const screens: Record<CookieBannerScreen, Screen> = {
  main: {
    id: 'main',
    title: 'Schutz deiner Daten',
    content:
      'Wir verwenden Cookies und ähnliche Technologien, um die Stabilität und Performance unserer Produkte zu verbessern. Mit Klick auf Okay stimmst Du dem zu. Dies wird auch in unserer Cookie-Richtlinie beschrieben. Um deine Einstellungen zu ändern oder deine Einwilligung zu widerrufen, aktualisiere einfach deine Cookie-Einstellungen.',
    mainButtonText: 'Okay',
    secondaryButtonText: 'Cookie-Einstellungen',
    mainButtonAction: 'acceptAll',
    secondaryButtonAction: 'settings',
  },
  settings: {
    id: 'settings',
    title: 'Cookie-Einstellungen',
    content:
      'Da wir deine Datenschutzrechte respektieren, kannst du bestimmte Arten von Cookies ablehnen. Dies kannst du in den Einstellungen unter deinem Profil jederzeit anpassen.',
    mainButtonText: 'Alle Akzeptieren',
    secondaryButtonText: 'Alle Ablehnen',
    mainButtonAction: 'acceptAll',
    secondaryButtonAction: 'cancelAll',
  },
};

export interface CookieBannerProps {
  localStorageIdentifier: string;
  cookiesIdentifier: string;
  onSetOptionalCookies?: (value: boolean) => void;
  onClose?: () => void;
}

export const CookieBanner: React.FC<CookieBannerProps> = ({
  localStorageIdentifier,
  cookiesIdentifier,
  onSetOptionalCookies,
  onClose,
}) => {
  const alreadySeen =
    getDataFromLocalStorage(
      `${localStorageIdentifier}.${cookiesIdentifier}.required`
    ) === 'true';

  const theme = useTheme();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [screenId, setScreenId] = useState<CookieBannerScreen | null>(
    alreadySeen ? null : 'main'
  );
  const [optionalCookies, setOptionalCookies] = useState<boolean>(false);

  const saveAndClose = useCallback(
    (optional: boolean) => {
      setDataInLocalStorage(
        `${localStorageIdentifier}.${cookiesIdentifier}.required`,
        'true'
      );

      if (optional || optionalCookies) {
        setDataInLocalStorage(
          `${localStorageIdentifier}.${cookiesIdentifier}.optional`,
          'true'
        );
      }

      setScreenId(null);
      onClose?.();
    },
    [localStorageIdentifier, cookiesIdentifier, optionalCookies, onClose]
  );

  const onButtonClick = useCallback(
    (action: CookieBannerScreen | CookieBannerButtonAction) => {
      if (['main', 'settings'].includes(action)) {
        setScreenId(action as CookieBannerScreen);
      } else if (action === 'acceptAll') {
        saveAndClose(true);
      } else if (action === 'cancelAll') {
        saveAndClose(false);
      }
    },
    [setScreenId, saveAndClose]
  );

  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);

  if (alreadySeen || screenId === null) return null;

  const screen = screens[screenId];

  return (
    <Overlay className={`${loaded ? 'fade-in' : ''}`}>
      <Banner className={`${loaded ? 'slide-in' : ''}`}>
        <CookieIcon size={64} fillSvg="url(#icon-gradient)">
          <linearGradient
            id="icon-gradient"
            x1="0%"
            x2="100%"
            y1="50%"
            y2="50%"
            gradientTransform="rotate(60, 0.5, 0.5)"
          >
            <stop offset="0%" stopColor="#19F583"></stop>
            <stop offset="100%" stopColor="#169EF8"></stop>
          </linearGradient>
        </CookieIcon>
        <TextContainer>
          <h3>{screen.title}</h3>
          <p>{screen.content}</p>
          <BottomRow>
            <a
              href="https://ampeersenergy.de/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutzerklärung
            </a>
            <Switches>
              {screen.id === 'settings' && (
                <>
                  <Switch
                    checked
                    disabled
                    activeColor={theme.palette.textSubtle}
                    passiveColor={theme.palette.textSubtle}
                    labelColor={theme.palette.textSubtle}
                  >
                    Notwendige Cookies
                  </Switch>
                  <Switch
                    checked={optionalCookies}
                    activeColor={theme.primaryColor}
                    passiveColor={theme.palette.text}
                    labelColor={theme.primaryColor}
                    onChange={() =>
                      setOptionalCookies((v) => {
                        onSetOptionalCookies?.(!v);
                        return !v;
                      })
                    }
                  >
                    Optionale Cookies
                  </Switch>
                </>
              )}
            </Switches>
          </BottomRow>
        </TextContainer>
        <ButtonGroup vertical>
          <Button onClick={() => onButtonClick(screen.mainButtonAction)}>
            {screen.mainButtonText}
          </Button>
          <Button
            secondary
            onClick={() => onButtonClick(screen.secondaryButtonAction)}
          >
            {screen.secondaryButtonText}
          </Button>
        </ButtonGroup>
      </Banner>
    </Overlay>
  );
};
