import React, { useCallback } from 'react';
import styled from 'styled-components';
import { DatePicker } from '../date-picker/date-picker';
import { Section, FlexRow } from '../layout/layout';
import { Switch } from '../switch/switch';

const Container = styled(Section)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 5px 13px;
`;

const Divider = styled.span`
  font-size: 15px;
  padding: 0 6px;
`;

const SwitchWithPadding = styled(Switch)`
  padding-right: 10px;
`;

const DatePickers = styled.div`
  transition: 0.2s ease-out;
  transition-property: max-width opacity;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.hidden {
    max-width: 0;
    opacity: 0;
  }

  &.visible {
    max-width: 400px;
    opacity: 1;
  }
`;

export interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  withTime?: boolean;
  isRealTime?: boolean;
  realTimeLabel: string;
  timePeriodLabel: string;
  onRangeChange?: ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => void;
  onRealTimeSelected?: (realTime: boolean) => void;
  onTimePeriodSelected?: (timePeriod: boolean) => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  withTime,
  isRealTime,
  realTimeLabel,
  timePeriodLabel,
  onRangeChange = () => {},
  onRealTimeSelected = () => {},
  onTimePeriodSelected = () => {},
}) => {
  const handleTimePeriodStartChanged = useCallback(
    (_date) => {
      onRangeChange({ startDate: _date, endDate });
    },
    [endDate, onRangeChange]
  );

  const handleTimePeriodEndChanged = useCallback(
    (_date) => {
      onRangeChange({ startDate, endDate: _date });
    },
    [startDate, onRangeChange]
  );

  const handleRealTimeChecked = useCallback(
    (checked: boolean) => {
      onRealTimeSelected(!!checked);
      onTimePeriodSelected(!checked);
    },
    [onRealTimeSelected, onTimePeriodSelected]
  );

  const handleTimePeriodChecked = useCallback(
    (checked: boolean) => {
      onRealTimeSelected(!checked);
      onTimePeriodSelected(!!checked);
    },
    [onRealTimeSelected, onTimePeriodSelected]
  );

  return (
    <Container>
      <FlexRow>
        <SwitchWithPadding
          onChange={handleRealTimeChecked}
          checked={!!isRealTime}
          tinted={true}
        >
          {realTimeLabel}
        </SwitchWithPadding>
        <SwitchWithPadding
          onChange={handleTimePeriodChecked}
          checked={!isRealTime}
          tinted={true}
        >
          {timePeriodLabel}
        </SwitchWithPadding>
      </FlexRow>
      <DatePickers className={isRealTime ? 'hidden' : 'visible'}>
        <DatePicker
          date={startDate}
          maxDate={endDate}
          onDateChange={handleTimePeriodStartChanged}
          withTime={withTime}
        />
        <Divider>-</Divider>
        <DatePicker
          date={endDate}
          minDate={startDate}
          onDateChange={handleTimePeriodEndChanged}
          withTime={withTime}
        />
      </DatePickers>
    </Container>
  );
};
