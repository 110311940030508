import React from 'react';

export const Background = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMin"
    viewBox="0 0 1440 294"
  >
    <defs>
      <linearGradient id="linearGradient-1" x1="0%" x2="100%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#169EF8"></stop>
        <stop offset="100%" stopColor="#19F583"></stop>
      </linearGradient>
      <path id="path-2" d="M0 -10H1440V284H0z"></path>
      <linearGradient
        id="linearGradient-4"
        x1="18.298%"
        x2="94.427%"
        y1="45.533%"
        y2="42.462%"
      >
        <stop offset="0%" stopColor="#75FFE2" stopOpacity="0.024"></stop>
        <stop offset="100%" stopColor="#D2EEFC" stopOpacity="0.7"></stop>
      </linearGradient>
      <linearGradient id="linearGradient-5" x1="0%" x2="100%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#169EF8"></stop>
        <stop offset="100%" stopColor="#19F583"></stop>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g>
        <g>
          <mask id="mask-3" fill="#fff">
            <use xlinkHref="#path-2"></use>
          </mask>
          <use
            fill="url(#linearGradient-1)"
            opacity="0.927"
            xlinkHref="#path-2"
          ></use>
          <path
            fill="#E6F5FE"
            fillOpacity="0.849"
            d="M0-9.995c162.134 206.983 300.957 298.106 416.468 273.37C531.98 238.64 693.418 159.277 900.782 25.29c136.968-91.26 229.168-152.07 276.602-182.428 47.434-30.358 88.49-55.61 123.166-75.755 77.24-40.196 144.39-54.11 201.45-41.742V486H0V-9.995z"
            mask="url(#mask-3)"
            opacity="0.55"
          ></path>
          <path
            fill="url(#linearGradient-4)"
            d="M0 152.814c168.858 131.546 350.918 170.6 546.182 117.158 195.263-53.44 360.647-122.81 496.15-208.11C1280.678-70.714 1433.9-137 1502-137v612H1.042L0 152.814z"
            mask="url(#mask-3)"
          ></path>
          <path
            fill="url(#linearGradient-5)"
            d="M0 234.814c162.9 131.546 338.536 170.6 526.91 117.158 188.372-53.44 347.92-122.81 478.642-208.11C1235.488 11.286 1383.304-55 1449-55v612H1.006L0 234.814z"
            mask="url(#mask-3)"
            opacity="0.701"
          ></path>
          <path
            fill="#53FE9A"
            fillOpacity="0.402"
            d="M0-255C91.015 53.017 266.015 296.35 525 475c-110.42 0-284.75-3.08-522.99-9.24L0-255z"
            mask="url(#mask-3)"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
