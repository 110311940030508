import React, { useState } from 'react';
import styled from 'styled-components';
import * as Icons from '../icons/icons';

export interface EditContainerProps {
  onSuccess?: (result: any) => void;
  onAbort?: () => void;
  variables?: any;
  formVariables?: any;
  values?: any;
  defaultValues?: any;
}

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

export interface CreateFlowProps {
  onDone: () => void;
  kind: string;
  editContainer: React.ComponentType<EditContainerProps>;
  variables?: any;
  formVariables?: any;
  values?: any;
  defaultValues?: any;
}

export const CreateFlow = ({
  onDone,
  kind,
  editContainer: EditContainer,
  variables,
  formVariables,
  values,
  defaultValues,
}: CreateFlowProps) => {
  const [isCreated, setIsCreated] = useState(false);

  if (isCreated) {
    return (
      <Center data-testid="create-flow-success">
        <div>
          <Icons.Checkmark size={60} color="#A8E015" />
        </div>
        {kind} erfolgreich hinzugefügt.
      </Center>
    );
  }

  return (
    <>
      <EditContainer
        onSuccess={() => setIsCreated(true)}
        onAbort={() => onDone()}
        variables={variables}
        formVariables={formVariables}
        values={values}
        defaultValues={defaultValues}
      />
    </>
  );
};
