import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../modal/modal';
import { SpinnerDark } from '../spinner/spinner';
import { Flex, FlexRow } from '../layout/layout';
import { Button } from '../button/button';

const Iframe = styled.iframe`
  &.is-loading {
    display: none;
  }

  border: none;
  outline: 0;
  margin-top: 5px;
`;

const LoadingWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  height: 1000px;
  width: 850px;
  background-color: ${(props) => props.theme.palette.backgroundMuted};
`;

export interface IFrameModalProps {
  contentLabel: string;
  closeModal: () => void;
  title: string;
  src?: string;
  isOpen?: boolean;
  isLoading?: boolean;
}

export const IFrameModal = ({
  contentLabel,
  closeModal,
  title,
  src,
  isOpen = true,
  isLoading = false,
}: IFrameModalProps) => {
  const [loading, setLoading] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={closeModal}
      title={title}
    >
      {(isLoading || loading) && (
        <LoadingWrapper>
          Laden… <SpinnerDark size={15} />
        </LoadingWrapper>
      )}
      {!isLoading && (
        <Iframe
          src={src}
          scrolling="auto"
          height="1000"
          width="850"
          onLoad={() => setLoading(false)}
          className={loading ? 'is-loading' : ''}
        />
      )}
      <FlexRow>
        <Flex>
          <Button secondary onClick={closeModal}>
            Schließen
          </Button>
        </Flex>
      </FlexRow>
    </Modal>
  );
};
