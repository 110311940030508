import styled from 'styled-components';
import { Form } from 'formik';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Flex = styled.div`
  flex: 1;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Segment = styled.div`
  margin-bottom: 7px;
`;

export const ShadowBox = styled.div`
  background: ${(props) => props.theme.palette.background};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

export const Padded = styled.div`
  padding: 16px;
`;

export const PaddedShadowBox = styled(ShadowBox)`
  padding: 16px;
`;

export const TextWrapper = styled.div`
  font-size: 16px;
  text-align: center;
  width: 100%;
  line-height: 1.4;
  padding: 16px 0;
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-element {
    margin-bottom: 12px;
    width: 100%;
  }

  .form-element:last-child {
    margin-bottom: 0px;
  }
`;

export interface SectionProps {
  expand?: boolean;
  narrow?: boolean;
}

export const Section = styled.div.attrs<SectionProps>((props) => ({
  className: `${props.narrow ? 'narrow' : ''} ${props.expand ? 'expand' : ''}`,
}))<SectionProps>`
  box-sizing: border-box !important;
  padding: 32px 34px;
  background-color: white;
  background: ${(props) => props.theme.palette.background};
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: var(--section-margin);

  &.expand {
    display: block;
    width: 100%;
  }

  &.narrow {
    padding: 10px 13px;
  }

  .header {
    color: ${(props) => props.theme.palette.text};
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;

    &.underlined {
      padding-left: 12px;
      padding-bottom: 16px;
      border-bottom: 1px solid ${(props) => props.theme.palette.borderMuted};
      margin-bottom: 20px;
    }
  }
`;

export const Main = styled.div`
  display: block;
  padding: 40px 25px;
  position: relative;
`;

export const PageGrid = styled.div`
  display: flex;
  position: relative;
  --section-margin: 6px;
`;

export const PageColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;

  :first-child {
    margin-right: 6px;
  }
`;
