import * as React from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../theme/theme';

export interface IconProps {
  size: string | number;
  color?: 'primary' | 'secondary' | string;
  hover?: string;
  title?: string;
  style?: object;
  onClick?: (params: any) => void;
  className?: string;
  viewBox?: string;
  children?: React.ReactNode;
  fillSvg?: string;
}

const SVGThemed = styled.svg<{ color: string; hover?: string }>`
  vertical-align: sub;
  fill: ${(props) => {
    if (props.color === 'primary') {
      return props.theme.primaryColor;
    } else if (props.color === 'secondary') {
      return props.theme.secondaryColor;
    } else {
      return props.color;
    }
  }};

  ${(props) =>
    props.hover
      ? `&:hover {
      fill: ${props.hover};
    }
    `
      : ''}
`;

export const BaseIcon: React.FC<IconProps> = ({
  children,
  size,
  viewBox = '0 0 30 30',
  color = defaultTheme.palette.text,
  ...rest
}) => {
  return (
    <SVGThemed
      width={size}
      height={size}
      viewBox={viewBox}
      color={color}
      {...rest}
    >
      {children}
    </SVGThemed>
  );
};

export const StyledIcon = styled.div`
  border: 1px solid ${(props) => props.theme.palette.borderEmphasis};
  border-radius: 4px;
  display: inline-block;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};

    svg {
      fill: ${(props) => props.theme.palette.background};
    }
  }
`;

export const FillOnHover = styled.span<{ fillColor?: string }>`
  cursor: pointer;

  :hover {
    svg {
      fill: ${(props) => props.fillColor || props.theme.primaryColor};
    }
  }
`;
