import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { motion } from 'framer-motion';

import { Checkmark } from '../icons/icons';

const CheckboxWrapper = styled.div<{ selected: boolean; disabled?: boolean }>`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid ${(props) => props.theme.palette.borderEmphasis};
  border-radius: 50px;
  background: ${(props) =>
    props.selected || props.disabled
      ? `${props.theme.palette.background} !important`
      : 'inherit'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  svg {
    position: relative;
    top: -6px;
    left: -6px;
  }

  &:hover:not(:disabled) {
    background: ${(props) => props.theme.palette.backgroundMuted};
    border-color: ${(props) => {
      if (props.selected) return props.theme.primaryColor;
      if (props.disabled) return props.theme.palette.borderEmphasis;
      return props.theme.palette.text;
    }};
  }
`;

export interface SelectableCheckboxProps {
  selected: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  Icon?: JSX.Element;
}

export function SelectableCheckbox({
  selected,
  disabled,
  onClick,
  className,
  Icon,
}: SelectableCheckboxProps) {
  const theme = useTheme();

  const handleClick = () => {
    if (!disabled && onClick) onClick();
  };

  return (
    <CheckboxWrapper
      className={['checkbox-wrapper', className].join(' ')}
      selected={selected}
      disabled={disabled}
      onClick={handleClick}
      tabIndex={0}
      role="checkbox"
      onKeyDown={handleClick}
    >
      <motion.div
        animate={{ scale: selected ? 1 : 0 }}
        style={{ originY: 0.25 }}
        initial={false}
      >
        {Icon ? Icon : <Checkmark size={29} color={theme.primaryColor} />}
      </motion.div>
    </CheckboxWrapper>
  );
}
