import React from 'react';
import styled, { useTheme } from 'styled-components';
import * as Icons from '../icons/icons';
import { PageTitle } from '../typography/typography';
import useToParentCallback from './useToParentCallback';
import { FlexRow, Flex } from './layout';
import { useHistory } from 'react-router-dom';

interface PageTitleLayoutProps {
  isLoading?: boolean;
  levelsUpToParent?: number;
  title: string;
  actions?: React.ReactNode;
  className?: string;
}

const Wrapper = styled.div`
  display: flex;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 20px;
`;

const IconWrapper = styled.div`
  background: ${(props) => props.theme.palette.background};
  padding: 4px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  align-self: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.secondaryColor};

    svg {
      fill: ${(props) => props.theme.primaryColor};
    }
  }
`;

const ActionRow = styled(Flex)`
  align-self: center;
`;

const PullRight = styled(FlexRow)`
  justify-content: flex-end;
`;

export const PageTitleLayout: React.FC<PageTitleLayoutProps> = ({
  children,
  title,
  isLoading,
  levelsUpToParent,
  actions,
  className,
}) => {
  const theme = useTheme();
  const toParent = useToParentCallback(levelsUpToParent || 1);
  const history = useHistory();

  const handleIconClick = () => {
    if (levelsUpToParent === undefined) {
      history.goBack();
    } else {
      toParent();
    }
  };

  return (
    <ContentWrapper className={className}>
      <Wrapper>
        <IconWrapper onClick={handleIconClick}>
          <Icons.Back size={25} color={theme.palette.warning.color} />
        </IconWrapper>
        <PageTitle>{isLoading ? 'Wird geladen…' : title}</PageTitle>
        <ActionRow>
          <PullRight>{actions}</PullRight>
        </ActionRow>
      </Wrapper>
      {children}
    </ContentWrapper>
  );
};
