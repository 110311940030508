import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { PageClose } from '../close/close';
import { Flex, FlexRow } from '../layout/layout';
import { PageTitle } from '../typography/typography';

export type MaxModalWidth = 'sm' | 'md' | 'lg' | 'xl';

export type ModalBodyProps = {
  minWidth?: number;
  minHeight?: number;
  maxWidth?: MaxModalWidth;
};

const ModalBody = styled.div<ModalBodyProps>`
  background: ${(props) => props.theme.palette.background};
  border-radius: 4px;
  padding: 15px;
  outline: 0;

  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight}px`};
  max-width: ${(props) => {
    switch (props.maxWidth) {
      case 'sm':
        return '400px';
      case 'md':
        return '600px';
      case 'lg':
        return '800px';
      case 'xl':
        return '1024px';
      default:
        return 'none';
    }
  }};
`;

export interface ModalProps extends ModalBodyProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  contentLabel: string;
  children: any;
  title: React.ReactNode;
}

const StyledPageClose = styled(PageClose)`
  display: inline-block;
  margin-top: inherit;
  align-self: start;
`;

const NoMarginTitle = styled(PageTitle)`
  margin: 0;
`;

const Header = styled(FlexRow)`
  align-items: center;
`;

export function InternalModal({
  children,
  title,
  maxWidth,
  minWidth,
  minHeight,
  onRequestClose,
  contentLabel,
  ...rest
}: ModalProps & React.ComponentProps<typeof ReactModal>) {
  return (
    <ReactModal
      className="modal"
      overlayClassName="modal-overlay"
      closeTimeoutMS={300}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      {...rest}
    >
      <ModalBody
        id={contentLabel}
        maxWidth={maxWidth}
        minWidth={minWidth}
        minHeight={minHeight}
      >
        <Header>
          <Flex>
            <NoMarginTitle>{title}</NoMarginTitle>
          </Flex>
          {onRequestClose && (
            <StyledPageClose
              onClick={onRequestClose}
              data-testid="modal-close"
            />
          )}
        </Header>
        {children}
      </ModalBody>
    </ReactModal>
  );
}
