import React, { useState } from 'react';
import {
  TabContainer,
  TabNavigationItem,
  YellowDot,
  TabNavigation,
} from './style';

export interface TabsProps {
  children: (React.ReactElement<TabChildrenProps> | null)[];
}

export interface TabChildrenProps {
  title: React.ReactNode;
  /**
   * lazy renders the tab only when the tab is active
   */
  lazy?: boolean;
  hasNotification?: boolean;
  onClick?: (index: number) => void;
}

export const Tab: React.FunctionComponent<TabChildrenProps> = ({
  children,
}) => {
  return <div>{children}</div>;
};

export const Tabs = ({ children }: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const childs: Array<React.ReactElement<TabChildrenProps>> = [];

  const navigationItems = React.Children.map(children, (child, index) => {
    const c = child as React.ReactElement<TabChildrenProps>;

    if (!React.isValidElement(c)) {
      return child;
    }

    childs.push(
      <TabContainer
        key={`${c.props.title}-container`}
        className={`${index === activeIndex ? 'active' : ''}`}
      >
        {c.props.lazy && index !== activeIndex ? null : c}
      </TabContainer>
    );

    return (
      <TabNavigationItem
        id={c.props.title ? String(c.props.title) : undefined}
        key={String(c.props.title)}
        onClick={() => {
          setActiveIndex(index);
          c.props.onClick?.(index);
        }}
        className={`${index === activeIndex ? 'active' : ''}`}
      >
        {c.props.title}
        {c.props.hasNotification && <YellowDot />}
      </TabNavigationItem>
    );
  });

  return (
    <div>
      <TabNavigation>{navigationItems}</TabNavigation>
      <div>{childs}</div>
    </div>
  );
};
