import React from 'react';
import styled from 'styled-components';
import * as Icons from '../icons/icons';

const AccordionWrapper = styled.div<{ withPadding?: boolean }>`
  display: flex;
  flex-direction: column;
`;

export interface AccordionProps {
  children: React.ReactElement[];
  initialOpenIds?: number[];
  withPadding?: boolean;
  autoClose?: boolean;
  LabelComponent?: React.ComponentType;
  className?: string;
}

// @TODO: add accessibility features
export const Accordion: React.FC<AccordionProps> = ({
  children,
  initialOpenIds,
  withPadding = true,
  autoClose = true,
  LabelComponent,
  className,
}) => {
  const [openIds, setOpenIds] = React.useState(initialOpenIds ?? []);

  return (
    <AccordionWrapper className={className}>
      {React.Children.map(
        children,
        (child: React.ReactElement, index: number) =>
          React.cloneElement(child, {
            id: index,
            isOpen: openIds.includes(index),
            onOpen: (id: number) => {
              if (autoClose) {
                if (openIds.includes(id)) setOpenIds([]);
                else setOpenIds([id]);
              } else {
                if (openIds.includes(id)) {
                  setOpenIds((open) => open.filter((curr) => curr !== id));
                } else {
                  setOpenIds((open) => [...open, id]);
                }
              }
            },
            withPadding,
            LabelComponent,
          })
      )}
    </AccordionWrapper>
  );
};

const AccordionItemWrapper = styled.div<{ withPadding?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  .noTopPadding {
    padding-top: 0;
  }

  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid ${(props) => props.theme.palette.border};
    padding: ${(props) => (props.withPadding ? '20px 12px' : 'unset')};
    cursor: pointer;
  }

  .label.toggle {
    align-self: end;
  }
`;

const AccordionLabel = styled.div`
  color: ${(props) => props.theme.palette.text};
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
`;

const AccordionContent = styled.div<{ $open: boolean }>`
  display: ${(props) => (props.$open ? 'flex' : 'none')};
  ${(props) => props.$open && 'padding: 20px 12px;'}
`;

export interface AccordionItemProps {
  label: React.ReactNode;
  children: React.ReactNode;
}

interface AccordionItemInternalProps {
  id: number;
  isOpen: boolean;
  onOpen: (value?: number) => void;
  withPadding?: boolean;
  LabelComponent?: React.ComponentType;
}

const AccordionItemTemplate: React.FC<
  AccordionItemProps & AccordionItemInternalProps
> = ({ id, isOpen, onOpen, label, children, withPadding, LabelComponent }) => {
  const LabelWrapper = LabelComponent ?? AccordionLabel;
  return (
    <AccordionItemWrapper withPadding={withPadding}>
      <LabelWrapper
        className={id === 0 ? 'label noTopPadding' : 'label'}
        onClick={() => onOpen(id)}
      >
        {label}
        {isOpen ? (
          <Icons.ChevronUp size={25} />
        ) : (
          <Icons.ChevronDown size={25} />
        )}
      </LabelWrapper>
      <AccordionContent $open={isOpen}>{children}</AccordionContent>
    </AccordionItemWrapper>
  );
};

export const AccordionItem: React.FC<AccordionItemProps> =
  AccordionItemTemplate as React.FC<AccordionItemProps>;
