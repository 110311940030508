import React from 'react';
import styled from 'styled-components';

const CirclesWrapper = styled.div`
  display: flex;
  padding: 8px 0;
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.textSubtle};
  margin-right: 8px;
`;

export interface CirclesProps {}

export const Circles: React.FC<CirclesProps> = () => {
  return (
    <CirclesWrapper>
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
    </CirclesWrapper>
  );
};
