import styled from 'styled-components';

export interface ButtonGroupProps {
  vertical?: boolean;
  expand?: boolean;
  gap?: number | string;
}

export const ButtonGroup = styled.div.attrs<ButtonGroupProps>(
  ({ expand, vertical }) => ({
    className: `${vertical ? 'vertical' : ''} ${expand ? 'expand' : ''}`,
  })
)<ButtonGroupProps>`
  display: flex;
  flex-direction: row;

  button {
    margin-left: ${(props) => props.gap || '2px'};
    margin-right: ${(props) => props.gap || '2px'};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.vertical {
    flex-direction: column;

    button {
      margin: ${(props) => props.gap || '2px'} 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.expand {
    width: 100%;
  }
`;
