import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AlertRetryable } from '../alert/alert-retryable';
import { SpinnerDark } from '../spinner/spinner';
import { TextWrapper } from '../layout/layout';

const SpinnerWithPadding = styled(SpinnerDark)`
  padding: 14px 0;
`;

const formatError = (
  data: any | undefined | null
): React.ComponentProps<typeof AlertRetryable> => {
  switch (data?.check2FAuth?.__typename) {
    case 'ServiceNotReachableError':
      return {
        title: 'Authentication Service nicht erreichbar',
        retryable: true,
      };
    case 'TwoFactorSessionExpired':
      return { title: '2FAuth Session abgelaufen', retryable: false };
    case 'TwoFactorSessionInvalid':
      return { title: '2FAuth Session ungültig', retryable: false };
    case 'UnknownError':
      return { title: data.check2FAuth.message, retryable: false };
  }

  return {};
};

export interface TwoFactorAuthProps {
  useCheck2FAuthMutation: () => [
    (args?: any) => Promise<{ data?: any }>,
    { loading: boolean; data?: any; error?: any }
  ];
  onSuccess: (result: any) => void;
  token?: string | null;
  id?: string | null;
}

export const TwoFactorAuth: React.FC<TwoFactorAuthProps> = ({
  onSuccess,
  useCheck2FAuthMutation,
  token,
  id,
}) => {
  const { t } = useTranslation('authentication');
  const [mutation, { data, error, loading }] = useCheck2FAuthMutation();

  useEffect(() => {
    if (typeof token !== 'string' || typeof id !== 'string') {
      return;
    }

    mutation({
      variables: {
        token,
        id,
      },
    });
  }, [id, mutation, token]);

  useEffect(() => {
    if (data?.check2FAuth?.__typename === 'User') {
      onSuccess(data);
    }
  }, [data, onSuccess]);

  if (typeof token !== 'string' || typeof id !== 'string') {
    return (
      <AlertRetryable title="Ungültiger Link" message=" " retryable={false} />
    );
  }

  return (
    <>
      {loading && (
        <>
          <SpinnerWithPadding size={25} />
          <TextWrapper>{t('loggingIn')}</TextWrapper>
        </>
      )}
      {!loading && error && <AlertRetryable error={error} />}
      {!loading && !error && (
        <AlertRetryable {...formatError(data)} onRetry={() => mutation()} />
      )}
    </>
  );
};
